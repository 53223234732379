import axiosInstance from "../utils/axios";

export const AddCategoryLandingPages = async (data) => {
    return await axiosInstance().post('/insertCategoryPages', data)
}

// export const AddCampusResourse = async (data) => {
//     return await axiosInstance().post('/insertUploadResource', data)
// }

export const updateCategoryLandingPages = async (data) => {
    return await axiosInstance().post(`/updateCategoryPages`, data)
}

export const getCategoryLandingCategoryDropdown = async () => {
    return await axiosInstance().get(`/getCatgoryListStatic`)
}

// export const getCampusColorList = async (values) => {
//     return await axiosInstance().post(`/getCampusColorList` , values)
// }

// export const getStaticPagesBrandList = async () => {
//     return await axiosInstance().get(`/getBrandTemplateList`)
// }

// export const getStaticPagesHeaderList = async () => {
//     return await axiosInstance().get(`/getHeaderDropDownListStatic`)
// }

// export const getCampusPagesStatusList = async () => {
//     return await axiosInstance().get(`/getPublishedStatusList`)
// }

// export const getStaticPagesPopupList = async () => {
//     return await axiosInstance().get(`/getPopUpListStatic`)
// }

export const GetCategoryLandingPageListCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getCategoryLandingPagesList`, values, {
        cancelToken: cancelToken
    });
};

export const getCategoryLandingPagesList = async (values) => {
    return await axiosInstance().post(`/getCategoryLandingPagesList`, values);
};

export const DeleteCategoryLandingPages = async (id) => {
    return await axiosInstance().post(`/deleteCategoryPages?TrainingCourseLandingPageID=${id}`);
};

// export const DeleteCampusResousePages = async (id) => {
//     return await axiosInstance().get(`/deleteUploadResource?id=${id}`);
// };

// export const GetCampusResourseList = async (data) => {
//     return await axiosInstance().post(`/getCampusResourceList`, data);
// };

// export const GetCampusPageResourseListCancelToken = async (values, cancelToken) => {
//     return await axiosInstance().post(`/getCampusResourceList`, values, {
//         cancelToken: cancelToken
//     });
// };

export const GetCategoryLandingUpdateList = async (id) => {
    return await axiosInstance().get(`/getCategoryPages?TrainingCourseLandingPageID=${id}`);
};

export const GetCategoryLandingPageFilterCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/getCategoryLandingPagesFilter`, {
        cancelToken: cancelToken
    });
};