import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import {
    getStaticPagesBrandList,
    getStaticPagesPopupList
} from "../../../services/StaticPgesServer";
import { AddCampusPages, getCampusColorList, getCampusLocationDropdown, getCampusPagesStatusList, GetCampusUpdateList, updateCampusPages } from "../../../services/CampusPagesServer";
import Resouses from "./resourses/Resouses";
import PermissionsGate from "../../../utils/permissionGate";
import { FileUploadField, FormField } from "../../common/FormFields";
import { getBlockVariantTypeDropDown, getBrandTemplateStatus, getHeaderDropDownListStatic, getPopUpListStatic } from "../../../services/BrandTemplateServices";
import axios from "axios";


const CampusPagesOpt = () => {
    const history = useHistory();
    const { id, type } = useParams();
    const [updateData, setUpdateData] = useState(null);
    const [brandList, setBrandList] = useState([]);
    const [linkedCampusData, setLinkedCampusData] = useState([]);
    const [campusColour, setCampusColour] = useState([]);
    const [statusArr, setStatusArr] = useState([]);
    const [brandValue, setbrandValue] = useState([]);
    const [templateName, setTemplateName] = useState([]);
    const [popup, setPopup] = useState([]);

    const [headerArr, setHeaderArr] = useState([]);
    const [popUpArr, setPopUpArr] = useState([]);
    const [learnignBlockArr, setLearnignBlockArr] = useState([]);
    const [testimonialsBlockArr, setTestimonialsBlockArr] = useState([]);

    const givenPermissions = useSelector((state) => state.givenPermission);

    let brandID = updateData?.brandTemplete.map((data) => data.BrandID);

    useEffect(() => {
        const checkPermissions = () => {
            const hasAccess = hasPermission({ scopes: ["pmcmedit"], permissions: givenPermissions });
            if (!hasAccess) {
                history.push("/noaccess");
            }
        };
        checkPermissions();
    }, [givenPermissions, history]);

    useEffect(() => {
        const fetchData = async () => {
            getCampusPagesStatusList()
                .then(res => setStatusArr(res.data.publishedStatus))
                .catch(error => console.error("Error fetching status data", error));

            getCampusLocationDropdown()
                .then(res => setLinkedCampusData(res.data.campusLocationFilters))

            getStaticPagesPopupList()
                .then(res => setPopup(res.data.Data))

            getStaticPagesBrandList()
                .then(res => setBrandList(res.data.brandTypesFilters))
        };

        fetchData();
    }, []);

    useEffect(() => {
        GetCampusUpdateList(id)
            .then(res => {
                setUpdateData(res?.data?.Data)
            })
            .catch(err => console.error(err))
    }, [id])
    console.log(updateData?.status)
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: updateData?.id || "",
            pageTitle: updateData?.pageTitle || "",
            status: updateData?.status || "",
            linkedCampus: updateData?.linkedCampus || "",
            contactNumber: updateData?.contactNumber || "",
            email: updateData?.email || "",
            campusColor: updateData?.campusColor || "",
            useCustomColor: updateData?.useCustomColor || 0,
            campusContent: updateData?.campusContent || "",
            campusAddress: updateData?.campusAddress || "",
            popUp: updateData?.popUp || "",
            formEmail: updateData?.formEmail || "",
            conversionCode: updateData?.conversionCode || "",
            metaTitle: updateData?.metaTitle || "",
            metaDescription: updateData?.metaDescription || "",
            metaKeywords: updateData?.metaKeywords || "",
            addCustomCss: updateData?.addCustomCss || 0,
            CustomCss: updateData?.CustomCss || "",
            Brand: brandID || [],
            showBannerBlock: updateData?.showBannerBlock || 0,
            showButtonForm: updateData?.showButtonForm || 0,
            googleMapsEmbed: updateData?.googleMapsEmbed || "",
            tourText: updateData?.tourText || "",
            tourVideo: updateData?.tourVideo || "",
            resource_id: [],

            school_category_show_block: updateData?.school_category_show_block || 0,
            school_category_search_bar: updateData?.school_category_search_bar || 0,
            school_category_text: updateData?.school_category_text || "",
            secondary_category_show_block: updateData?.secondary_category_show_block || 0,
            secondary_category_text: updateData?.secondary_category_text || "",
            upcoming_opendays_show_block: updateData?.upcoming_opendays_show_block || 0,
            upcoming_opendays_text: updateData?.upcoming_opendays_text || "",
            learning_method_show_block: updateData?.learning_method_show_block || 0,
            learning_method_block_variant: updateData?.learning_method_block_variant || "",
            learning_method_block_text: updateData?.learning_method_block_text || "",
            testimonials_block_show: updateData?.testimonials_block_show || 0,
            testimonials_block_variant: updateData?.testimonials_block_variant || "",
            testimonials_block_text: updateData?.testimonials_block_text || "",
            news_block_show: updateData?.news_block_show || 0,
            news_block_text: updateData?.news_block_text || "",
        },
        validationSchema: Yup.object({
            pageTitle: Yup.string().required("Page Title is required"),
            Brand: Yup.array().required("Brand Templates is required"),
            contactNumber: Yup.number().required("Contact Number is required"),
            email: Yup.string().email("Enter Correct Email ").required("Email is required"),
            campusColor: Yup.string().required("Color is required"),
            showBannerBlock: Yup.number().required("Show Banner Block is required"),
            showButtonForm: Yup.number().required("Show Button is required"),
            campusContent: Yup.string().required("Campus Content is required"),
            campusAddress: Yup.string().required("Campus Address is required"),
            tourText: Yup.string().required("Campus Tour Text is required"),

            CustomCss: Yup.string().when('addCustomCss', {
                is: 1,
                then: Yup.string().required("Custom CSS is required")
            }),
            showButtonForm: Yup.string().required("Show button form is required"),
            showBannerBlock: Yup.string().required("Show banner block is required"),

            school_category_text: Yup.string().required('Schools Category Block Title Text is required'),
            secondary_category_text: Yup.string().required('Secondary Category Block Title Text is required'),
            upcoming_opendays_text: Yup.string().required('Upcoming Open Days Block Title Text is required'),
            learning_method_block_text: Yup.string().required('Learning Methods Block Title Text is required'),
            testimonials_block_text: Yup.string().required('Testimonials Block Title Text is required'),
            news_block_text: Yup.string().required('News Block Title Text is required'),
        }),
        onSubmit: async (values, actions) => {
            let data = { ...values }
            data.resource_id = data?.resource_id?.map((item) => item.id)
            if (type === "update") {
                updateCampusPages(data)
                    .then(res => {
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: "updated Successfully",
                        });
                        actions.resetForm();
                        history.push("/website_management/pages/campuspages/table");
                    })
                    .catch(err => console.error(err))
            } else {
                try {
                    await AddCampusPages(data);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Added Successfully",
                    });
                    actions.resetForm();
                    history.push("/website_management/pages/campuspages/table");
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.response?.data?.message || "An error occurred",
                    });
                }
            }
        }
    });

    useEffect(() => {
        let colorValue = {
            brandID: templateName
        }

        getCampusColorList(colorValue)
            .then(res => setCampusColour(res.data.result))
            .catch(err => console.error(err))
    }, [templateName])

    useEffect(() => {
        setbrandValue(brandList.filter((data) => formik.values.Brand.includes(data.value)))
    }, [formik.values.Brand])

    useEffect(() => {
        setTemplateName(brandValue.map(data => data.value))
    }, [brandValue])


    useEffect(() => {
        const fetchData = async () => {
            try {
                const [statusRes, headerRes, BlockVariantTypeRes, PopUpRes] = await Promise.all([
                    getBrandTemplateStatus(),
                    getHeaderDropDownListStatic(),
                    getBlockVariantTypeDropDown(),
                    getPopUpListStatic()
                ]);
                const statusFilters = statusRes.data.statusFilters || [];
                const popUpFilters = PopUpRes.data.Data || [];
                const headerFilters = headerRes.data.Data || [];
                const learnignBlockFilters = BlockVariantTypeRes.data.learnignBlock || [];
                const testimonialsBlockFilters = BlockVariantTypeRes.data.testimonialsBlock || [];
                setStatusArr(statusFilters);
                setHeaderArr(headerFilters);
                setPopUpArr(popUpFilters);
                setLearnignBlockArr(learnignBlockFilters);
                setTestimonialsBlockArr(testimonialsBlockFilters);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        };

        fetchData();
    }, []);

    return (
        <div className="card card-profile-info-card">
            <div>
                <div className="new-card-header">
                    <div className="card-header pt-0">Campus Information</div>
                </div>
                <div className="card-body-inr card-body-info">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3">
                                    <label>Page Title *</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.pageTitle && formik.touched.pageTitle
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Page Title"
                                        name="pageTitle"
                                        id="pageTitle"
                                        title="page Title"
                                        value={formik.values.pageTitle}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="Assigned Brand Templates">
                                    <label>Assigned Brand Templates* <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box is-multi" + (formik.errors.Brand && formik.touched.Brand
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="Brand"
                                        value={brandValue}
                                        onChange={(value) => formik.setFieldValue("Brand", value ? value.map((v) => v.value) : [])}
                                        onBlur={formik.handleBlur}
                                        options={brandList}
                                        maxMenuHeight={175}
                                        isMulti
                                        placeholder={"Select Assigned Brand Templates"}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="Status">
                                    <label>Status</label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.status && formik.touched.status
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="status"
                                        value={statusArr.find(val => val.value == formik.values.status)}
                                        onChange={(option) => {
                                            formik.setFieldValue("status", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={statusArr}
                                        maxMenuHeight={175}
                                        placeholder={
                                            formik.values.status || "Select Status"
                                        }
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="PageInformation">
                                    <label>Linked Campus</label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.linkedCampus && formik.touched.linkedCampus
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="linkedCampus"
                                        value={linkedCampusData.find(val => val.value === formik.values.linkedCampus)}
                                        onChange={(option) => {
                                            formik.setFieldValue("linkedCampus", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={linkedCampusData}
                                        maxMenuHeight={175}
                                        placeholder={
                                            formik.values.linkedCampus || "Select Linked Campus"
                                        }
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3">
                                    <label>Contact Number *</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.contactNumber && formik.touched.contactNumber
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Contact Number"
                                        name="contactNumber"
                                        id="contactNumber"
                                        title="Contact Number"
                                        value={formik.values.contactNumber}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3">
                                    <label>Email*</label>
                                    <input
                                        type="email"
                                        className={
                                            "form-control" +
                                            (formik.errors.email && formik.touched.email
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Email"
                                        name="email"
                                        id="email"
                                        title="Email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-4">

                                    <label>Campus Colour *</label>
                                    <Select
                                        className={`form-control custom-select-box ${formik.errors.campusColor && formik.touched.campusColor && "is-invalid"}`}
                                        name="campusColor"
                                        value={campusColour.filter(val => formik.values.campusColor.includes(val.value.toString()))}
                                        onChange={(value) => {
                                            if (value) {
                                                formik.setFieldValue("campusColor", value.value);
                                            } else {
                                                formik.setFieldValue("campusColor", "");
                                            }
                                        }}
                                        isClearable
                                        onBlur={formik.handleBlur}
                                        options={campusColour}
                                        maxMenuHeight={175}
                                        placeholder={"Accent 1"}
                                    />
                                    {formik.errors.campusColor &&
                                        formik.touched.campusColor ? (
                                        <span className="exclamation">
                                            <i className="fal fa-exclamation-circle"></i>
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-4">
                                    <label>
                                        Campus Tour Elements * <i className="fal fa-info-circle"></i>
                                    </label>

                                    <div className="d-flex align-items-center mt-2">
                                        <div className="custom-check custom-control custom-checkbox mr-5" title="showButtonForm">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="showButtonForm"
                                                name="showButtonForm"
                                                checked={formik.values.showButtonForm === 1}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "showButtonForm",
                                                        e.target.checked ? 1 : 0
                                                    )
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="showButtonForm">
                                                Show Buttons
                                            </label>
                                        </div>

                                        <div className="custom-check custom-control custom-checkbox" title="showBannerBlock">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="showBannerBlock"
                                                name="showBannerBlock"
                                                checked={formik.values.showBannerBlock}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "showBannerBlock",
                                                        e.target.checked ? 1 : 0
                                                    )
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="showBannerBlock">
                                                Show Banner Block
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FileUploadField
                                name="tourVideo"
                                label="Campus Tour Video"
                                formik={formik}
                                accept="video/*"
                                col_md={6}
                                col_lg={3}
                            />
                            <div className="col-md-12">
                                <div className="form-group-blk mb-3">
                                    <label>Google Maps Embed Code</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.googleMapsEmbed && formik.touched.googleMapsEmbed
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Google Maps Embed Code"
                                        name="googleMapsEmbed"
                                        id="googleMapsEmbed"
                                        title="Google Maps Embed"
                                        value={formik.values.googleMapsEmbed}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group-blk mb-3">
                                    <label>Campus Content*</label>
                                    <HtmlInputEditor
                                        editorState={formik.values.campusContent}
                                        setEditorState={(editorState) => {
                                            formik.setFieldValue('campusContent', editorState);
                                        }}
                                        hideSign
                                        isCKEditor
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group-blk mb-3">
                                    <label>Campus Address*</label>
                                    <HtmlInputEditor
                                        editorState={formik.values.campusAddress}
                                        setEditorState={(editorState) => {
                                            formik.setFieldValue('campusAddress', editorState);
                                        }}
                                        hideSign
                                        isCKEditor
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group-blk mb-3">
                                    <label>Campus Tour Text*</label>
                                    <HtmlInputEditor
                                        editorState={formik.values.tourText}
                                        setEditorState={(editorState) => {
                                            formik.setFieldValue('tourText', editorState);
                                        }}
                                        hideSign
                                        isCKEditor
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <hr className="border border-2" style={{ borderColor: "rgb(230, 235, 241)" }} />
                            </div>
                            <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1">
                                    <h4 className="card-header">
                                        Campus Gallery
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <Resouses formik={formik} />
                            </div>
                            <div className="new-card-header col-md-12">
                                <h4 className="card-header pt-0"><i
                                    className="fal fa-cog"
                                    style={{ color: "var(--topbar-color)" }}
                                ></i> Page Settings</h4>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3" title="Status">
                                    <label>Popup <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.popUp && formik.touched.popUp
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="popUp"
                                        value={popup.find(val => val.value === formik.values.popUp)}
                                        onChange={(option) => {
                                            formik.setFieldValue("popUp", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={popup}
                                        maxMenuHeight={175}
                                        placeholder={
                                            formik.values.popUp || "Default"
                                        }
                                        isClearable
                                    />
                                </div>
                            </div>
                            {/* <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3">
                                    <label>Form Email</label>
                                    <input
                                        type="email"
                                        className={
                                            "form-control" +
                                            (formik.errors.formEmail && formik.touched.formEmail
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Form Email"
                                        name="formEmail"
                                        id="email"
                                        title="email"
                                        value={formik.values.formEmail}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div> */}


                            {
                                formik.values?.useCustomColor ?
                                    <>
                                        {[
                                            { label: "Campus Colour", name: "campusColor", color: true, type: "color", },
                                        ].map((field, index) => (
                                            <FormField key={index} field={field} formik={formik} />
                                        ))}
                                    </>
                                    :
                                    <FormField
                                        field={{ name: "campusColor", label: "Campus Colour" }}
                                        formik={formik}
                                        selectOptions={campusColour}
                                    />
                            }

                            <div className="col-md-6 col-lg-3 d-flex align-items-center">
                                <label className="mb-0">
                                    Use Custom Colour <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="toggle-switch ml-2">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={formik.values.useCustomColor == 1}
                                            onChange={(e) => {
                                                formik.setFieldValue("useCustomColor", e.target.checked ? 1 : 0)
                                                formik.setFieldValue(
                                                    "campusColor",
                                                    e.target.checked ? "#000000" : ""
                                                )
                                            }}
                                        />
                                        <span className="slider slider-round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="edit-icon new-card-header">
                                            <h4 className="card-header">Schools Category Block </h4>
                                        </div>
                                    </div>


                                    <div className={`col-md-6 col-lg-3`}>
                                        <label htmlFor={"Block Settings"}>
                                            Block Settings
                                        </label>
                                        <div className="form-icon-group mt-3">
                                            <div className="custom-control custom-checkbox text-left mb-3 mr-3" title="Show Block">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="school_category_show_block"
                                                    name="school_category_show_block"
                                                    checked={formik.values.school_category_show_block == 1}
                                                    onChange={(e) => {
                                                        console.log("Updating school_category_show_block:", e.target.checked);
                                                        formik.setFieldValue(
                                                            "school_category_show_block",
                                                            e.target.checked ? 1 : 0
                                                        );
                                                    }}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="school_category_show_block"
                                                >
                                                    Show  Block
                                                </label>
                                            </div>
                                            <div className="custom-control custom-checkbox text-left" title="Show Course Search Bar">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="school_category_search_bar"
                                                    name="school_category_search_bar"
                                                    checked={formik.values.school_category_search_bar == 1}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(
                                                            "school_category_search_bar",
                                                            e.target.checked ? 1 : 0
                                                        );
                                                    }}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="school_category_search_bar"
                                                >
                                                    Show Course Search Bar
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-9">
                                        <label htmlFor="school_category_text" title="Site Name">Block Title Text *</label>
                                        <div className="form-icon-group" title="site_Name">
                                            <input
                                                type="text"
                                                className={`form-control ${formik.touched.school_category_text && formik.errors.school_category_text && "is-invalid"
                                                    }`}
                                                name="school_category_text"
                                                id="school_category_text"
                                                title="Block Title Text"
                                                placeholder="Block Title Text"
                                                value={formik.values?.school_category_text}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div className="col-12">
                                <div className="row pt-1">
                                    <div className="col-md-12">
                                        <div className="edit-icon new-card-header">
                                            <h4 className="card-header">Secondary Category Block</h4>
                                        </div>
                                    </div>

                                    <div className={`col-md-6 col-lg-3`}>
                                        <label htmlFor={"Block Settings"}>
                                            Block Settings
                                        </label>
                                        <div className="form-icon-group mt-3">
                                            <div className="custom-control custom-checkbox text-left mb-3 mr-3" title="Show Block">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="secondary_category_show_block"
                                                    name="secondary_category_show_block"
                                                    checked={formik.values.secondary_category_show_block == 1}
                                                    onChange={(e) => {
                                                        console.log("Updating secondary_category_show_block:", e.target.checked);
                                                        formik.setFieldValue(
                                                            "secondary_category_show_block",
                                                            e.target.checked ? 1 : 0
                                                        );
                                                    }}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="secondary_category_show_block"
                                                >
                                                    Show  Block
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-9">
                                        <label htmlFor="secondary_category_text" title="Site Name">Block Title Text *</label>
                                        <div className="form-icon-group" title="site_Name">
                                            <input
                                                type="text"
                                                className={`form-control ${formik.touched.secondary_category_text && formik.errors.secondary_category_text && "is-invalid"
                                                    }`}
                                                name="secondary_category_text"
                                                id="secondary_category_text"
                                                title="Block Title Text"
                                                placeholder="Block Title Text"
                                                value={formik.values?.secondary_category_text}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>


                            <div className="col-12">
                                <div className="row pt-1">
                                    <div className="col-md-12">
                                        <div className="edit-icon new-card-header">
                                            <h4 className="card-header">Upcoming Open Days Block</h4>
                                        </div>
                                    </div>

                                    <div className={`col-md-6 col-lg-3`}>
                                        <label htmlFor={"Block Settings"}>
                                            Block Settings
                                        </label>
                                        <div className="form-icon-group mb-4 mt-3">
                                            <div className="custom-control custom-checkbox text-left mr-3" title="Show Block">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="upcoming_opendays_show_block"
                                                    name="upcoming_opendays_show_block"
                                                    checked={formik.values.upcoming_opendays_show_block == 1}
                                                    onChange={(e) => {
                                                        console.log("Updating upcoming_opendays_show_block:", e.target.checked);
                                                        formik.setFieldValue(
                                                            "upcoming_opendays_show_block",
                                                            e.target.checked ? 1 : 0
                                                        );
                                                    }}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="upcoming_opendays_show_block"
                                                >
                                                    Show  Block
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-9">
                                        <label htmlFor="upcoming_opendays_text" title="Site Name">Block Title Text *</label>
                                        <div className="form-icon-group" title="site_Name">
                                            <input
                                                type="text"
                                                className={`form-control ${formik.touched.upcoming_opendays_text && formik.errors.upcoming_opendays_text && "is-invalid"
                                                    }`}
                                                name="upcoming_opendays_text"
                                                id="upcoming_opendays_text"
                                                title="Block Title Text"
                                                placeholder="Block Title Text"
                                                value={formik.values?.upcoming_opendays_text}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div className="col-12">
                                <div className="row pt-1">
                                    <div className="col-md-12">
                                        <div className="edit-icon new-card-header">
                                            <h4 className="card-header">Learning Methods Block</h4>
                                        </div>
                                    </div>

                                    <div className={`col-md-6 col-lg-3`}>
                                        <label htmlFor={"Block Settings"}>
                                            Block Settings
                                        </label>
                                        <div className="form-icon-group mb-4 mt-3">
                                            <div className="custom-control custom-checkbox text-left mr-3" title="Show Block">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="learning_method_show_block"
                                                    name="learning_method_show_block"
                                                    checked={formik.values.learning_method_show_block == 1}
                                                    onChange={(e) => {
                                                        console.log("Updating learning_method_show_block:", e.target.checked);
                                                        formik.setFieldValue(
                                                            "learning_method_show_block",
                                                            e.target.checked ? 1 : 0
                                                        );
                                                    }}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="learning_method_show_block"
                                                >
                                                    Show  Block
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group-blk mb-3" title="Block Variant">
                                            <label htmlFor="learning_method_block_variant">Block Variant <i className="fal fa-info-circle grade-icon"></i>
                                            </label>
                                            <Select
                                                className={
                                                    "form-control custom-select-box " +
                                                    (formik.errors.learning_method_block_variant && formik.touched.learning_method_block_variant
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                                name="learning_method_block_variant"
                                                value={learnignBlockArr.filter(
                                                    (item) => item.value == formik.values.learning_method_block_variant
                                                )}
                                                onChange={(value) => {
                                                    if (value) {
                                                        formik.setFieldValue("learning_method_block_variant", value.value);
                                                    } else {
                                                        formik.setFieldValue("learning_method_block_variant", "");
                                                    }
                                                }}
                                                onBlur={formik.handleBlur}
                                                options={learnignBlockArr}
                                                maxMenuHeight={175}
                                                placeholder={"Select Block Variant"}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-4">
                                        <label htmlFor="learning_method_block_text" title="Site Name">Block Title Text *</label>
                                        <div className="form-icon-group" title="site_Name">
                                            <input
                                                type="text"
                                                className={`form-control ${formik.touched.learning_method_block_text && formik.errors.learning_method_block_text && "is-invalid"
                                                    }`}
                                                name="learning_method_block_text"
                                                id="learning_method_block_text"
                                                title="Block Title Text"
                                                placeholder="Block Title Text"
                                                value={formik.values?.learning_method_block_text}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div className="col-12">
                                <div className="row pt-1">
                                    <div className="col-md-12">
                                        <div className="edit-icon new-card-header">
                                            <h4 className="card-header">Testimonials Block</h4>
                                        </div>
                                    </div>

                                    <div className={`col-md-6 col-lg-3`}>
                                        <label htmlFor={"Block Settings"}>
                                            Block Settings
                                        </label>
                                        <div className="form-icon-group mb-4 mt-3">
                                            <div className="custom-control custom-checkbox text-left mr-3" title="Show Block">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="testimonials_block_show"
                                                    name="testimonials_block_show"
                                                    checked={formik.values.testimonials_block_show == 1}
                                                    onChange={(e) => {
                                                        console.log("Updating testimonials_block_show:", e.target.checked);
                                                        formik.setFieldValue(
                                                            "testimonials_block_show",
                                                            e.target.checked ? 1 : 0
                                                        );
                                                    }}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="testimonials_block_show"
                                                >
                                                    Show  Block
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group-blk mb-3" title="Block Variant">
                                            <label htmlFor="testimonials_block_variant">Block Variant <i className="fal fa-info-circle grade-icon"></i>
                                            </label>
                                            <Select
                                                className={
                                                    "form-control custom-select-box " +
                                                    (formik.errors.testimonials_block_variant && formik.touched.testimonials_block_variant
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                                name="Linked PMC Programme"
                                                value={testimonialsBlockArr.filter(
                                                    (item) => item.value == formik.values.testimonials_block_variant
                                                )}
                                                onChange={(value) => {
                                                    if (value) {
                                                        formik.setFieldValue("testimonials_block_variant", value.value);
                                                    } else {
                                                        formik.setFieldValue("testimonials_block_variant", "");
                                                    }
                                                }}
                                                onBlur={formik.handleBlur}
                                                options={testimonialsBlockArr}
                                                maxMenuHeight={175}
                                                placeholder={"Select Block Variant"}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-4">
                                        <label htmlFor="testimonials_block_text" title="Site Name">Block Title Text *</label>
                                        <div className="form-icon-group" title="site_Name">
                                            <input
                                                type="text"
                                                className={`form-control ${formik.touched.testimonials_block_text && formik.errors.testimonials_block_text && "is-invalid"
                                                    }`}
                                                name="testimonials_block_text"
                                                id="testimonials_block_text"
                                                title="Block Title Text"
                                                placeholder="Block Title Text"
                                                value={formik.values?.testimonials_block_text}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div className="col-12">
                                <div className="row pt-1">
                                    <div className="col-md-12">
                                        <div className="edit-icon new-card-header">
                                            <h4 className="card-header">News Block</h4>
                                        </div>
                                    </div>

                                    <div className={`col-md-6 col-lg-3`}>
                                        <label htmlFor={"Block Settings"}>
                                            Block Settings
                                        </label>
                                        <div className="form-icon-group mb-4 mt-3">
                                            <div className="custom-control custom-checkbox text-left mr-3" title="Show Block">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="news_block_show"
                                                    name="news_block_show"
                                                    checked={formik.values.news_block_show == 1}
                                                    onChange={(e) => {
                                                        console.log("Updating news_block_show:", e.target.checked);
                                                        formik.setFieldValue(
                                                            "news_block_show",
                                                            e.target.checked ? 1 : 0
                                                        );
                                                    }}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="news_block_show"
                                                >
                                                    Show  Block
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-9">
                                        <label htmlFor="news_block_text" title="Site Name">Block Title Text *</label>
                                        <div className="form-icon-group" title="site_Name">
                                            <input
                                                type="text"
                                                className={`form-control ${formik.touched.news_block_text && formik.errors.news_block_text && "is-invalid"
                                                    }`}
                                                name="news_block_text"
                                                id="news_block_text"
                                                title="Block Title Text"
                                                placeholder="Block Title Text"
                                                value={formik.values?.news_block_text}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1">
                                    <h4 className="card-header">
                                        <i
                                            className="fal fa-cog"
                                            style={{ color: "var(--topbar-color)" }}
                                        ></i> SEO Settings
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3">
                                    <label htmlFor="code">Conversion Code</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.conversionCode && formik.touched.conversionCode
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Conversion Code"
                                        name="conversionCode"
                                        id="conversion"
                                        title="conversion"
                                        maxLength={20}
                                        value={formik.values.conversionCode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3">
                                    <label>Meta Title</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.metaTitle && formik.touched.metaTitle
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Meta Title"
                                        title="metaTitle"
                                        name="metaTitle"
                                        value={formik.values.metaTitle}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3">
                                    <label>Meta Description</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.metaDescription && formik.touched.metaDescription
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Meta Description"
                                        title="metaDescription"
                                        name="metaDescription"
                                        value={formik.values.metaDescription}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3">
                                    <label>Meta Keywords</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.metaKeywords && formik.touched.metaKeywords
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Meta Keywords"
                                        title="metaKeywords"
                                        name="metaKeywords"
                                        value={formik.values.metaKeywords}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            {/* <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1">
                                    <h4 className="card-header">
                                        <i
                                            className="fal fa-cog"
                                            style={{ color: "var(--topbar-color)" }}
                                        ></i> Style Settings
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-flex align-items-center mb-3">
                                <label className="mb-0">
                                    Add Custom CSS <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="toggle-switch ml-2">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={formik.values.addCustomCss == 1}
                                            onChange={(e) => formik.setFieldValue("addCustomCss", e.target.checked ? 1 : 0)}
                                        />
                                        <span className="slider slider-round"></span>
                                    </label>
                                </div>
                            </div>
                        
                              {formik.values.addCustomCss == 1 && <div className="col-md-12">
                                <div className=" mb-3 mt-3" title="Customs CSS">
                                    <label>Customs CSS*</label>
                                    <div className="html-editor-input">
                                            <textarea value={formik.values.CustomCss} className={`textarea form-control ${formik.touched.CustomCss && formik.errors.CustomCss && "is-invalid"}`} onBlur={formik.handleBlur} onChange={formik.handleChange} name="CustomCss"></textarea>
                                            <div className="headerf">
                                                <span className="title fw-bolds"> CSS</span>
                                            </div>
                                        </div>
                                </div>
                            </div>} */}
                        </div>
                        <div className="form-group form-group-save-cancel mt-4">
                            <PermissionsGate scopes={[type == "create" ? 'wbscampuslpageadd' : 'wbscampuslpageedit']}>
                                <button
                                    className="btn btn-save btn-success"
                                    type="submit"
                                    title="Save"
                                >
                                    <i className="fal fa-save"></i>
                                    Save
                                </button>
                            </PermissionsGate>
                        </div>
                        {Object.keys(formik.errors).map((key) => {
                            if (formik.touched[key]) {
                                return (
                                    <div className="invalid-feedback d-block" key={key}>
                                        {formik.errors[key]}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CampusPagesOpt;
