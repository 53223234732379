import axiosInstance from "../utils/axios";

export const AddBlogAndNewsPages = async (data) => {
    return await axiosInstance().post('/insertBlogPages', data)
}

// export const AddCampusResourse = async (data) => {
//     return await axiosInstance().post('/insertUploadResource', data)
// }

export const updateBlogAndNewsPages = async (data) => {
    return await axiosInstance().post(`/updateBlogPages`, data)
}

export const getBlogAndNewsDropdown = async () => {
    return await axiosInstance().post(`/getBlogNewsPagesFilter`)
}

// export const getCampusColorList = async (values) => {
//     return await axiosInstance().post(`/getCampusColorList` , values)
// }

// export const getStaticPagesBrandList = async () => {
//     return await axiosInstance().get(`/getBrandTemplateList`)
// }

// export const getStaticPagesHeaderList = async () => {
//     return await axiosInstance().get(`/getHeaderDropDownListStatic`)
// }

// export const getCampusPagesStatusList = async () => {
//     return await axiosInstance().get(`/getPublishedStatusList`)
// }

// export const getStaticPagesPopupList = async () => {
//     return await axiosInstance().get(`/getPopUpListStatic`)
// }

export const GetBlogAndNewsPageListCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getBlogPagesList`, values, {
        cancelToken: cancelToken
    });
};

export const getBlogAndNewsPagesList = async (values) => {
    return await axiosInstance().post(`/getBlogPagesList`, values);
};

export const DeleteBlogandNewsPages = async (id) => {
    return await axiosInstance().delete(`/deleteBlogPages?id=${id}`);
};

// export const DeleteCampusResousePages = async (id) => {
//     return await axiosInstance().get(`/deleteUploadResource?id=${id}`);
// };

// export const GetCampusResourseList = async (data) => {
//     return await axiosInstance().post(`/getCampusResourceList`, data);
// };

// export const GetCampusPageResourseListCancelToken = async (values, cancelToken) => {
//     return await axiosInstance().post(`/getCampusResourceList`, values, {
//         cancelToken: cancelToken
//     });
// };

export const GetBlogAndNewsUpdateList = async (id) => {
    return await axiosInstance().get(`/getBlogPages?id=${id}`);
};
export const GetBlogAndNewsAuditTrailListFilters = async () => {
    return await axiosInstance().get(`/getBlogAndNewsAuditTrailListFilters`);
};
export const GetBlogAndNewsTrailList = async (values) => {
    return await axiosInstance().post(`/getBlogAndNewsTrailList`, values);
};