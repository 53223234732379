import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import {
  DeletePmc,
  GetListofProgramCancelToken,
  ListProgrammeFilters,
} from "../../../services/ProgramService";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { TrimText } from "../../common/TrimText";
import { Link } from "react-router-dom";
import PermissionsGate from "../../../utils/permissionGate";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import Swal from "sweetalert2";
import {
  RenderProgrammeNQF,
  RenderProgrammeType,
  RenderSchool,
} from "../../../utils/CommonGroupingItem";
import $ from "jquery";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";
import DataTableComponent from "../../common/DataTableComponent";
import { RenderPMCStatus } from "../../../utils/CommonStatusItems";

const Program = () => {
  const history = useHistory();
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [school, setSchool] = useState({arr:[], checkObj:{}});
  const [type, setType] = useState({arr:[], checkObj:{}});
  const [NQF, setNQF] = useState({arr:[], checkObj:{}});
  const [status, setStatus] = useState({arr:[],checkObj:{}});
  const [loading, setloading] = useState(true);
  const [updateData, setUpdateData] = useState(Date.now());
  const [filterData, setFilterData] = useState({status : [], school : [], type:[], NQF:[]})
  const [tableState, setTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "name",
    sortOrder: "asc",
  });
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    const cancelTokenSources = [];

    const getProgram = async () => {
      setloading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const payload = {
          page:tableState.page,
          limit:tableState.perPage,
          key:tableState.sortKey,
          sort:tableState.sortOrder,
          search : search,
          exportStatus:"false",
          viaStatus:status.arr,
          viaSchool:school.arr,
          viaType:type.arr,
          viaNqfLevel:NQF.arr,
        }
        const res = await GetListofProgramCancelToken(payload, source.token);
        if (res.status === 200) {
          setData(res.data.data);
          setTotalRows(res.data.total)
          
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }finally{
        setloading(false);
      }
    };
  
    getProgram();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData, tableState, school, status, search, type, NQF]);

  useEffect(() => {
    const filterSource = axios.CancelToken.source();
    const getFilters = async () =>{
      try {
        const res = await ListProgrammeFilters(filterSource.token);
        if(res.status==200){
          setFilterData({...res.data, status : res.data.statusListFilter, school : res.data.SchoolListFilter, NQF:res.data?.nqfLevel, type:res.data?.type})
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }
    
    getFilters()
    return () => {
      filterSource.cancel('Component unmounted');      
    };
  }, [updateData])
  
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["pmcpview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);
  
  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => (
        <PermissionsGate
          scopes={["pmcpedit"]}
          RenderError={() => (
            <span title={row.name} className="feature-name">
              {" "}
              <span className="textLimit100">{row.name}</span>
            </span>
          )}
        >
          <Link
            to={`/program-management-center/programmes/programme/open/${row.id}`}
            title={row.name}
            className="as-text-blue curser feature-name"
          >
            <span title={row.name} className="textLimit100">
              {row.name}
            </span>
          </Link>
        </PermissionsGate>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row) =>
        row?.status != null ? RenderPMCStatus(row?.status).html : "-",
    },
    {
      name: "Type",
      selector: "qualification_type",
      sortField: "qualification_type",
      sortable: true,
      cell: (row) =>
        row?.qualification_type != null ? RenderProgrammeType(row?.qualification_type).html : "-",
    },
    {
      name: "Code",
      selector: "Code",
      sortField: "Code",
      sortable: true,
      cell: (row, index) => (
        <div className="ticket-id">
          <span title={row.Code} className="overflow-ellipsis2">
            {row.Code ? TrimText(row.Code, 10) : "-"}
          </span>
        </div>
      ),
    },

    {
      name: "SAQA Link",
      selector: "SAQA_Name",
      sortField: "SAQA_Name",
      sortable: true,
      cell: (row) =>
        row.SAQA_Link ? (
          <a
            href={
              row.SAQA_Link.includes("http")
                ? row.SAQA_Link
                : "http://" + row.SAQA_Link
            }
            target={"_blank"}
            className="as-text-blue curser"
            title={row.SAQA_Name}
          >
            {TrimText(row.SAQA_Name, 15)}
          </a>
        ) : (
          "-"
        ),
    },    
    {
      name: "NQF",
      selector: "NQF_Level",
      sortField: "NQF_Level",
      sortable: true,
      cell: (row) => <div title={row?.NQF_Level}>{row?.NQF_Level}</div>,
    },
    {
      name: "School",
      selector: "School",
      sortField: "School",
      sortable: true,
      cell: (row) => row?.School ? <span className="feature-name"><span className="textLimit100">{RenderSchool(row?.School).html}</span></span> : "-",
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <PermissionsGate
              scopes={["pmcpedit"]}
              RenderError={() => (
                <button
                  className="btn btn-primary rounded-circle"
                  title="Open"
                  disabled
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
            >
              <Link
                to={`/program-management-center/programmes/programme/open/${row.id}`}
                className="btn btn-primary rounded-circle"
                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>

            <div className="dropdown btn-dropdown-item">
              <button
                className="btn btn-primary rounded-circle dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                title="More"
              >
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <PermissionsGate
                  scopes={["pmcpadd"]}
                  RenderError={() => (
                    <button
                      className="btn btn-primary rounded-circle"
                      title="Duplicate"
                      disabled
                    >
                      <i className="fal fa-copy"></i>
                    </button>
                  )}
                >
                  <Link
                    to={`/program-management-center/programmes/programme/duplicate/${row.id}`}
                    className="btn btn-primary rounded-circle"
                    title="Duplicate"
                  >
                    <i className="fal fa-copy"></i>
                  </Link>
                </PermissionsGate>
                <PermissionsGate
                  scopes={["pmcpview"]}
                  RenderError={() => (
                    <button
                      className="btn btn-primary rounded-circle"
                      title="Programme View"
                      disabled
                    >
                      <i className="fal fa-eye"></i>
                    </button>
                  )}
                >
                  <Link
                    to={`/program-management-center/exportProgramme/${row.id}`}
                    target="_blank"
                    className="btn btn-primary rounded-circle"
                    title="View Programme"
                  >
                    <i className="fal fa-eye"></i>
                  </Link>
                </PermissionsGate>

                <PermissionsGate
                  scopes={["pmcpdelete"]}
                  RenderError={() => (
                    <button
                      className="btn btn-danger rounded-circle"
                      title="Delete"
                      disabled
                    >
                      <i className="fal fa-trash-alt"></i>
                    </button>
                  )}
                >
                  <button
                    title="Delete"
                    className="btn btn-danger rounded-circle"
                    onClick={() => handleDelete(row.id)}
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ], []);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeletePmc({ id: id })
          .then((res) => {
            Swal.fire("Deleted!", "Programme has been deleted.", "success");
            setUpdateData(Date.now());
          })
          .catch((error) => console.log(error));
      }
    });
  };

  const exportData = (fileType, fileName) => {
    let exportData = data;

    const header = ["Name", "Status", "Type", "Code", "SAQA Link", "#NQF", "School"];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
          Swal.showLoading();
      },
    });

    const payload = {
      page:tableState.page,
      limit:tableState.perPage,
      key:tableState.sortKey,
      sort:tableState.sortOrder,
      exportStatus:"true",
      viaStatus:status.arr,
      viaSchool:school.arr,
      viaType:type.arr,
      viaNqfLevel:NQF.arr,
    }

    GetListofProgramCancelToken(payload).then((res)=>{
      // console.log(res.data)
      exportData = res.data;
      exportData = exportData?.map((row) => ({
        ...row,
        Name: row?.name ? row?.name : "-",
        Status: row.status ? RenderPMCStatus(row.status).text : "",
        Type: row.qualification_type ? RenderProgrammeType(row.qualification_type).text : "-",
        Code: row.Code ? row.Code : "-",
        "SAQA Link": row.SAQA_Link ? row.SAQA_Link : "-",
        "#NQF": row?.NQF_Level ? row?.NQF_Level : "-",
        School: row.School ? RenderSchool(row.School).text : "-",
      }));
  
      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, exportData });
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
  
        const blobURL = window.URL.createObjectURL(blob);
  
        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [
          "text/csv",
          anchor.download,
          anchor.href,
        ].join(":");
        anchor.click();
  
        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = exportData.map((row) => {
          const obj = {};
          header.forEach((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });
  
        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        // Returning false as downloading of file is already taken care of
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = exportData.map((row) => {
          return [
            row["Name"],
            row["Status"],
            row["Type"],
            row["Code"],
            row["SAQA Link"],
            row["#NQF"],
            row["School"],
          ];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            // valign: "center",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }
    }).catch((err)=>{
      console.log(err)
    })    
  };

  return (    
    <DataTableComponent
      data={data}
      columns={columns}
      loading={loading}
      search={search}
      setSearch={setSearch}
      state={tableState}
      setState={setTableState}
      totalRows={totalRows}
      exportFunction={exportData}
      exportFileName={"Programme_List"}    
      filters={[
        {
          filterName: "School",
          optionArr: filterData.school,
          state: school,
          setState: setSchool,
          renderLabelFunction : RenderSchool
        },
        {
            filterName: "Status",
            optionArr: filterData.status,
            state: status,
            setState: setStatus,
            renderLabelFunction: RenderPMCStatus,
        },
        {
          filterName: "Type",
          optionArr: filterData.type,
          state: type,
          setState: setType,
          renderLabelFunction: RenderProgrammeType,
      },
      {
        filterName: "NQF",
        optionArr: filterData.NQF,
        state: NQF,
        setState: setNQF,
        cell: (row) => <div title={row?.NQF_Level}>{row?.NQF_Level}</div>,
    }        
      ]}                
      tableButton={[
        <PermissionsGate
          RenderError={() => (
            <button
              className="btn btn-primary"
              title="Create New"
              disabled
            >
              <i className="fal fa-plus"></i>Create New
            </button>
          )}
          scopes={["pmcpadd"]}
        >
          <Link
            to={`/program-management-center/programmes/programme/add`}
          >
            <button className="btn btn-primary" title="Create New">
              <i className="fal fa-plus"></i>Create New
            </button>
          </Link>
        </PermissionsGate>
      ]}
    /> 
  );
};

export default Program;
