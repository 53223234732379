import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import {
  TermPeriods,
} from "../../systemadministration/DataReactSelect";
import { useFormik } from "formik";
import Select from "react-select";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import * as Yup from "yup";
import Swal from "sweetalert2";
import Papa from "papaparse";
import {
  AddProgram,
  UpdateProgramme,
  GetSchoolList,
  GetProgramDetailCancelToken,
  GetCampusLocationDropdown,
  GetBrandDropdown,
  GetPagesList,
  GetCategoryListStatic,
  GetPopUpListStatic,
  GetAssignedProgrammeAndShortCourseList,
  GetAssignedDropdownForPmc,
  GetListProgrammeAndShortCourse,
  GetDropdownForProgrammeAndShortCourse,
  GetLevelsList,
  AddShortCourse,
  UpdateShortCourse,
  GetShortCourseData,
  getAssignedDropdownForPmc,
  getPagesListForPmcFilters,
  getBrandTemplateList
} from "../../../services/ProgramService";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { RenderProgrammeOrCourseType, RenderProgrammeType } from "../../../utils/CommonGroupingItem";
import { RenderPMCPageStatus, RenderPMCStatus, RenderPMCStatusCol, RenderPageBrands } from "../../../utils/CommonStatusItems";
import HtmlParser from "react-html-parser";
import axios from "axios";
import { GetNetsuiteProductListCancelToken } from "../../../services/ProgrammeServices";
import { checkIfImageExists, modifiyOptionsForSelect } from "../../../utils/commonFunction";
import DataTableComponent from '../../common/DataTableComponent';
import { TrimText } from "../../common/TrimText";
import { downloadURL, IMAGE_URL } from "../../../utils/Constants";
import $ from 'jquery';
import DataTableComponentFrontPagination from "../../common/DataTableComponentFrontPagination";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
const statusArr = [
  {
    value: "Draft",
    label: HtmlParser(`<span className="red" title="Draft">Draft  </span> `)
  },
  {
    value: "New",
    label: HtmlParser(`<span className="skyblue" title="New"> New </span>`)
  },
  {
    value: "Final",
    label: HtmlParser(` <span className="green" title="Final">Final</span>`)
  },
  {
    value: "Approved",
    label: HtmlParser(`<span className="yellowshade" title="Approved">Approved</span>`)
  },
  {
    value: "Inactive",
    label: HtmlParser(`<span className="blue" title="Inactive">Inactive</span>`)
  },
  {
    value: "Published",
    label: HtmlParser(`<span className="blue" title="Published">Published</span>`)
  },
  {
    value: "Unpublished",
    label: HtmlParser(`<span className="blue" title="Unpublished">Unpublished</span>`)
  },
]

const creditStatusList = [
  {value : "0", label:"No Credits"},
  {value : "1", label:"Credit Bearing"},
]

export default function ShortCourseDetails({ detail }) {;
  const history = useHistory();
  const { id, type } = useParams();
  // const [detail, setDetail] = useState({});
  const [learning_method, setLearningMethodArr]=useState([])
  const [programId, setProgramID] = useState(id);
  const [disabled, setDisabled] = useState(false)

  const [editorState1, setEditorState1] = useState("");
  const [subjectContent, setSubjectContent] = useState("");
  const [admissionReq, setAdmissionReq] = useState("");
  const [accreditation, setAccreditation] = useState();
  const [articulationOption, setArticulationOption] = useState("");

  const [studyKit, setStudyKit] = useState("");
  const [careerOpportunity, setCareerOpportunity] = useState("");
  const [hardwareReq, setHardwareReq] = useState("");
  const [duration, setDuration] = useState("");
  const [regulatoryInfo, setRegulatoryInfo] = useState("");
  const [impNotes, setImpNotes] = useState("");
  
  const [editorToShow, setEditorToShow] = useState(false);
  
  const [schoolList, setSchoolList] = useState([]);
  const [campusLocList, setCampusLocList] = useState([]);
  const [netsuiteProdList, setNetsuiteProdList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [popupList, setPopupList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [levelList, setLevelList] = useState([]);
  
  const [assignPageList, setAssignPageList] = useState([]);
  const [allpageListData, setAllPageListData]=useState([])
  const [assignPageLoading, setAssignPageLoading] = useState(false);
  const [pageStatus, setPageStatus] = useState({ arr: [], checkObj: {} })
  const [pageBrand, setPageBrand] = useState({ arr: [], checkObj: {} })
  const [PageFilterData, setPageFilterdata] = useState({status:[],BrandList:[]});
  const [assignPageTableState, setAssignPageTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "PageTitle",
    sortOrder: "asc",
  })
  const [assignPageSearch, setAssignPageSearch] = useState("");
  const [assignPageTotalRows, setAssignPageTotalRows] = useState(0);
  const [assignedPage, setAssignedPage] = useState({});
  const [updateData, setUpdateData]=useState(false)

  const [articulationOptionList, setArticulationOptionList] = useState([]);
  const [articulationOptionLoading, setArticulationOptionLoading] = useState(false);
  const [articulationOptionFilterData, setArticulationOptionFilterData] = useState({status:[],programmeType:[],type:[]});
  const [articulationOptionSearch, setArticulationOptionSearch] = useState("");
  const [articulationOptionTotalRow, setArticulationOptionTotalRow] = useState("");
  const [articulationOptionTableState, setArticulationOptionTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "name",
    sortOrder: "asc",
  })
  const [artStatus, setArtStatus] = useState({ arr: [], checkObj: {} })
  const [artProgType, setArtProgType] = useState({ arr: [], checkObj: {} })
  const [artType, setArtType] = useState({ arr: [], checkObj: {} })
  
  const [assignedDataList, setAssignedDataLis]= useState([])
  const [articulationAssignedTotalRow, setArticulationAssignednTotalRow] = useState("");
  const [assignedArticulationOptionList, setAssignedArticulationOptionList] = useState([]);
  const [assignedArticulationOptionSearch, setAssignedArticulationOptionSearch] = useState("");
  const [assignedArticulationOptionLoading, setAssignedArticulationOptionLoading] = useState(false);
  const [assignedArticulationOptionFilterData, setAssignedArticulationOptionFilterData] = useState({status:[],programmeType:[],type:[]});
  const [assignedStatus, setAssignedStatus] = useState({ arr: [], checkObj: {} })
  const [assignedProgType, setAssignedProgType] = useState({ arr: [], checkObj: {} })
  const [assignedType, setAssignedType] = useState({ arr: [], checkObj: {} })
  const [articulationAssignedTableState, setArticulationAssignedTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "name",
    sortOrder: "asc",
  })
  const dropDownValues = useSelector(state => state.registerDropdownValues);

  let learning = detail?.learning_method?.map((data) => data.learning_method);

  useEffect(() => {
    const cancelTokenSources = [];
    const cancelDropdownSource1 = [];
    const cancelDropdownSource2 = [];
    const cancelDropdownSource3 = [];
    const cancelDropdownSource4 = [];
    const cancelDropdownSource5 = [];
    const cancelDropdownSource6 = [];
    const cancelDropdownSource7 = [];

    const getSchool = async () =>{
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetSchoolList();
        if(res.status==200){
          setSchoolList(res.data.data)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);          
        }
      }
    }
    
    const getCampusLocationData = async () =>{
      cancelDropdownSource1.forEach(source =>{
        source.cancel('New request made');
      });
      const source1 = axios.CancelToken.source();
      cancelDropdownSource1.push(source1);

      try {
        const res = await GetCampusLocationDropdown(source1.token);
        if(res.status == 200){
          setCampusLocList(res.data.campusLocationFilters);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);          
        }
      }
    }

    const getNetsuiteProductData = async () =>{
      cancelDropdownSource2.forEach(source =>{
        source.cancel('New request made');
      });
      const source2 = axios.CancelToken.source();
      cancelDropdownSource2.push(source2);

      try {
        const res = await GetNetsuiteProductListCancelToken("", source2.token);
        if(res.status == 200){
          const respData = res.data.data && res.data.data.items ? res.data.data.items : [];
          const modifyResponse = modifiyOptionsForSelect(respData,"netsuite");
          setNetsuiteProdList(modifyResponse);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);          
        }
      }
    }
    const getBrandData = async () => {
      cancelDropdownSource3.forEach((source) => {
        source.cancel("New request made");
      });
      const source3 = axios.CancelToken.source();
      cancelDropdownSource3.push(source3);

      try {
        const res = await getBrandTemplateList(source3.token);
        if (res.status == 200) {
          setBrandList(res.data?.brandTypesFilters);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    // const getBrandData = async () =>{
    //   cancelDropdownSource3.forEach(source =>{
    //     source.cancel('New request made');
    //   });
    //   const source3 = axios.CancelToken.source();
    //   cancelDropdownSource3.push(source3);

    //   try {
    //     const res = await GetBrandDropdown(source3.token);
    //     if(res.status == 200){
    //       setBrandList(res.data.BrandFilters);
    //     }
    //   } catch (error) {
    //     if (!axios.isCancel(error)) {
    //       console.error(error);          
    //     }
    //   }
    // }

    const getPopupData = async () =>{
      cancelDropdownSource4.forEach(source =>{
        source.cancel('New request made');
      });
      const source4 = axios.CancelToken.source();
      cancelDropdownSource4.push(source4);

      try {
        const res = await GetPopUpListStatic(source4.token);
        if(res.status == 200){
          setPopupList(res.data.Data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);          
        }
      }
    }

    const getCategoryData = async () =>{
      cancelDropdownSource5.forEach(source =>{
        source.cancel('New request made');
      });
      const source5 = axios.CancelToken.source();
      cancelDropdownSource5.push(source5);

      try {
        const res = await GetCategoryListStatic(source5.token);
        if(res.status == 200){
          setCategoryList(res.data.Data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);          
        }
      }
    }
    
    const getLevelData = async () =>{
      cancelDropdownSource6.forEach(source =>{
        source.cancel('New request made');
      });
      const source6 = axios.CancelToken.source();
      cancelDropdownSource6.push(source6);

      try {
        const res = await GetLevelsList(source6.token);
        if(res.status == 200){
          setLevelList(res.data.levels);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);          
        }
      }
    }

    const getPageFilters = async () =>{
      cancelDropdownSource7.forEach(source =>{
        source.cancel('New request made');
      });
      const source3 = axios.CancelToken.source();
      cancelDropdownSource7.push(source3);

      try {
        const res = await getPagesListForPmcFilters(source3.token);
        if(res.status == 200){
          setPageFilterdata({
            BrandList: res.data?.getBrandtemplate,
            status:res.data?.getStatus
          })
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);          
        }
      }
    }

    GetDropdownForProgrammeAndShortCourse().then((res)=>{
      if(res.status === 200){
        const respData = res.data;
        setArticulationOptionFilterData({
          ...articulationOptionFilterData,
          status : respData.status,
          programmeType : respData.programmeType,
          type : respData.type
        });
      }
    })

    if (type == "open" || type == "duplicate") {
      getAssignedDropdownForPmc({
        id: id,
        type_assigned: 1
      }).then((res) => {
        if (res.status === 200) {
          const respData = res.data;
          setAssignedArticulationOptionFilterData({
            ...articulationOptionFilterData,
            status: respData.status,
            programmeType: respData.programmeType,
            type: respData.type
          });
        }
      }).catch((err) => {
        console.log(err);
      })
  }

    getSchool();
    getCampusLocationData();
    getNetsuiteProductData();
    getBrandData();
    getPopupData();
    getCategoryData();
    getLevelData();
    getPageFilters()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
      cancelDropdownSource1.forEach(source =>{
        source.cancel('Component unmounted');
      });
      cancelDropdownSource2.forEach(source =>{
        source.cancel('Component unmounted');
      });
      cancelDropdownSource3.forEach(source =>{
        source.cancel('Component unmounted');
      });
      cancelDropdownSource4.forEach(source =>{
        source.cancel('Component unmounted');
      });
      cancelDropdownSource5.forEach(source =>{
        source.cancel('Component unmounted');
      });
      cancelDropdownSource6.forEach(source =>{
        source.cancel('Component unmounted');
      });
    };
    
  }, [type,id, updateData]);


  useEffect(() => {
    const assignPage = assignPageList.filter((page) => {
      return page.id === formik.values.terms_conditions_page;
    });
    setAssignedPage(assignPage);
  }, [assignPageList, ]);

  useEffect(()=>{
    const cancelTokenSources = [];

    const getAssignPageListData = async () =>{    
      setAssignPageLoading(true)  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const payload = {
          page:assignPageTableState.page,
          perPage:assignPageTableState.perPage,
          search:assignPageSearch,
          key:assignPageTableState.sortKey,
          sort:assignPageTableState.sortOrder,
          exportStatus:"false",
          setSearch:setAssignPageSearch,
          viaBrand:pageBrand.arr,
          viaStatus:pageStatus.arr,
        }
        const res = await GetPagesList(payload, source.token);
        if(res.status==200){
          setAssignPageTotalRows(res.data?.Data?.total);
          setAssignPageList(res.data?.Data?.data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      } finally {
        setAssignPageLoading(false);
      }
    }

    getAssignPageListData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [assignPageTableState, assignPageSearch, pageStatus,pageBrand ])


  const dataToRender = () => {
    let updatedData = [];
    let allData = [...assignedArticulationOptionList];

    if (assignedArticulationOptionSearch.length) {
      let name = allData.filter((item) => {
              let includes = item.name
                ?.toString()
                ?.toLowerCase()
                ?.includes(assignedArticulationOptionSearch.toLowerCase());
              if (includes) {
                return includes;
              } else return null;
            });
      
            let data = [...name];
            let unique = [...new Set(data)];
      
            updatedData = unique;
    }
    else {
      updatedData = allData
    }

    if (assignedStatus.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = assignedStatus.arr.find(function (post, index) {
          if (item.status && post.toLowerCase() == item.status.toLowerCase() ? item.status : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (assignedProgType.arr.length) {
      let tempProgType = updatedData;
      let tempResult = tempProgType.filter((item) => {
        const startsWith = assignedProgType.arr.find(function (post, index) {
          if (item.qualification_type && post == item.qualification_type ? item.qualification_type : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (assignedType.arr.length) {
      let tempType = updatedData;
      let tempResult = tempType.filter((item) => {
        const startsWith = assignedType.arr.find(function (post, index) {
          if (item?.pmc_type?.toString() && post == (item?.pmc_type?.toString() ? item?.pmc_type?.toString() : "")) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (
      assignedArticulationOptionSearch.length ||
      assignedStatus.arr.length ||
      assignedProgType.arr.length ||
      assignedType.arr.length 
    ) {  
      const uniqueData = updatedData.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id == value.id && t?.pmc_type == value?.pmc_type && t?.type == value?.type
        )))
      return uniqueData
    } else {
      const uniqueData = allData.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id == value.id && t?.pmc_type == value?.pmc_type && t?.type == value?.type
        )))
      return uniqueData;
    }
  };

  useEffect(()=>{
    if(type == "open" || type == "duplicate"){
      const payload = {
        id:id,
        // page:articulationAssignedTableState.page,
        // limt:articulationAssignedTableState.perPage,
        // key:articulationAssignedTableState.sortKey,
        // sort:articulationAssignedTableState.sortOrder,
        exportStatus:"true",
        type_assigned:1,
        search:assignedArticulationOptionSearch,
        setSearch:setAssignedArticulationOptionSearch,
        viaProgrammeType:assignedProgType.arr,
        viaType:assignedType.arr,
        viaStatus:assignedStatus.arr
      }
      GetAssignedProgrammeAndShortCourseList(payload).then((res)=>{
        if(res.status === 200){
          const respData = res.data.data;
          setAssignedArticulationOptionList(respData.resData);
          setArticulationAssignednTotalRow(respData.pagination.total)
          const assignedData = respData.resData?.map(
            ({ id, type, pmc_type }) => ({
              id,
              pmc_type: type || pmc_type,
            })
          );
          setAssignedDataLis(assignedData)
        }
        
      }).catch((err)=>{
        console.log(err)
      })   
    }   
  },[id, updateData])

  useEffect(() => {
    if (detail) {
      // setLearningMethodArr(detail?.learning_method ? detail?.learning_method : []);
      setSubjectContent(detail.Description);
      setAdmissionReq(detail?.prerequisites);
      setAccreditation(detail.accreditation);
      setArticulationOption(detail.articultion_option);
      setCareerOpportunity(detail.career_opportunities);
      setStudyKit(detail.study_kit);
      setHardwareReq(detail.hardware_requirement);
      setDuration(detail.duration_dec);
      setRegulatoryInfo(detail.regulatory_inform);
      setImpNotes(detail.important_notes);
      setEditorToShow(true)
    }
  }, [detail]);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["pmcpedit"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [id])

  useEffect(() =>{
    const payload = {
      page: articulationOptionTableState.page,
      limit: articulationOptionTableState.perPage,
      key: articulationOptionTableState.sortKey,
      sort: articulationOptionTableState.sortOrder,
      exportStatus:false,
      search:articulationOptionSearch,
      viaStatus:artStatus.arr,
      viaType:artType.arr,
      viaProgrammeType:artProgType.arr,
      id:id
    }
    setArticulationOptionLoading(true);
    GetListProgrammeAndShortCourse(payload).then((res)=>{
      if(res.status === 200){
        const respData = res.data.data;
        setArticulationOptionList(respData.resData);
        setArticulationOptionTotalRow(respData.pagination.total)
      }
    }).catch((err)=>{
      console.log(err);
    }).finally(()=>{
      setArticulationOptionLoading(false);
    });



  },[articulationOptionTableState, artType, artProgType, artStatus, articulationOptionSearch,id])  
  
   
  // outcomes:Outcomes
  
  
  
  const formik = useFormik({
    enableReinitialize: type === "open" || type === "duplicate" ? true : false,
    initialValues: {
      duplicateId:"",
      name: detail?.name ? detail?.name : "",
      Code: detail?.Code ? detail.Code : "",
      School: detail?.School ? detail.School : "",
      Delivery_Methods: learning || [],
      level : detail?.level ? detail?.level : "",
      // credit_status : detail?.credit_status ? detail?.credit_status : "",
      credit_status: detail.credit_status !== undefined ? String(detail.credit_status) : "",
      Credits: detail?.Credits !== null ? detail.Credits : "",
      SAQA_Link: detail?.SAQA_Link ? detail.SAQA_Link : "",      
      Duration: detail?.Duration ? detail.Duration : "",
      campus_location: detail?.campus_location ? detail.campus_location : "",
      // course_delivery : detail?.course_delivery ? detail.course_delivery : [], 
      course_delivery: detail?.course_delivery
      ? detail.course_delivery?.map((item) => item?.delivery_method)
      : [],  
      netsuite_department_id: detail?.netsuite_department_id ? detail.netsuite_department_id : "",
      netsuite_product: detail?.netsuite_product ? detail.netsuite_product : "",
      brandID: detail?.brandID ? detail.brandID : "",
      status: detail?.status ? detail.status : "",
      popupID: detail?.popupID ? detail.popupID : "",
      categories: detail?.categories ? detail.categories : "",
      banner_image: detail?.banner_image ? detail.banner_image : "",
      banner_image_url: detail?.banner_image ? detail.banner_image : "",
      career_video: detail?.career_video ? detail.career_video : "",
      career_video_url: detail?.career_video ? detail.career_video : "",
      auto_generate_fectsheet : detail?.auto_generate_fectsheet ? detail?.auto_generate_fectsheet == 1 : false,
      override_default_tc : detail?.override_default_tc ? detail?.override_default_tc == 1 : false,
      terms_conditions_page : detail?.terms_conditions_page ? detail?.terms_conditions_page : "",

      Description:"",
      admission_req: "",
      accreditation:"",

      articultion_option: "",
      articulation_assign : assignedDataList,

      career_opportunities: "",
      study_kit:"",

      hardware_requirement:"",
      core_spec:detail?.core_spec ? detail?.core_spec : 0,
      med_spec:detail?.med_spec ? detail?.med_spec : 0,
      high_spec:detail?.high_spec ? detail?.high_spec : 0,

      full_time_hybrid_fee:detail?.full_time_hybrid_fee !== null ? detail?.full_time_hybrid_fee : "",
      full_time_online_fee:detail?.full_time_online_fee !== null ? detail?.full_time_online_fee : "",
      part_time_online_fee:detail?.part_time_online_fee !== null ? detail?.part_time_online_fee : "",
      self_paced_fee:detail?.self_paced_fee !== null ? detail?.self_paced_fee : "",
      
      registration_fee:detail?.registration_fee ? detail?.registration_fee : "",
      cost_terms:detail?.cost_terms ? detail?.cost_terms : "",
      installments: detail?.installments ? detail?.installments : "",
      earlyBirds: ((type === "open" || type === "duplicate") && detail?.earlyBirds !== "") ? detail?.earlyBirds : 2,

      duration_dec: "",
      outcomes: "",
      important_notes: "",

      conversion_code:detail?.conversion_code ? detail?.conversion_code : "",
      meta_title:detail?.meta_title ? detail?.meta_title : "",
      meta_description:detail?.meta_description ? detail?.meta_description : "",
      meta_keywords:detail?.meta_keywords ? detail?.meta_keywords : ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Course Name is required").max(255, "Course Name should not be greater than 255 Character").trim(),
      // School: Yup.string().required("School is required").trim(),
      Delivery_Methods: Yup.string().required("Learning Methods is required"),
      // level: Yup.string().required("Level is required"),
      // credit_status: Yup.string().required("Credits is required"),
      // Credits: Yup.string().required("Credits is required"),
      campus_location: Yup.string().required("Campus Location is required").trim(),      
      
      netsuite_product: Yup.string().required("Netsuite Product is required"),
      // brandID: Yup.string().required("Assigned Brand is required"),
      status: Yup.string().required("Status is required"),
      // banner_image: Yup.string().required("Banner Image is required"),
      // career_video: Yup.string().required("Career Video is required"),
      // terms_conditions_page: Yup.string().required("Assigned Terms & Conditions Page is required"),
      terms_conditions_page: Yup.string().when("override_default_tc", {
        is: true,
        then: Yup.string().required("Assigned Terms & Conditions Page is required"),
      }),

      full_time_hybrid_fee: Yup.string().when('Delivery_Methods', {
        is: (methods) => {
          let arr= methods.split(",");
           return arr.includes("full")
        },
        then: Yup.string().required("Full Time, Hybrid Fee is required"),
        otherwise: Yup.string(),
      }),
           
      full_time_online_fee: Yup.string().when('Delivery_Methods', {
        is: (methods) => methods.includes("fulltimeonline"),
        then: Yup.string().required(
          "Full Time, Online Fee is required"
        ),
        otherwise: Yup.string(),
      }),
      part_time_online_fee: Yup.string().when('Delivery_Methods', {
        is: (methods) => methods.includes("parttimeonline"),
        then: Yup.string().required(
          "Part Time, Online Fee is required"
        ),
        otherwise: Yup.string(),
      }),
      self_paced_fee: Yup.string().when('Delivery_Methods', {
        is: (methods) => methods.includes("selfplaced"),
        then: Yup.string().required(
          "Self Paced, Online Fee is required"
        ),
        otherwise: Yup.string(),
      }),
      
      // registration_fee: Yup.string().required("Registration Fee is required"),
      
    }),
    onSubmit: (values) => {
      
      setDisabled(true);
      let formData = new FormData();

      formData.append('name', values.name);
      formData.append('Code', values.Code);
      formData.append('School', values.School);
      formData.append('Delivery_Methods', values.Delivery_Methods);
      formData.append('level', values.level);
      formData.append('credit_status', values.credit_status);
      formData.append('Credits', values.Credits);
      formData.append('SAQA_Link', values.SAQA_Link);
      formData.append('Duration', values.Duration);
      formData.append('campus_location', values.campus_location);
      formData.append('course_delivery', values.course_delivery);

      formData.append('netsuite_product', values.netsuite_product);
      formData.append('brandID', values.brandID);
      formData.append('status', values.status);
      formData.append('popupID', values.popupID);
      formData.append('categories', values.categories);
      formData.append('banner_image', values.banner_image);
      formData.append('career_video', values.career_video);
      formData.append('auto_generate_fectsheet', (values.auto_generate_fectsheet === true ? 1 : 0));
      formData.append('override_default_tc', (values.override_default_tc == true ? 1 : 0));
      formData.append('terms_conditions_page', values.terms_conditions_page);
      
      formData.append('Description', subjectContent);
      formData.append('prerequisites', admissionReq);
      formData.append('accreditation', accreditation);
      formData.append('articultion_option', articulationOption);
      formData.append('articulation_assign', JSON.stringify(values.articulation_assign));
      formData.append('career_opportunities', careerOpportunity);
      formData.append('study_kit', studyKit);
      formData.append('hardware_requirement', hardwareReq);
      formData.append('core_spec', values.core_spec);
      formData.append('med_spec', values.med_spec);
      formData.append('high_spec', values.high_spec);
      formData.append('full_time_hybrid_fee', values.full_time_hybrid_fee);
      formData.append('full_time_online_fee', values.full_time_online_fee);
      formData.append('part_time_online_fee', values.part_time_online_fee);
      formData.append('self_paced_fee', values.self_paced_fee);
      
      formData.append('registration_fee', values.registration_fee);
      formData.append('cost_terms', values.cost_terms);
      formData.append('installments', values.installments);
      formData.append('earlyBirds', values.earlyBirds === "" ? 2 : values.earlyBirds);
      formData.append('duration_dec', duration);
      formData.append('outcomes', regulatoryInfo);
      formData.append('important_notes', impNotes);
      formData.append('conversion_code', values.conversion_code);
      formData.append('meta_title', values.meta_title);
      formData.append('meta_description', values.meta_description);
      formData.append('meta_keywords', values.meta_keywords);
      formData.append("netsuite_department_id",  values?.netsuite_department_id)
      
      if (type === "open") {
        // values = { ...values, programId: id };
        formData.append('id', id);
        UpdateShortCourse(formData)
          .then((res) => {
            setDisabled(false);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Updated Successfully",
            });
            setUpdateData(!updateData)
            formik.resetForm()
            // history.push(
            //   `/program-management-center/programmes/programme/open/${id}`
            // );
          })
          .catch((err) => {
            setDisabled(false);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: err.response.data.message,
            });
          });
      } else {
        if (type == "duplicate") {
          // values = { ...values, duplicateId: id };
          formData.append('duplicateId', id);
        }
        AddShortCourse(formData)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added Successfully",
            });
            setProgramID(res.data.id);
            history.push(
              `/program-management-center/programmes/shortCourse/table`
            );
          })
          .catch((err) => console.log(err));
      }
    },
  });

  useEffect(() => {
    const data = netsuiteProdList.filter((item) => item.value == formik.values?.netsuite_product);
    const netsuit_dId = data[0]?.departmentId;
    
    if (netsuit_dId) {
      formik.setFieldValue("netsuite_department_id", netsuit_dId);
    }
  }, [netsuiteProdList, formik.values?.netsuite_product]);
  
  const handleChangeInstallments = (value) => {
    const prevValue = formik.values.installments.split(",").filter(item => item !== "");  
    if (prevValue.includes(value)) {
      const ind = prevValue.findIndex(item => item === value);
      prevValue.splice(ind, 1);      
    } else {
      prevValue.push(value);      
    }
    formik.setFieldValue("installments", prevValue.join(","));
  };

  const handlereset = async () => {
    history.push(`/program-management-center/programmes/shortCourse/table`);
  };

  const assignPageColumn = useMemo(() => [
    {
        name: "Name",
        selector: "name",
        sortable: true,
        sortField: "PageTitle",
        // wrap: true,
        cell: (row) => (
            <span
                className="as-text-blue curser feature-name"
                // to={{
                //     pathname: `/courseAdministration/Programmes/programme/open/${row.qualification}/details`,
                // }}
                onClick={() => {
                      formik.setFieldValue("terms_conditions_page", row.PageID);
                      setAssignedPage({...row});
                      $("#assignPagePopup").modal("hide");
                    }}
                title={row.title}
            >
                <span className="textLimit100">
                    {row.PageTitle ? row.PageTitle : "-"}
                </span>
            </span>
        ),
    },
    {
        name: "Status",
        selector: "Status",
        sortField: "status",
        sortable: true,
        cell: (row) => (row.status ? RenderPMCStatusCol(row.status).html : "-"),
    }, 
    {
      name: "Brand Templates",
      selector: "Brand Templates",
      sortable: false,
      cell: (row) => <p className="d-flex flex-wrap">
        {row?.brandTemplete?.length > 0  ? row?.brandTemplete.map((brand, index) => brand?.templateName &&  <div className="brandList-pmc" title={brand?.templateName}>
            {brand?.templateName}
        </div>) : "-"}
      </p>,
  },       
    {
        name: "Action",
        selector: "",
        maxWidth: "50px",
        cell: (row) => (
          <div className="assessment-08">
            <div className="as-buttons">
              {formik.values.terms_conditions_page != row.PageID ? 
                <button
                    type="button"
                    className="btn btn-primary rounded-circle"
                    onClick={() => {
                      formik.setFieldValue("terms_conditions_page", row.PageID);
                      setAssignedPage({...row});
                      $("#assignPagePopup").modal("hide");
                    }}
                    title="Assign Page"
                >
                    <i className="fal fa-plus"></i>
                </button>
              :
                <button
                  type="button"
                  className="btn btn-danger rounded-circle"
                  onClick={() => {
                    formik.setFieldValue("terms_conditions_page", "");
                    setAssignedPage({});                    
                  }}
                  title="Assign Page"
                >
                  <i className="fal fa-minus"></i>
                </button>
              } 

            </div>
          </div>
        ),
    },
  ]);

  useEffect(()=>{
    const payload = {
      // page:assignPageTableState.page,
      // perPage:assignPageTableState.perPage,
      // search:assignPageSearch,
      // key:assignPageTableState.sortKey,
      // sort:assignPageTableState.sortOrder,
      exportStatus:"true",
      // setSearch:setAssignPageSearch,
      // viaBrand:pageBrand.arr,
      // viaStatus:pageStatus.arr,
    }

  GetPagesList(payload).then((res)=>{
    if(res.status==200){
      setAllPageListData(res.data?.Data)
    }
  })
  .catch((err)=>console.log(err))
  },[])

  const exportAssignPageData = (fileType, fileName) => {
    let exportData=[...assignedArticulationOptionList]
    const header = ["Name",  "Status", "Brand Template"];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
          Swal.showLoading();
      },
    });

      const payload = {
        page:assignPageTableState.page,
        perPage:assignPageTableState.perPage,
        search:assignPageSearch,
        key:assignPageTableState.sortKey,
        sort:assignPageTableState.sortOrder,
        exportStatus:true,
        // setSearch:setAssignPageSearch,
        viaBrand:pageBrand.arr,
        viaStatus:pageStatus.arr,
      }

    GetPagesList(payload).then((res)=>{
      exportData = res.data.Data;
      exportData = exportData?.map((row) => ({
        ...row,
          Name: row?.PageTitle ? row?.PageTitle : "-",
          Status: row?.status ? (row.status) : "-",
        "Brand Template": row?.brandTemplete?.length > 0 ? row?.brandTemplete.map((brand, index) => brand?.templateName && (brand?.templateName)).join(",") : "-"
      }));
  
      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, exportData });
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
  
        const blobURL = window.URL.createObjectURL(blob);
  
        // Create new tag for download file
        const anchor = document.createElement("a"); 
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [
          "text/csv",
          anchor.download,
          anchor.href,
        ].join(":");
        anchor.click();
  
        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = exportData.map((row) => {
          const obj = {};
          header.forEach((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });
  
        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        // Returning false as downloading of file is already taken care of
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = exportData.map((row) => {
          return [
            row["Name"],
            row["Status"],
            row["Brand Template"],
          ];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            // valign: "center",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }
    }).catch((err)=>{
      console.log(err)
    })   
  };

  const exportDataAssignedArticulationOption = () => {
    let data = dataToRender();
    const header = ["Name", "Code", "Status", "Programme Type", "Type"];
    data = data.map((row) => ({
      Name: row?.name ? row?.name : "-",
      Code: row.Code ? row.Code : "-",
      Status: row.status ? row.status : "-",
      "Programme Type": row.qualification_type
        ? RenderProgrammeType(row?.qualification_type).text
        : "-",
      Type:
      row?.pmc_type !== null && row?.pmc_type !== undefined
      ? RenderProgrammeOrCourseType(row.pmc_type).text
      : row?.type !== null && row?.type !== undefined
      ? RenderProgrammeOrCourseType(row.type).text
      : "-",

    }));
    const pdfData = data.map((row) => {
      return [
        row["Name"],
        row["Code"],
        row["Status"],
        row["Programme Type"],
        row["Type"],
      ];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  };


const exportDataArticulationOption = (fileType, fileName) => {
  let exportData = [...articulationOptionList]
  const header = ["Name", "Code", "Status", "Programme Type", "Type"];

  Swal.fire({
    title: "File downloading",
    onOpen: function () {
      Swal.showLoading();
    },
  });

  const payload = {
    page: articulationOptionTableState.page,
    limit: articulationOptionTableState.perPage,
    key: articulationOptionTableState.sortKey,
    sort: articulationOptionTableState.sortOrder,
    exportStatus: true,
    search: articulationOptionSearch,
    viaStatus: artStatus.arr,
    viaType: artType.arr,
    viaProgrammeType: artProgType.arr,
    id: id
  }

  GetListProgrammeAndShortCourse(payload).then((res) => {
    exportData = res.data.data?.resData;
    exportData = exportData?.map((row) => ({
      ...row,
      Name: row?.name ? row?.name : "-",
      Code: row.Code ? row.Code : "-",
      Status: row.status ? (row.status) : "-",
      "Programme Type": row.qualification_type ? RenderProgrammeType(row?.qualification_type).text : "-",
      Type: row.type != null ? RenderProgrammeOrCourseType(row?.type).text : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, exportData });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
      Swal.close();
    } else if (fileType === "xlsx") {
      const compatibleData = exportData.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);
      Swal.close();
      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = exportData.map((row) => {
        return [
          row["Name"],
          row["Code"],
          row["Status"],
          row["Programme Type"],
          row["Type"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);
      Swal.close();
      return false;
    }
  }).catch((err) => {
    console.log(err)
  })
};


const articulationOptionColumn = useMemo(() => [
  {
      name: "Name",
      selector: "name",
      sortable: true,
      sortField: "name",
      cell: (row) => (
          <span
            className="as-text-blue curser feature-name"
            title={row.name}
          >
            <span className="textLimit100">
              {row.name ? row.name : "-"}
            </span>
          </span>
      ),
  },
  {
      name: "Code",
      selector: "code",
      sortable: true,
      sortField: "code",
      cell: (row) => (row.Code ? row.Code : "-"),
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    sortField: "status",
    cell: (row) => (row.status!=null ? RenderPMCStatus(row.status).html : "-"),
  }, 
  {
    name: "Programme Type",
    selector: "qualification_type",
    sortable: true,
    sortField: "qualification_type",
    cell: (row) => (row.qualification_type != null ? RenderProgrammeType(row.qualification_type).html : "-"),
  },
  {
    name: "Type",
    selector: "type",
    sortable: true,
    sortField: "type",
    cell: (row) => (row.type!=null ? RenderProgrammeOrCourseType(row.type).html : "-"),
  },       
  {
      name: "Action",
      selector: "",
      maxWidth: "50px",
      cell: (row) => {
        const isAssigned = assignedArticulationOptionList.some(
          (item) =>
            item.id === row.id &&
            (item.type || item.pmc_type) == (row.type || row.pmc_type)
        );
        const handleAssign = () => {
          setAssignedArticulationOptionList((prevList) => {
            const updatedList = isAssigned
              ? prevList.filter(
                  (item) =>
                    !(
                      item.id === row.id &&
                      (item.type || item.pmc_type) ==
                        (row.type || row.pmc_type)
                    )
                )
              : [...prevList, { ...row, isAssigned: true }];

            const assignedData = updatedList.map(
              ({ id, type, pmc_type }) => ({
                id,
                pmc_type: type || pmc_type,
              })
            );

            formik.setFieldValue("articulation_assign", assignedData);
            return updatedList;
          });
        };

        return (
          <div className="assessment-08">
            <div className="as-buttons">
              <button
                type="button"
                className={`btn rounded-circle ${
                  isAssigned ? "btn-danger" : "btn-primary"
                }`}
                onClick={handleAssign}
                title={isAssigned ? "Unassign Page" : "Assign Page"}
              >
                <i className={`fal fa-${isAssigned ? "minus" : "plus"}`}></i>
              </button>
            </div>
          </div>
        );
      },
  },
]); 

const sortingName = (rowA, rowB) => {
  const name1 = rowA.name ? rowA.name.toString() : "";
  const name2 = rowB.name ? rowB.name.toString() : "";
  return name1.localeCompare(name2);
};

const sortingCode = (rowA, rowB) => {
  const code1 = rowA.Code ? rowA.Code.toString() : "";
  const code2 = rowB.Code ? rowB.Code.toString() : "";
  return code1.localeCompare(code2);
};

const sortingProgrammeType = (rowA, rowB) => {
  const qualification_name1 = rowA.qualification_name ? rowA.qualification_name.toString() : "";
  const qualification_name2 = rowB.qualification_name ? rowB.qualification_name.toString() : "";
  return qualification_name1.localeCompare(qualification_name2);
};

const sortingType = (rowA, rowB) => {
  const type1 = rowA?.pmc_type || rowA?.type  ? rowA?.type?.toString() || rowA?.pmc_type?.toString() : "";
  const type2 = rowB?.pmc_type || rowB?.type  ? rowB?.type?.toString() || rowB?.pmc_type?.toString() : "";
  return type1.localeCompare(type2);
};


  const ArticulationAssigncolumns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortFunction: sortingName,
      sortField: "name",
      sortable: true,
      cell: (row) => (
        <Link
        to={`/program-management-center/programmes/${
          row?.pmc_type === "0" || row?.type === "0" ||  row?.pmc_type === 0 || row?.type === 0 
            ? "programme"
            : "shortCourse"
        }/open/${row.id}`}
        title={row.name}
        className="as-text-blue curser feature-name"
      >
        <span title={row.name} className="textLimit100">
          {row.name}
        </span>
      </Link>
      
      ),
    },
    {
      name: "Code",
      selector: "Code",
      sortField: "Code",
      sortFunction: sortingCode,
      sortable: true,
      cell: (row) => (row.Code ? row.Code : "-"),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row) => (row.status != null ? RenderPMCStatus(row.status).html : "-"),
    },
    {
      name: "Programme Type",
      selector: "qualification_type",
      sortField: "qualification_type",
      sortable: true,
      sortFunction: sortingProgrammeType,
      cell: (row) => (row.qualification_type != null ? RenderProgrammeType(row.qualification_type).html : "-"),
    },
    {
      name: "Type",
      selector: "type",
      sortField: "pmc_type",
      sortable: true,
      sortFunction: sortingType,
      cell: (row) =>
      row?.pmc_type !== null && row?.pmc_type !== undefined
        ? RenderProgrammeOrCourseType(row.pmc_type).html
        : row?.type !== null && row?.type !== undefined
        ? RenderProgrammeOrCourseType(row.type).html
        : "-",    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <Link
              to={`/program-management-center/programmes/${
                row?.pmc_type === "0" ||
                row?.type === "0" ||
                row?.pmc_type === 0 ||
                row?.type === 0
                  ? "programme"
                  : "shortCourse"
              }/open/${row.id}`}
              className="btn btn-primary rounded-circle"
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </Link>

            <button
              title="Delete"
              type="button"
              className="btn btn-danger rounded-circle"
              onClick={() => {
                setAssignedArticulationOptionList((prevList) => {
                  const updatedList = prevList?.filter((item) => {
                    const itemType = item.pmc_type || item.type;
                    const rowType = row.pmc_type || row.type;

                    return !(
                      item.id === row.id &&
                      itemType?.toString() === rowType?.toString()
                    );
                  });

                  const assignedData = updatedList?.map(
                    ({ id, type, pmc_type }) => ({
                      id,
                      pmc_type: type || pmc_type,
                    })
                  );

                  formik.setFieldValue("articulation_assign", assignedData);

                  return updatedList;
                });
              }}
            >
              <i className="fal fa-trash-alt"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);





  const editorArr = [    
    {
      name: "Course Content",
      id: "one",
      component: <HtmlInputEditor
        editorState={subjectContent}
        setEditorState={setSubjectContent}
        hideSign={true}
        belowNoMargin={true}
        isCKEditor={true}
      />,
      isInvalid: formik.errors.Description && formik.touched.Description
    },
    {
      name: "Prerequisites",
      id: "two",
      component: <HtmlInputEditor
        editorState={admissionReq}
        setEditorState={setAdmissionReq}
        hideSign={true}
        belowNoMargin={true}
        isCKEditor={true}
      />,
      isInvalid: formik.errors.admission_req && formik.touched.admission_req
    },
    {
      name: "Accreditation",
      id: "three",
      component: <HtmlInputEditor
        editorState={accreditation}
        setEditorState={setAccreditation}
        hideSign={true}
        belowNoMargin={true}
        isCKEditor={true}
      />,
      isInvalid: formik.errors.accreditation && formik.touched.accreditation
    },
    {
      name: "Articulation Option",
      id: "four",
      component: 
      <>
      <div className="mb-3">  
      <DataTableComponentFrontPagination
              data={dataToRender()}
              columns={ArticulationAssigncolumns}
              loading={assignedArticulationOptionLoading}
              search={assignedArticulationOptionSearch}
              setSearch={setAssignedArticulationOptionSearch}
              exportData={exportDataAssignedArticulationOption}
              exportFileName={"Articulation_Option"}
              // defaultSort={{ defaultSortColumn: "start", defaultSortAsc: false }}
              filters={[
                {
                  filterName: " Status",
                  optionArr: assignedArticulationOptionFilterData.status,
                  state: assignedStatus,
                  setState: setAssignedStatus,
                  renderLabelFunction: RenderPMCStatus,
                  uniqueId: "assignedStatus"
                },
                {
                  filterName: " Programme Type",
                  optionArr:
                  assignedArticulationOptionFilterData.programmeType,
                  state: assignedProgType,
                  setState: setAssignedProgType,
                  uniqueId: "assignProgrammeType",
                  renderLabelFunction: RenderProgrammeType,
                },
                {
                  filterName: " Type",
                  optionArr: assignedArticulationOptionFilterData.type,
                  state: assignedType,
                  setState: setAssignedType,
                  uniqueId: "assignType",
                  renderLabelFunction: RenderProgrammeOrCourseType,
                },
              ]}
              tableButton={[
                <button
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#assignArticulationPopup"
                  title="Assign Articulation Option"
                  type="button"
                >
                  <i className="fal fa-plus"></i>Assign Articulation Option
                </button>,
              ]}
            />    
      </div>
      <label htmlFor="art_additional_info">Additional Information</label>
      <HtmlInputEditor
        editorState={articulationOption}
        setEditorState={setArticulationOption}
        hideSign={true}
        belowNoMargin={true}
        isCKEditor={true}
      />
      </>,
      isInvalid: formik.errors.articultion_option && formik.touched.articultion_option
    },
    {
      name: "Career Opportunities",
      id: "five",
      component: <HtmlInputEditor
        editorState={careerOpportunity}
        setEditorState={setCareerOpportunity}
        hideSign={true}
        belowNoMargin={true}
        isCKEditor={true}
      />,
      isInvalid: false
    },
    {
      name: "Study Kit",
      id: "six",
      component: <HtmlInputEditor
        editorState={studyKit}
        setEditorState={setStudyKit}
        hideSign={true}
        belowNoMargin={true}
        isCKEditor={true}
      />,
      isInvalid: false
    },
    {
      name: "Hardware Requirements",
      id: "seven",
      component: 
      <>
      <div className="row">
        <div className="col-auto">
          <div className="form-group-blk mb-3">
            <label htmlFor="early_bird_discount">Laptop Options</label>
            <div className="d-flex">
              <div className="custom-control custom-checkbox text-left">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="core_spec"
                  name="core_spec"
                  checked={formik.values.core_spec == 1}
                  onChange={()=>{
                    if(formik.values.core_spec == 1){
                      formik.setFieldValue("core_spec", 0);
                    }else{
                      formik.setFieldValue("core_spec", 1);
                    }
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="core_spec"
                >
                  Core Spec
                </label>
              </div>
              <div className="custom-control custom-checkbox text-left ml-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="med_spec"
                  name="med_spec"
                  checked={formik.values.med_spec == 1}
                  onChange={()=>{
                    if(formik.values.med_spec == 1){
                      formik.setFieldValue("med_spec", 0);
                    }else{
                      formik.setFieldValue("med_spec", 1);
                    }
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="med_spec"
                >
                  Medium Spec
                </label>
              </div>
              <div className="custom-control custom-checkbox text-left ml-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="high_spec"
                  name="high_spec"
                  checked={formik.values.high_spec == 1}
                  onChange={()=>{
                    if(formik.values.high_spec == 1){
                      formik.setFieldValue("high_spec", 0);
                    }else{
                      formik.setFieldValue("high_spec", 1);
                    }
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="high_spec"
                >
                  High Spec
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HtmlInputEditor
        editorState={hardwareReq}
        setEditorState={setHardwareReq}
        hideSign={true}
        belowNoMargin={true}
        isCKEditor={true}
      />
      </>,
      isInvalid: false
    },
    {
      name: "Pricing",
      id: "eight",
      component: 
      <>
        <div className="row">
          <div className="col-md-8 col-lg-3">
          <div className="form-group-blk mb-3">
              <label htmlFor="full_time_hybrid_fee">Full Time, Hybrid Fee {formik.values.Delivery_Methods.includes("full") && "*"}</label>
              <div><span className="pricing-before-text">R</span>
              <input
                type="number"
                className={
                  "input-price form-control" +
                  (formik.errors.full_time_hybrid_fee && formik.touched.full_time_hybrid_fee
                    ? " is-invalid"
                    : "")
                }
                placeholder="Enter Full Time, Hybrid Fee"
                name="full_time_hybrid_fee"
                id="full_time_hybrid_fee"
                title="Full Time, Hybrid Fee"
                value={formik.values.full_time_hybrid_fee}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              </div>
            </div>
          </div>
          <div className="col-md-8 col-lg-3">
          <div className="form-group-blk mb-3">
              <label htmlFor="full_time_online_fee">Full Time, Online Fee {formik.values.Delivery_Methods.includes("fulltimeonline") && "*"}</label>
              <div><span className="pricing-before-text">R</span>
              <input
                type="number"
                className={
                  "input-price form-control" +
                  (formik.errors.full_time_online_fee && formik.touched.full_time_online_fee
                    ? " is-invalid"
                    : "")
                }
                placeholder="Enter Full Time, Online Fee"
                name="full_time_online_fee"
                id="full_time_online_fee"
                title="Full Time, Online Fee"
                value={formik.values.full_time_online_fee}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              </div>
            </div>
          </div>
          <div className="col-md-8 col-lg-3">
          <div className="form-group-blk mb-3">
              <label htmlFor="part_time_online_fee">Part Time, Online Fee {formik.values.Delivery_Methods.includes("parttimeonline") && "*"}</label>
              <div><span className="pricing-before-text">R</span>
              <input
                type="number"
                className={
                  "input-price form-control" +
                  (formik.errors.part_time_online_fee && formik.touched.part_time_online_fee
                    ? " is-invalid"
                    : "")
                }
                placeholder="Enter Part Time, Online Fee"
                name="part_time_online_fee"
                id="part_time_online_fee"
                title="Part Time, Online Fee"
                value={formik.values.part_time_online_fee}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              </div>
            </div>
          </div>
          <div className="col-md-8 col-lg-3">
          <div className="form-group-blk mb-3">
              <label htmlFor="self_paced_fee">Self Paced Fee {formik.values.Delivery_Methods.includes("selfplaced") && "*"}</label>
              <div><span className="pricing-before-text">R</span>
              <input
                type="number"
                className={
                  "input-price form-control" +
                  (formik.errors.self_paced_fee && formik.touched.self_paced_fee
                    ? " is-invalid"
                    : "")
                }
                placeholder="Enter Self Paced Fee"
                name="self_paced_fee"
                id="self_paced_fee"
                title="Self Paced Fee"
                value={formik.values.self_paced_fee}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              </div>
            </div>
          </div>
          <div className="col-md-8 col-lg-3">
          <div className="form-group-blk mb-3">
              <label htmlFor="registration_fee">Registration Fee </label>
              <div><span className="pricing-before-text">R</span>
              <input
                type="number"
                className={
                  "input-price form-control" +
                  (formik.errors.registration_fee && formik.touched.registration_fee
                    ? " is-invalid"
                    : "")
                }
                placeholder="Enter Registration Fee"
                name="registration_fee"
                id="registration_fee"
                title="Registration Fee"
                value={formik.values.registration_fee}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group-blk mb-3">
              <label htmlFor="cost_terms">Cost Terms</label>
              <input
                className={
                  "form-control" +
                  (formik.errors.cost_terms && formik.touched.cost_terms
                    ? " is-invalid"
                    : "")
                }
                placeholder="Enter Cost Terms"
                name="cost_terms"
                id="cost_terms"
                title="Cost Terms"
                value={formik.values.cost_terms}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-12">
              <div className="row">
                <div className="col-auto">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="installments">Instalments</label>
                    <div className="d-flex">
                      {["2M", "3M", "6M", "10M", "12M", "18M", "24M"].map((option) => (
                        <div className="custom-control custom-checkbox text-left ml-2" key={option}>
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={option}
                            name={option}
                            checked={formik.values.installments.split(",").includes(option)}
                            onChange={() => handleChangeInstallments(option)}
                          />
                          <label className="custom-control-label label-instalments" htmlFor={option}>
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="early_bird_discount">Early Bird Discount</label>
                    <div className="d-flex">
                      <div className="custom-control custom-checkbox text-left">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="5_percent"
                          name="5_percent"
                          checked={formik.values.earlyBirds == 1}
                          // onChange={() => formik.setFieldValue("earlyBirds", "1")}
                          onChange={() => {
                            if (formik.values.earlyBirds == 1) {
                              formik.setFieldValue("earlyBirds", "2");
                            } else {
                              formik.setFieldValue("earlyBirds", "1");
                            }
                          }}
                        />
                        <label className="custom-control-label label-instalments" htmlFor="5_percent">
                          5%
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox text-left">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="10_percent"
                          name="10_percent"
                          checked={formik.values.earlyBirds == 0}
                          onChange={() => {
                            if (formik.values.earlyBirds == 0) {
                              formik.setFieldValue("earlyBirds", "2");
                            } else {
                              formik.setFieldValue("earlyBirds", "0");
                            }
                          }}
                          // onChange={() => formik.setFieldValue("earlyBirds", 0)}
                        />
                        <label className="custom-control-label label-instalments" htmlFor="10_percent">
                          10%
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>           
          </div>
        </div>        
      </>,
      isInvalid: false
    },
    {
      name: "Duration",
      id: "nine",
      component: <HtmlInputEditor
        editorState={duration}
        setEditorState={setDuration}
        hideSign={true}
        belowNoMargin={true}
        isCKEditor={true}
      />,
      isInvalid: false
    },
    // {
    //   name: "Outcomes",
    //   id: "ten",
    //   component: <HtmlInputEditor
    //     editorState={regulatoryInfo}
    //     setEditorState={setRegulatoryInfo}
    //     hideSign={true}
    //     belowNoMargin={true}
    //     isCKEditor={true}
    //   />,
    //   isInvalid: false
    // },
    {
      name: "Important Notes",
      id: "eleven",
      component: <HtmlInputEditor
        editorState={impNotes}
        setEditorState={setImpNotes}
        hideSign={true}
        belowNoMargin={true}
        isCKEditor={true}
      />,
      isInvalid: false
    }
  ]

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let background = RenderPMCStatus(data.value).bgColor;
      let color = RenderPMCStatus(data.value).colorCode;

      return {
        ...styles,
        backgroundColor: background,
        color: color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        // ':active': {
        //   ...styles[':active'],
        //   backgroundColor: !isDisabled
        //     ? isSelected
        //       ? data.color
        //       : color.alpha(0.3).css()
        //     : undefined,
        // },
      };
    },
  };

  // console.log("formik" , formik.values); 

  return (
    <>
      <div className="card card-profile-info-card filter-table-bg">        
        <div className="new-card-header">
          <div className="card-header pt-0">
            Course Information
          </div>
          <div className="card-body-inr card-body-info">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="name">Course Name *</label>
                    <input
                      type="text"
                      className={
                        "form-control" +
                        (formik.errors["name"] &&
                          formik.touched["name"]
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter Course Name"
                      name="name"
                      id="name"
                      title="Course Name"
                      value={formik.values["name"]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>          
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="code">Code</label>
                    <input
                      type="text"
                      className={
                        "form-control" +
                        (formik.errors.Code && formik.touched.Code
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter code"
                      name="Code"
                      id="Code"
                      title="Code"
                      value={formik.values.Code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>      
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3" title="School">
                    <label htmlFor="school">School *</label>
                    <Select
                      className={"form-control custom-select-box " + (formik.errors.School && formik.touched.School ? " is-invalid" : "")}
                      name="School"
                      value={schoolList.filter((item) => {
                        return item.value == formik.values.School;
                      })}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("School", value.value);
                        } else {
                          formik.setFieldValue("School", "");
                        }
                      }}
                      onBlur={formik.handleBlur}
                      options={schoolList}
                      maxMenuHeight={175}
                      placeholder={"Select School"}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3" title="Learning Methods">
                    <label>Learning Methods *</label>
                    <Select
                      className={
                        "form-control custom-select-box is-multi" +
                        (formik.errors.Delivery_Methods &&
                          formik.touched.Delivery_Methods
                          ? " is-invalid"
                          : "")
                      }
                      name="Delivery_Methods"
                      // value={dropDownValues.study_type_2.filter((obj) => {
                      //   return formik.values.Delivery_Methods.includes(obj.value);
                      // })}
                      value={dropDownValues?.study_type?.filter((data) => formik.values.Delivery_Methods.includes(data.value))}
                      // onChange={(value) => {
                      //   if (value) {
                      //     let arr = [];
                      //     value.map((item) =>{
                      //       arr.push(item.value);
                      //     })
                      //     formik.setFieldValue("Delivery_Methods", arr)
                      //   } else {
                      //     formik.setFieldValue("Delivery_Methods", [])
                      //   }
                      // }}
                      onChange={(value) => formik.setFieldValue("Delivery_Methods", value ? value.map((v) => v.value) : [])}
                      onBlur={formik.handleBlur}
                      options={dropDownValues.study_type}
                      maxMenuHeight={175}
                      isMulti={true}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3" title="Level">
                    <label>Level *</label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.level &&
                          formik.touched.level
                          ? " is-invalid"
                          : "")
                      }
                      name="level"
                      value={levelList.filter((obj) => {
                        return obj.value == formik.values.level
                      })}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("level", value.value)
                        } else {
                          formik.setFieldValue("level", "")
                        }
                      }}
                      onBlur={formik.handleBlur}
                      options={levelList}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.level
                          ? formik.values.level
                          : "Select Level  "
                      }
                      isClearable
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3" title="Credits">
                    <label>Credit Type</label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.credit_status &&
                          formik.touched.credit_status
                          ? " is-invalid"
                          : "")
                      }
                      name="credit_status"
                      value={creditStatusList && creditStatusList.filter(val => val?.value == formik.values.credit_status)}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("credit_status", value.value)
                        } else {
                          formik.setFieldValue("credit_status", "")
                        }
                      }}
                      onBlur={formik.handleBlur}
                      options={creditStatusList}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.credit_status
                          ? formik.values.credit_status
                          : "Select Credits  "
                      }
                      isClearable
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="credit">Credits<i className="fal fa-info-circle grade-icon"></i></label>
                    <input
                      type="number"
                      className={
                        "form-control" +
                        (formik.errors.Credits && formik.touched.Credits
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter Credits"
                      name="Credits"
                      id="Credits"
                      title="Credits"
                      value={formik.values.Credits}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>                                               
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="SAQA_Link">SAQA Link <i className="fal fa-info-circle grade-icon"></i></label>
                    <input
                      type="text"
                      className={
                        "form-control" +
                        (formik.errors["SAQA_Link"] &&
                          formik.touched["SAQA_Link"]
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter SAQA Link"
                      name="SAQA_Link"
                      id="SAQA_Link"
                      title="SAQA Link"
                      value={formik.values["SAQA_Link"]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>                
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="Duration">Duration (Weeks)</label>
                    <input
                      type="number"
                      className={
                        "form-control" +
                        (formik.errors.Duration && formik.touched.Duration
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter Duration (Weeks)"
                      name="Duration"
                      id="Duration"
                      title="Duration (Weeks)"
                      value={formik.values.Duration}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>                                
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3" title="Campus Location">
                    <label>Campus Location * <i className="fal fa-info-circle grade-icon"></i></label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.campus_location &&
                          formik.touched.campus_location
                          ? " is-invalid"
                          : "")
                      }
                      name="campus_location"
                      value={campusLocList.filter(val => val?.value === formik.values.campus_location)}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("campus_location", value.value)
                        } else {
                          formik.setFieldValue("campus_location", "")
                        }
                      }}
                      onBlur={formik.handleBlur}
                      options={campusLocList}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.campus_location
                          ? formik.values.campus_location
                          : "Select Campus Location  "
                      }
                    />
                  </div>
                </div>
                <div className="col-auto">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="course_delivery">Course Delivery</label>
                    <div className="d-flex">
                      <div className="custom-control custom-checkbox text-left">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="scheduled"
                          name="scheduled"
                          checked={formik.values.course_delivery.includes("scheduled")}
                          onChange={() => {
                            const prevVal = [...formik.values.course_delivery];
                            if (prevVal.includes("scheduled")) {
                              // Remove the item
                              const newValue = prevVal.filter(item => item !== "scheduled");
                              formik.setFieldValue("course_delivery", newValue);
                            } else {
                              // Add the item
                              prevVal.push("scheduled");
                              formik.setFieldValue("course_delivery", prevVal);
                            }
                          }}
                        />
                        <label className="custom-control-label" htmlFor="scheduled">
                          Instructor-Led Classes (Scheduled)
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox text-left ml-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="mentored"
                          name="mentored"
                          checked={formik.values.course_delivery.includes("mentored")}
                          onChange={() => {
                            const prevVal = [...formik.values.course_delivery];
                            if (prevVal.includes("mentored")) {
                              // Remove the item
                              const newValue = prevVal.filter(item => item !== "mentored");
                              formik.setFieldValue("course_delivery", newValue);
                            } else {
                              // Add the item
                              prevVal.push("mentored");
                              formik.setFieldValue("course_delivery", prevVal);
                            }
                          }}
                        />
                        <label className="custom-control-label" htmlFor="mentored">
                          Mentored
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox text-left ml-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="selfpaced"
                          name="selfpaced"
                          checked={formik.values.course_delivery.includes("selfpaced")}
                          onChange={() => {
                            const prevVal = [...formik.values.course_delivery];
                            if (prevVal.includes("selfpaced")) {
                              // Remove the item
                              const newValue = prevVal.filter(item => item !== "selfpaced");
                              formik.setFieldValue("course_delivery", newValue);
                            } else {
                              // Add the item
                              prevVal.push("selfpaced");
                              formik.setFieldValue("course_delivery", prevVal);
                            }
                          }}
                        />
                        <label className="custom-control-label" htmlFor="selfpaced">
                          Self-Paced
                        </label>
                      </div>
                    </div>
                  </div>
                </div>                                               
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="card-header pt-0">
                    <i className="fal fa-cog" style={{color:"var(--topbar-color)"}}></i> Course Settings
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3" title="Netsuite Product">
                    <label>Netsuite Product * <i className="fal fa-info-circle grade-icon"></i></label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.netsuite_product &&
                          formik.touched.netsuite_product
                          ? " is-invalid"
                          : "")
                      }
                      name="netsuite_product"
                      // value={netsuiteProdList.filter(function (option) {
                      //   return formik.values.netsuite_product == option.value ? option.label : " " 
                      // })}
                      value={netsuiteProdList.filter(val => val?.value == formik.values.netsuite_product)}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("netsuite_product", value.value);
                          formik.setFieldValue("netsuite_department_id", value.departmentId);
                        } else {
                          formik.setFieldValue("netsuite_product", "");
                          formik.setFieldValue("netsuite_department_id", "");
                        }
                      }}
                      onBlur={formik.handleBlur}
                      options={netsuiteProdList}
                      maxMenuHeight={175}
                      placeholder={formik.values.netsuite_product ? formik.values.netsuite_product :"Select Netsuite Product"}
                      isClearable
                    />
                  </div>                  
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3" title="Assigned Brand Templates">
                      <label>Assigned Brand Templates * <i className="fal fa-info-circle grade-icon"></i></label>
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.brandID &&
                            formik.touched.brandID
                            ? " is-invalid"
                            : "")
                        }
                        name="brandID"
                        // value={brandList.filter(function (option) {
                        //   return formik.values.brandID == option.value ? option.label : " " 
                        // })}
                        value={brandList.filter(val => val?.value === formik.values.brandID)}
                        onChange={(value) => {
                          if (value) {
                            formik.setFieldValue("brandID", value.value)
                          } else {
                            formik.setFieldValue("brandID", "")
                          }
                        }}
                        onBlur={formik.handleBlur}
                        options={brandList}
                        maxMenuHeight={175}
                        placeholder={formik.values.brandID ? formik.values.brandID :"Select Assigned Brands"}
                        isClearable
                      />
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3" title="Status">
                    <label>Status * <i className="fal fa-info-circle grade-icon"></i></label>
                    <Select
                      // styles={colourStyles}
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.status &&
                          formik.touched.status
                          ? " is-invalid"
                          : "")
                      }
                      name="status"
                      value={statusArr.filter(val => val?.value === formik.values.status)}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("status", value.value)
                        } else {
                          formik.setFieldValue("status", "")
                        }
                      }}
                      // menuIsOpen={true}
                      onBlur={formik.handleBlur}
                      options={statusArr}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.status
                          ? formik.values.status
                          : "Select Status  "
                      }
                      isClearable
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3" title="Popup">
                    <label>Popup <i className="fal fa-info-circle grade-icon"></i></label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.popupID &&
                          formik.touched.popupID
                          ? " is-invalid"
                          : "")
                      }
                      name="popupID"
                      // value={popupList.filter(function (option) {
                      //   return formik.values.popupID == option.value ? option.label : " " 
                      // })}
                      value={popupList.filter(val => val?.value === formik.values.popupID)}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("popupID", value.value)
                        } else {
                          formik.setFieldValue("popupID", "")
                        }
                      }}
                      onBlur={formik.handleBlur}
                      options={popupList}
                      maxMenuHeight={175}
                      placeholder={formik.values.popupID ? formik.values.popupID : "Select Popup"}
                      isClearable
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3" title="Categories">
                    <label>Categories <i className="fal fa-info-circle grade-icon"></i></label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.categories &&
                          formik.touched.categories
                          ? " is-invalid"
                          : "")
                      }
                      name="categories"
                      // value={categoryList.filter(function (option) {
                      //   return formik.values.categories == option.value ? option.value : " " 
                      // })}
                      value={categoryList.filter(val => val?.value === formik.values.categories)}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("categories", value.value)
                        } else {
                          formik.setFieldValue("categories", "")
                        }
                      }}
                      // menuIsOpen={true}
                      onBlur={formik.handleBlur}
                      options={categoryList}
                      maxMenuHeight={175}
                      placeholder={ formik.values.categories ? formik.values.categories : "Select Category"}
                      isClearable
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3" title="Banner Image">
                    <label>Banner Image * <i className="fal fa-info-circle grade-icon"></i></label>
                    <div className="form-group atttach-file m-0">
                      <label
                        className={
                          formik.errors.banner_image &&
                          formik.touched.banner_image
                            ? "file-req is-invalid"
                            : ""
                        }
                      >
                        <i className="fal fa-upload"></i>
                        <span>Upload</span>
                        <input
                          type="file"
                          title="Upload"
                          accept="image/*"
                          className="form-control  form-control-aatch-file"
                          onChange={(e) => {
                            if (e.target.files.length) {
                              formik.setFieldValue("banner_image",e.target.files[0]);
                              formik.setFieldValue("banner_image_url",URL.createObjectURL(e.target.files[0]));                              
                            }
                            e.target.value=""
                          }}
                        />
                      </label>
                    </div>
                    {(formik.values.banner_image && formik.values.banner_image_url) && (
                      <>
                        <div className="frm-group">
                          <ul className="list-unstyled attached-file-ul m-0">
                            <li>
                              <a
                                title={formik.values.banner_image_url}
                                href={
                                  formik.values.banner_image_url.includes("blob")
                                    ? formik.values.banner_image_url
                                    : `${downloadURL}/${IMAGE_URL}/${formik.values.banner_image_url}`
                                        .replaceAll("/public/", "")
                                        .replaceAll(
                                          "home/myaie/public_html/",
                                          ""
                                        )
                                }
                                target="_blank"
                              >
                                <i className="fal fa-file"></i>
                                &nbsp;
                                {formik.values.banner_image?.name
                                  ? TrimText(formik.values.banner_image?.name, 40)
                                  : TrimText(
                                      formik.values.banner_image_url.split(
                                        "/"
                                      ).reverse()[0],
                                      40
                                    )}
                              </a>
                              <button
                                className="btn btn-danger rounded-circle btn-dropdown-item"
                                title="Delete"
                                onClick={() => {
                                  formik.setFieldValue("banner_image", "");
                                  formik.setFieldValue("banner_image_url", "");
                                }}
                              >
                                <i className="fal fa-trash-alt"></i>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/* <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="career_video">Career Video URL <i className="fal fa-info-circle grade-icon"></i></label>

                    <input
                      type="text"
                      className={
                        "form-control" +
                        (formik.errors.career_video && formik.touched.career_video
                          ? " is-invalid"
                          : "")
                      }
                      name="career_video"
                      id="career_video"
                      title="career_video"
                      value={formik.values.career_video}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>    */}
                <div className="col-md-6 col-lg-3 d-flex align-items-center">
                  <div className="d-flex" title=" Automatically Generate Factsheet">
                    <label className="mb-0">
                    Automatically Generate Factsheet{" "}
                      <i className="fal fa-info-circle grade-icon"></i>
                    </label>
                    <div className="toggle-switch ml-2">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={formik.values.auto_generate_fectsheet === true}
                          onChange={(e)=>{
                            formik.setFieldValue("auto_generate_fectsheet", e.target.checked);
                          }}
                        />
                        <span class="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-center">
                  <div className="d-flex" title="Override Default Terms & Conditions">
                    <label className="mb-0">
                    Override Default Terms & Conditions{" "}
                      <i className="fal fa-info-circle grade-icon"></i>
                    </label>
                    <div className="toggle-switch ml-2">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={formik.values.override_default_tc === true}
                          onChange={(e)=>{
                            formik.setFieldValue("override_default_tc", e.target.checked);
                          }}
                        />
                        <span class="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                {formik.values?.override_default_tc ? (<div className="col-md-6 col-lg-3">
                  <div className="d-flex align-items-start flex-column">
                    <label>Assigned Terms & Conditions Page {formik.values.override_default_tc && "*"} <i className="fal fa-info-circle grade-icon"></i></label>
                    <button className={"btn btn-save btn-success" + (formik.errors.terms_conditions_page && formik.touched.terms_conditions_page ? " file-req is-invalid" : "")} type="button" title="Assign Page" data-toggle="modal" data-target="#assignPagePopup">
                        <i className="fal fa-plus"></i> Assign Page
                    </button>
                    {formik.values.terms_conditions_page ? (
                      allpageListData
                        ?.filter((page) => page.PageID === formik.values.terms_conditions_page)
                        .map((assignedPage) => (
                          <div className="frm-group w-100" key={assignedPage.PageID}>
                            <ul className="list-unstyled attached-file-ul m-0">
                              <li>
                                <a href={`/website_management/pages/staticpages/table/update/${assignedPage.PageID}`} target="_blank">
                                <span className="textLimit100 as-text-blue w-100" title={assignedPage.PageTitle}>
                                  {TrimText(assignedPage.PageTitle, 30)}
                                </span>
                                <button
                                  className="btn btn-danger rounded-circle btn-dropdown-item"
                                  title="Delete"
                                  onClick={() => {
                                    formik.setFieldValue("terms_conditions_page", "");
                                  }}
                                >
                                  <i className="fal fa-trash-alt"></i>
                                </button>
                                </a>
                              </li>
                            </ul>
                          </div>
                        ))
                    ):null}
                  </div>
                </div>
                ):null}
              </div>
              <div
                className="admin-home-acc accordion ac_over_none"
                id="accordionExample"
              >
                {editorArr.map((item, index) => {
                  return (
                    <div className="card-main" key={index}>
                      <div className="card-top">
                        <div className="card-header1" id={`heading${item.id}`}>
                          <h2 className="mb-0">
                            <button
                              className="btn-link collapsed"
                              type="button" data-toggle="collapse" data-target={`#collapse${item.id}`} aria-expanded="false" aria-controls={`collapse${item.id}`}
                            >

                              <div className={`title-border-box ${item.isInvalid ? "editor-block-invalid" : ""} `}>{item.name}</div>


                              <div className="header-status">
                                <div className="status-gol card-blue-1">
                                  <i className="fal fa-chevron-down rotate-icon"></i>
                                </div>
                              </div>

                            </button>
                          </h2>

                        </div>
                        <div
                          id={`collapse${item.id}`} className="card-body1 mt-2 collapse" aria-labelledby={`heading${item.id}`} data-parent="#accordionExample"
                        >
                          <div className="card-body inner-tab-card">
                            {(editorToShow || type == "add") && item.component}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <hr />
              {/* <div className="row">
                <div className="col-12">
                  <div className="card-header pt-0">
                    <i className="fal fa-cog" style={{color:"var(--topbar-color)"}}></i> SEO Settings
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="conversion_code">Conversion Code</label>
                    <input
                      className={
                        "form-control" +
                        (formik.errors.conversion_code && formik.touched.conversion_code
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter Conversion Code"
                      name="conversion_code"
                      id="conversion_code"
                      title="Conversion Code"
                      value={formik.values.conversion_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>                
                <div className="col-md-6 col-lg-6">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="meta_title">Meta Title</label>
                    <input
                      className={
                        "form-control" +
                        (formik.errors.meta_title && formik.touched.meta_title
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter Meta Title"
                      name="meta_title"
                      id="meta_title"
                      title="Meta Title"
                      value={formik.values.meta_title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="meta_description">Meta Description</label>
                    <input
                      className={
                        "form-control" +
                        (formik.errors.meta_description && formik.touched.meta_description
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter Meta Description"
                      name="meta_description"
                      id="meta_description"
                      title="Meta Description"
                      value={formik.values.meta_description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="meta_keywords">Meta Keywords</label>
                    <input
                      className={
                        "form-control" +
                        (formik.errors.meta_keywords && formik.touched.meta_keywords
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter Meta Keywords"
                      name="meta_keywords"
                      id="credit"
                      title="Meta Keywords"
                      value={formik.values.meta_keywords}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div> */}
              <div className="form-group form-group-save-cancel mt-4">
                <button
                  className="btn btn-save btn-success"
                  type="submit"
                  title="Save"
                  disabled={disabled || (type == "open" && !formik.dirty)}
                  onClick={formik.handleSubmit}
                >
                  {disabled ? (
                    <i className="fas fa-cog fa-spin"></i>
                  ) : (
                    <i className="fal fa-save"></i>
                  )}
                  Save
                </button>

                <button
                  className="btn btn-close btn-danger"
                  type="button"
                  title="Cancel"
                  onClick={() => handlereset()}
                >
                  <i className="fal fa-times"></i>Cancel
                </button>
              </div>
              {/** map function to render all errors  */}
              {Object.keys(formik.values).map(key => {
                if (formik.touched[key] && formik.errors[key]) {
                  return (
                    <div className="invalid-feedback d-block" key={key}>
                      {formik.errors[key]}
                    </div>
                  )
                }
              })}
            </form>
          </div>
        </div>        
      </div>
      <div
        className="topic-add-modal modal able_modal01 fade"
        id="assignPagePopup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-plus"></i> Assign Page
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <DataTableComponent
                data={assignPageList}
                loading={assignPageLoading}
                state={assignPageTableState}
                setState={setAssignPageTableState}
                setSearch={setAssignPageSearch}
                totalRows={assignPageTotalRows}
                columns={assignPageColumn}
                exportFunction={exportAssignPageData}
                exportFileName={"Assign_Page"}
                isInsidePopUp={true} 
                filters={[
                  {
                    filterName: "Status",
                    optionArr: PageFilterData.status,
                    state: pageStatus,
                    setState: setPageStatus,
                    renderLabelFunction: RenderPMCPageStatus
                  },
                  
                  {
                    filterName: "Brand Templates",
                    optionArr: PageFilterData.BrandList,
                    state: pageBrand,
                    isOptionReversed:false,
                    stopOptionSorting:true,
                    setState: setPageBrand,
                    // renderLabelFunction: RenderPageBrands,
                  }
                ]}                
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="topic-add-modal modal able_modal01 fade"
        id="assignArticulationPopup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-plus"></i> Assign Articulation Options
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <DataTableComponent
                data={articulationOptionList}
                columns={articulationOptionColumn}
                loading={articulationOptionLoading}
                search={articulationOptionSearch}
                keyField="ref_id"
                setSearch={setArticulationOptionSearch}
                state={articulationOptionTableState}
                setState={setArticulationOptionTableState}
                totalRows={articulationOptionTotalRow}
                exportFunction={exportDataArticulationOption}
                exportFileName={"Articulation_Option"}                
                isInsidePopUp={true}
                filters={[
                  {
                    filterName: " Status",
                    optionArr: articulationOptionFilterData.status,
                    state: artStatus,
                    setState: setArtStatus,
                    renderLabelFunction : RenderPMCStatus
                  },
                  {
                      filterName: " Programme Type",
                      optionArr: articulationOptionFilterData.programmeType,
                      state: artProgType,
                      setState: setArtProgType,
                      // uniqueId: "assignStudyType",
                      renderLabelFunction: RenderProgrammeType,
                  },
                  {
                    filterName: " Type",
                    optionArr: articulationOptionFilterData.type,
                    state: artType,
                    setState: setArtType,
                    // uniqueId: "assignStudyType",
                    renderLabelFunction: RenderProgrammeOrCourseType,
                  }
                ]}                
                
              />              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
