import React, { useMemo, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Swal from "sweetalert2";
import $ from "jquery";
import Select from "react-select";
import {
  AssignedFeatureAndBenefit,
  CreateFeaturesBenefits,
  ListFeaturesBenefits,
  ListFeaturesBenefitsCancelToken,
  ListFeaturesBenefitsShortCourseCancelToken,
  UnlinkFeatureAndBenefit,
  UnlinkShortCourseFeatureAndBenefit,
  addEditBenefitAndFeature,
  shortCourseAssignedFeatureAndBenefit,
} from "../../../services/ProgramService";
import { useFormik } from "formik";
import * as Yup from "yup";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import { formatQuestion } from "../../../utils/FormatQuestion";
import { RenderFeatureAndBenefits } from "../../../utils/CommonGroupingItem";
import PermissionsGate from "../../../utils/permissionGate";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const ShortCourseFeaturesBenefits = (props) => {
  const history = useHistory();
  const { id, type } = useParams();

  const [search1, setSearch1] = useState("");
  const [search, setSearch] = useState("");
  const [featuresData, setFeaturesData] = useState({});
  const [topics, setTopics] = useState([]);
  const [loadtopics, setLoadTopics] = useState(true);
  const [dummy, setDummy] = useState();
  const [disabledTopic, setDisabledTopic] = useState(false);
  const [editorState1, setEditorState1] = useState("");
  const [showCkEditor, setShowCkEditor] = useState(false);
  const [availableFeatures, setavailableFeatures] = useState([]);
  const [loadingAvailableFeatures, setLoadingAvailableFeatures] =
    useState(false);
  const [modalIcon, setModalIcon] = useState(false);

  useEffect(() => {
    handleTableScroll();
  }, [loadingAvailableFeatures, loadtopics]);

  useEffect(() => {
    const cancelTokenSources = [];

    const ListOfAssignFeaturesShortCourse = async () => {
      setLoadTopics(true);

      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const payload = {
          available: 0,
          shortcourse_id: id,
        };

        const res = await ListFeaturesBenefitsShortCourseCancelToken(
          payload,
          source.token
        );
        if (res.status === 200) {
          setTopics(res.data.data);
          setLoadTopics(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoadTopics(false);
        }
      }
    };

    ListOfAssignFeaturesShortCourse();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [dummy, type]);

  const givenPermsisions = useSelector((state) => state.givenPermission);
  useEffect(() => {
    let response = hasPermission({
      scopes: ["pfbview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];

    const listOfShortCourseAvailableFeatures = async () => {
      setLoadingAvailableFeatures(true);

      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        const payload = {
          available: 1,
          shortcourse_id: id,
        };
        const res = await ListFeaturesBenefitsShortCourseCancelToken(
          payload,
          source.token
        );
        if (res.status === 200) {
          setavailableFeatures(res.data.data);
          setLoadingAvailableFeatures(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoadingAvailableFeatures(false);
        }
      }
    };

    listOfShortCourseAvailableFeatures();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, []);

  const FeatureType = [
    { value: "program", label: "Program" },
    { value: "institute", label: "Institute" },
    { value: "faculty", label: "Faculty" },
  ];

  const resetFilter1 = () => {
    setSearch1("");
  };

  const resetFilterAvailable = () => {
    setSearch("");
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = topics;
    if (search1.length) {
      let tempId = allData.filter((item) => {
        let includes = item.name
          .toString()
          .toLowerCase()
          .includes(search1.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempdata = [...tempId];
      let unique = [...new Set(tempdata)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (search1.length) {
      return updatedData;
    } else {
      return topics;
    }
  };

  const dataToRenderAvailable = () => {
    let updatedData = [];
    let allData = availableFeatures;
    if (search.length) {
      let tempId = allData.filter((item) => {
        let includes = item.name
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempdata = [...tempId];
      let unique = [...new Set(tempdata)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (search.length) {
      return updatedData;
    } else {
      return availableFeatures;
    }
  };

  const typeSort = (a, b) => {
    return a &&
      a.type.toString().toLowerCase() > b &&
      b.type.toString().toLowerCase()
      ? 1
      : -1;
  };

  const handleSearchFilter1 = (e) => {
    const value = e.target.value;
    setSearch1(value);
  };
  const handleSearchAvailableFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const exportData = (fileType, fileName) => {
    let data, header;
    data = dataToRender();
    header = ["Name", "Type"];
    data = data.map((row) => ({
      ...row,
      Name: row.name ? row.name : "-",
      Type: row.type ? RenderFeatureAndBenefits(row.type).text : "-",
    }));
    // }

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      let compatibleData;
      compatibleData = data.map((row) => {
        return [row["Name"], row["Type"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const exportDataAvailable = (fileType, fileName) => {
    let data, header;
    data = dataToRenderAvailable();
    header = ["Name", "Type"];
    data = data.map((row) => ({
      ...row,
      Name: row.name ? row.name : "-",
      Type: row.type ? RenderFeatureAndBenefits(row.type).text : "-",
    }));
    // }

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      let compatibleData;
      compatibleData = data.map((row) => {
        return [row["Name"], row["Type"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const addAvailableFeature = (row) => {
    let dataFeature = {
      shortcourse_id: id,
      feature_benefit_id: row.id,
    };
    shortCourseAssignedFeatureAndBenefit(dataFeature)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.massage || "Updated Successfully",
        });
        let changeData = availableFeatures;
        let newData = changeData.filter((data) => {
          return data.id != row.id;
        });
        setavailableFeatures([...newData]);
        setDummy(!dummy);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      wrap: true,
      cell: (row) =>
        row.name ? (
          <PermissionsGate
            scopes={["pfbedit"]}
            RenderError={() => (
              <p>
                {row?.name.trim() ? (
                  <span className="feature-name">
                    {" "}
                    <span className="textLimit100">{row.name}</span>{" "}
                  </span>
                ) : (
                  "-"
                )}
              </p>
            )}
          >
            <span
              title={row.name}
              onClick={() => {
                setShowCkEditor(true);
                // $("#feature-popup").modal("show");
                setFeaturesData(row);
                setModalIcon(true);
                formik.setFieldValue("type", row.type);
                if (row.feature_benefit) {
                  setEditorState1(row.feature_benefit);
                }
              }}
              className="as-text-blue curser feature-name"
            >
              <span title={row.name} className="textLimit100">
                {row.name}
              </span>
            </span>
          </PermissionsGate>
        ) : (
          "-"
        ),
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      sortFunction: typeSort,
      wrap: true,
      cell: (row) =>
        row.type ? (
          <span
            className={`as-${
              RenderFeatureAndBenefits(row.type).color
            }-bg as-widget`}
          >
            {" "}
            {RenderFeatureAndBenefits(row.type).text}{" "}
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate
              RenderError={() => (
                <button
                  className="btn btn-primary rounded-circle"
                  title="Open"
                  disabled
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
              scopes={["pfbedit"]}
            >
              <button
                title="Open"
                className="btn btn-primary rounded-circle"
                onClick={() => {
                  setShowCkEditor(true);
                  // $("#feature-popup").modal("show");
                  setFeaturesData(row);
                  setModalIcon(true);
                  formik.setFieldValue("type", row.type);
                  if (row.feature_benefit) {
                    setEditorState1(row.feature_benefit);
                  }
                }}
              >
                <i className="fal fa-folder-open"></i>
              </button>
            </PermissionsGate>
            <PermissionsGate
              scopes={["pfbdelete"]}
              RenderError={() => (
                <button
                  className="btn btn-danger rounded-circle"
                  title="Delete"
                  disabled
                >
                  <i className="fal fa-trash-alt"></i>
                </button>
              )}
            >
              <button
                title="Unlink"
                className="btn btn-danger rounded-circle"
                onClick={() => handleDeleteTopic(row.unlink_id)}
              >
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);
  const Availablecolumns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      wrap: true,
      cell: (row) =>
        row.name ? (
          <span
            title={row.name}
            className="as-text-blue curser feature-name"
            onClick={() => {
              Swal.fire({
                title: "Are you sure?",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Add it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  addAvailableFeature(row);
                }
              });
            }}
          >
            <span className="textLimit75">{row.name}</span>
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      sortFunction: typeSort,
      wrap: true,
      cell: (row) =>
        row.type ? (
          <span
            className={`as-${
              RenderFeatureAndBenefits(row.type).color
            }-bg as-widget`}
          >
            {" "}
            {RenderFeatureAndBenefits(row.type).text}{" "}
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <button
              className="btn btn-primary rounded-circle"
              title="Add"
              type="button"
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  icon: "question",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, Add it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    addAvailableFeature(row);
                  }
                });
              }}
            >
              <i className="fal fa-plus"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const handleDeleteTopic = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
          UnlinkShortCourseFeatureAndBenefit({ unlink_id: id })
            .then((res) => {
              Swal.fire(
                "Unlinked!",
                "Features and Benefits has been unlinked.",
                "success"
              );
              setDummy(!dummy);
            })
            .catch((error) => console.log(error));
        }
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      feature_benefit: "",
      type: featuresData?.type ? featuresData.type : "",
      name: featuresData?.name ? featuresData.name : "",
    },
    validationSchema: Yup.object({
      feature_benefit: Yup.string().test(
        "required",
        "Description is Required",
        (value) => {
          let feature = formatQuestion(editorState1);
          let isInvalid = feature == "-";
          return !isInvalid;
        }
      ),
      type: Yup.string().required("Type is required"),
      name: Yup.string()
        .required("Name is required")
        .max(255, "Name should not be greater than 255 Character"),
    }),
    onSubmit: (values) => {
      setDisabledTopic(true);
        !featuresData &&
        addEditBenefitAndFeature({
          ...values,
          shortcourse_id: id,
          feature_benefit: editorState1,
        })
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added Successfully",
            });
            setDisabledTopic(false);
            setLoadTopics(false);
            setDummy(!dummy);
            formik.resetForm();
            $("#feature-popup").modal("hide");
            setShowCkEditor(false);
          })
          .catch((err) => console.log(err));

        featuresData &&
        addEditBenefitAndFeature({
          ...values,
          id: featuresData?.id,
          shortcourse_id: id,
          feature_benefit: editorState1,
        })
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added Successfully",
            });
            setDisabledTopic(false);
            setLoadTopics(false);
            setDummy(!dummy);
            formik.resetForm();
            $("#feature-popup").modal("hide");
            setShowCkEditor(false);
          })
          .catch((err) => console.log(err));
    },
  });

  return (
    <div className="custom-table-div filter-search-icon card card-table-custom">
      <div className="filter-search-head">
        <div className="filter-search-head-left">
          <div className="system-administration-table table-responsive">
            <div className="table-responsive-div">
              <div
                id="assessment-table-main_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <div
                  id="assessment-table-main_filter"
                  className="dataTables_filter"
                >
                  <label>
                    <input
                      type="search"
                      className=""
                      placeholder="Search"
                      aria-controls="assessment-table-main"
                      onChange={handleSearchFilter1}
                      value={search1}
                    />
                  </label>
                  <div className="filter-button-group">
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-search-bar-blk">
              <div className="button-reset dropdown-comman">
                <button
                  className="btn btn-primary"
                  onClick={() => resetFilter1()}
                  title="Reset"
                >
                  <i className="fal fa-redo"></i>Reset
                </button>
              </div>
              <div className="files-export-group">
                <button
                  type="button"
                  className="btn btn-files"
                  onClick={() => {
                    exportData(
                      "xlsx",
                      "Features and Benefits",
                      "Features and Benefits"
                    );
                  }}
                  title="Export spreadsheet"
                >
                  <i className="fal fa-file-excel icon"></i>
                </button>

                <button
                  type="button"
                  className="btn btn-files"
                  onClick={() => {
                    exportData(
                      "csv",
                      "Features and Benefits",
                      "Features and Benefits"
                    );
                  }}
                  title="Export CSV"
                >
                  <i className="fal fa-file-csv icon"></i>
                </button>

                <button
                  type="button"
                  className="btn btn-files"
                  onClick={() => {
                    exportData(
                      "pdf",
                      "Features and Benefits",
                      "Features and Benefits"
                    );
                  }}
                  title="Export PDF"
                >
                  <i className="fal fa-file-pdf icon"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="filter-search-head-right">
          <div className="">
            <PermissionsGate
              RenderError={() => (
                <button
                  className="btn btn-primary mr-2"
                  title="Add Feature & Benefit"
                  disabled
                >
                  Add Feature & Benefit
                </button>
              )}
              scopes={["pfbadd"]}
            >
              <button
                className="btn btn-primary mr-2"
                // data-toggle="modal"
                // data-target="#feature-popup"
                title="Add Feature & Benefit"
                data-toggle="modal"
                data-target="#Select-Features"
              >
                <i className="fal fa-plus"></i>Add Feature & Benefit
              </button>
            </PermissionsGate>
            <PermissionsGate
              RenderError={() => (
                <button className="btn btn-primary" title="Create New" disabled>
                  Create New
                </button>
              )}
              scopes={["pfbadd"]}
            >
              <button
                className="btn btn-primary"
                // data-toggle="modal"
                // data-target="#feature-popup"
                title="Create New"
                onClick={() => {
                  setFeaturesData({});
                  setEditorState1("");
                  setShowCkEditor(true);
                  $("#feature-popup").modal("show");
                  setModalIcon(false);
                }}
              >
                <i className="fal fa-plus"></i>Create New
              </button>
            </PermissionsGate>
          </div>
        </div>
      </div>

      <DataTable
        data={dataToRender()}
        columns={columns}
        pagination={true}
        progressComponent={<SkeletonTicketList />}
        progressPending={loadtopics}
        noDataComponent={Str.noRecord}
        defaultSortAsc={true}
        defaultSortField={"name"}
        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
      />
      {showCkEditor ? (
        <div
          // ref={editorRef}
          className="topic-add-modal modal fade show able_modal01"
          style={{ display: "block" }}
          id="feature-popup"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document"
          >
            <div className="modal-content">
              <form onSubmit={formik.handleSubmit}>
                <div className="modal-body p-0">
                  <div className="modal-header modal-header-custom">
                    <h5 className="modal-title">
                      {modalIcon ? (
                        <>
                          <i className="fal fa-folder-open"></i> Edit
                        </>
                      ) : (
                        <>
                          <i className="fal fa-plus"></i> Add
                        </>
                      )}{" "}
                      Features &amp; Benefits
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        formik.resetForm();
                        $("#feature-popup").modal("hide");
                        setEditorState1("");
                        setShowCkEditor(false);

                        formik.setFieldValue("type", "");
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="t p-30px">
                    <div className="topic-add-modal-content">
                      <div className="row center-label">
                        <div className="col-md-12">
                          <div className="form-group-blk mb-3">
                            <label>Name *</label>
                            <input
                              type="text"
                              className={
                                "form-control" +
                                (formik.errors.name && formik.touched.name
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter Name"
                              name="name"
                              id="name"
                              title="Name"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group-blk mb-3">
                            <label>Description *</label>
                            <HtmlInputEditor
                              editorState={editorState1}
                              setEditorState={setEditorState1}
                              hideSign={true}
                              isCKEditor={true}
                              isInvalid={
                                formik.touched.feature_benefit &&
                                formik.errors.feature_benefit
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group-blk mb-3" title="Type">
                            <label htmlFor="type">Type *</label>
                            <Select
                              className={
                                "form-control custom-select-box" +
                                (formik.errors.type && formik.touched.type
                                  ? " is-invalid"
                                  : "")
                              }
                              name="type"
                              id="type"
                              value={FeatureType.filter((data) => {
                                return data.value == formik.values.type;
                              })}
                              onChange={(value) => {
                                if (value) {
                                  formik.setFieldValue("type", value.value);
                                } else {
                                  formik.setFieldValue("type", "");
                                }
                              }}
                              onBlur={formik.handleBlur}
                              options={FeatureType}
                              maxMenuHeight={175}
                              placeholder={
                                formik.values.type
                                  ? formik.values.type
                                  : "Select Type "
                              }
                              isClearable
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group form-group-save-cancel mt-3">
                      <button
                        className="btn btn-primary"
                        title="Save"
                        onClick={formik.handleSubmit}
                        disabled={disabledTopic}
                      >
                        {disabledTopic ? (
                          <i className="fas fa-cog fa-spin"></i>
                        ) : (
                          <i className="fal fa-save"></i>
                        )}{" "}
                        Save
                      </button>
                      <button
                        className="btn btn-close btn-danger"
                        type="button"
                        title="Cancel"
                        onClick={() => {
                          formik.resetForm();
                          $("#feature-popup").modal("hide");
                          setEditorState1("");
                          setShowCkEditor(false);

                          formik.setFieldValue("type", "");
                        }}
                      >
                        <i className="fal fa-times"></i>Cancel
                      </button>
                      {Object.keys(formik.values).map((key) => {
                        if (formik.touched[key] && formik.errors[key]) {
                          return (
                            <div className="invalid-feedback d-block">
                              {formik.errors[key]}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : null}
      <div
        className="topic-add-modal modal fade"
        id="Select-Features"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-plus"></i> Add Feature & Benefit
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
                <div className="custom-table-div filter-search-icon card-table-custom">
                  <div className="search-filter-div">
                    <div className="search-filter-div-left">
                      <div className="system-administration-table table-responsive">
                        <div className="table-responsive-div">
                          <div
                            id="assessment-table-main_wrapper"
                            className="dataTables_wrapper no-footer"
                          >
                            <div
                              id="assessment-table-main_filter"
                              className="dataTables_filter"
                            >
                              <label>
                                <input
                                  type="search"
                                  className=""
                                  placeholder="Search"
                                  aria-controls="assessment-table-main"
                                  onChange={handleSearchAvailableFilter}
                                  value={search}
                                />
                              </label>
                              <div className="filter-eff filter-data-btn">
                                <button className="filter-buttons">
                                  <i className="fal fa-filter"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="filter-button-group">
                          <div className="button-reset dropdown-comman">
                            <button
                              className="btn btn-primary"
                              onClick={() => resetFilterAvailable()}
                              title="Reset"
                            >
                              <i className="fal fa-redo"></i>Reset
                            </button>
                          </div>
                          <div className="files-export-group">
                            <button
                              type="button"
                              className="btn btn-files"
                              onClick={() => {
                                exportDataAvailable(
                                  "xlsx",
                                  "Features and Benefits",
                                  "Features and Benefits"
                                );
                              }}
                              title="Export spreadsheet"
                            >
                              <i className="fal fa-file-excel icon"></i>
                            </button>

                            <button
                              type="button"
                              className="btn btn-files"
                              onClick={() => {
                                exportDataAvailable(
                                  "csv",
                                  "Features and Benefits",
                                  "Features and Benefits"
                                );
                              }}
                              title="Export CSV"
                            >
                              <i className="fal fa-file-csv icon"></i>
                            </button>

                            <button
                              type="button"
                              className="btn btn-files"
                              onClick={() => {
                                exportDataAvailable(
                                  "pdf",
                                  "Features and Benefits",
                                  "Features and Benefits"
                                );
                              }}
                              title="Export PDF"
                            >
                              <i className="fal fa-file-pdf icon"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <DataTable
                    data={dataToRenderAvailable()}
                    columns={Availablecolumns}
                    pagination={true}
                    progressComponent={<SkeletonTicketList />}
                    progressPending={loadingAvailableFeatures}
                    noDataComponent={Str.noRecord}
                    defaultSortAsc={true}
                    defaultSortField={"name"}
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShortCourseFeaturesBenefits;
