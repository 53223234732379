import React, { useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component';
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import Str from "../../common/Str";
import { Link, useHistory } from 'react-router-dom';
import { TrimText } from '../../common/TrimText';
import { pmcStatus } from '../program/GroupItems';
import PermissionsGate from '../../../utils/permissionGate';
import hasPermission from '../../../utils/hasMultiplePermission';
import { useSelector } from 'react-redux';
import { CampusRender, RenderCampus, RenderCampusDropdown, RenderCourseType, RenderLearningMethod, RenderSchool } from '../../../utils/CommonGroupingItem';
import { GetCommonContentReReg } from '../../../services/ProgrammeServices';
import Tablefilter from '../../common/Tablefilter';
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE } from '../../../utils/Constants';
import { handleTableScroll } from '../../../utils/commonFunction';
import { GetProgramDetailCancelToken, LinkedModuleNetsuiteProductCourseList, LinkedModuleNetsuiteProductCourseListFilters, LinkedNetsuiteProductCourseList, LinkedNetsuiteProductCourseListFilters, UnlinkedIntakeToProgramme, UnlinkInatkeToModule } from '../../../services/ProgramService';
import axios from 'axios';
import moment from 'moment';
import { drop } from 'underscore';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { unlink } from 'fs';
import Swal from 'sweetalert2';
import LPTable from './LPTable';

function LinkedProgramme({data}) {
    const history = useHistory();
    const {id} = useParams()
    const [search, setSearch] = useState("");
    const [intake, setIntake] = useState({ arr: [], checkObj: {} });
    const [campus, setCampus] = useState({ arr: [], checkObj: {} });
    const [delivery, setDelivery] = useState({ arr: [], checkObj: {} });
    const [courseType, setCourseType] = useState({ arr: [], checkObj: {} });
    const [loading, setLoading] = useState();
    const [listData,setListData] = useState([])
    const [dropdown, setDropdown] = useState({intake : [],campus: [],deliveryMethod :[],courseType : []})
    const [school, setSchool] = useState({ arr: [], checkObj:{}})
    const [toggle,setToggle] = useState(false)
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [sortkey, setSortKey] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [detail, setDetails] = useState([])
    const [totalRows, setTotalRows] = useState(0)
    const [tableState, setTableState] = useState({
        page: 1,
        perPage: 10,
        sortKey: "start",
        sortOrder: "DESC"
    })
    

    useEffect(() => {
        handleTableScroll()
      }, [loading])


      const handlePageChange = (pageNo) => {
        setPage(pageNo);
      };
    
      const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
      };
    
      const handleSort = (column, sortDirection) => {
        setSortKey(column.sortField);
        setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
      };

    useEffect(() => {
        console.log("Loading",detail);
    },[detail])
    useEffect(
         () => {
            const getFilters =async () => {

                const response = await LinkedNetsuiteProductCourseListFilters(id,detail?.netsuite_product,1)
                if (response.status === 200) {
                    setDropdown({...dropdown, intake : response.data?.yearsAndIntaksFilters, campus: response.data?.campusFilter, deliveryMethod : response.data?.intakeTypeFilters, courseType : response.data?.subjectTypeFilter })
                }
            }
            getFilters()
        },[detail,toggle]
    )


    useEffect(
        () => {
            GetProgramDetailCancelToken({"PMC_ID" : id})
            .then((res) => {
                setDetails((res.data)[0])
            }).catch((err) => {console.log(err)})
        },[]
    )

      useEffect(() => {
        const cancelTokenSources = [];
        const getData = async () => {
            setLoading(true)
          cancelTokenSources.forEach((source) => {
            source.cancel('New request made');
          });
    
          const source = axios.CancelToken?.source();
          cancelTokenSources.push(source);
          try { 
            const data = {
                programme_id:id,
                netsuite_product_id:detail?.netsuite_product,
                isAssigned:1,
                page: page,
                limit: perPage,
                key: sortkey,
                sort: sortOrder,
                search: search,
                viaCampus : campus.arr.length ? campus.arr : [],
                intake : intake.arr.length ? intake.arr : [],
                viaLearnType : delivery.arr.length? delivery.arr : [],
                viaCourseType: courseType.arr.length? courseType.arr : [],
            }
                const response = await LinkedNetsuiteProductCourseList(data)
                if (response.status === 200) {
                    setListData(response.data.data.data);
                    setTotalRows(response.data?.data?.total)
                    setLoading(false)
                }
          } catch (error) {
            if (!axios.isCancel(error)) {
              console.log('error :', error);
              setLoading(false)
            }
          }
        };
        if(detail.length){
            getData();
        }
    
        return () => {
          cancelTokenSources.forEach((source) => {
            source.cancel('Component unmounted');
          });
        };
      }, [intake,delivery,courseType,search,sortOrder,sortkey,campus,detail,page,totalRows,toggle]);

    const givenPermsisions = useSelector((state) => state.givenPermission);

    useEffect(() => {
        let response = hasPermission({ scopes: ["matpview"], permissions: givenPermsisions });
        if (!response) {
            history.push("/noaccess");
        }
    }, [])

    // useEffect(() => {
    //     GetCommonContentReReg().then(res =>
    //         setSchoolArr(res.data.school_type)
    //     ).catch(err => console.error("err in common data api :", err))
    // }, [])

    const resetFilter = () => {
        setSearch("");
        setIntake({ arr: [], checkObj: {} });
        setCampus({ arr: [], checkObj: {} });
        setDelivery({ arr: [], checkObj: {} });
        setCourseType({ arr: [], checkObj: {} });
    };

    const handlerefresh = () => {
        setToggle(!toggle)
    }

    const exportData = (fileType, fileName) => {
        let data;
        const header = ["Name", "Intake","Campus","Delivery Method","Start Date","End Date","Course Type","Students"];
        Swal.fire({
          title: "File downloading",
          onOpen: function () {
            Swal.showLoading();
          },
        });
        const cData = {
            module_id:id,
            netsuite_product_id:detail?.netsuite_product,
            isAssigned:1,
            page: page,
            limit: perPage,
            key: sortkey,
            sort: sortOrder,
            search: search,
            viaCampus : campus.arr.length ? campus.arr : [],
            intake : intake.arr.length ? intake.arr : [],
            viaLearnType : delivery.arr.length? delivery.arr : [],
            viaCourseType: courseType.arr.length? courseType.arr : [],
            programme_id : id,
            exportStatus:"true"
        }
        LinkedNetsuiteProductCourseList(cData).then(res => {
          data = res.data?.data;
          data = data?.map((row, ind) => ({
            ...row,
            "Name": row.name ? row.name : "-",
            "Intake" : row.intakes ? row.intakes: "-",
            "Campus": row.campus != null && row.campus != "" ? RenderCampus(row.campus).text  : "-",
            "Delivery Method" : row.type ? RenderLearningMethod(row.type).text : "-",
            "Start Date" : row.start ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : "-",
            "End Date" : row.end ? moment.unix(row.end).format(TABLE_DATE_FORMAT): "-",
            "Course Type" : row.subject_type ? row.subject_type : "-",
            "Students": row.total_student != null && row.total_student != "" ? row.total_student : "-",
    
          }));
          if (fileType === "csv") {
            const csvString = Papa.unparse({ fields: header, data });
            // console.log(csvString);
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
    
            const blobURL = window.URL.createObjectURL(blob);
    
            // Create new tag for download file
            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
            anchor.click();
    
            // Remove URL.createObjectURL. The browser should not save the reference to the file.
            setTimeout(() => {
              // For Firefox it is necessary to delay revoking the ObjectURL
              URL.revokeObjectURL(blobURL);
            }, 1000);
            Swal.close();
          } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
              const obj = {};
              header.map((col, index) => {
                obj[col] = row[col];
              });
              return obj;
            });
    
            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
              header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);
            Swal.close();
            // Returning false as downloading of file is already taken care of
            return false;
          }
          if (fileType === "pdf") {
            const compatibleData = data.map((row) => {
              return [row["Name"], row["Intake"],row["Campus"],row["Delivery Method"],row["Start Date"],row["End Date"],row["Course Type"],row["Students"]];
            });
            const doc = new JsPDF();
            doc.autoTable({
              head: [header],
              body: compatibleData,
              styles: {
                minCellHeight: 10,
                color : "white",
                minCellWidth: 5,
                halign: "left",
                fontSize: 8,
              },
            });
            doc.save(`${fileName}.pdf`);
            Swal.close();
            return false;
          }
    
        }).catch(error => {
          console.log("error -----> ", error);
        })
    
    
    
    
      };

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setSearch(value);
    };

    const myNameFilter = (a, b) => {
        return a.SAQA_Name && a.SAQA_Name.toLowerCase() > b.SAQA_Name && b.SAQA_Name.toLowerCase() ? 1 : -1;
    };
    const myCodeFilter = (a, b) =>
        a.Code.toLowerCase() > b.Code.toLowerCase() ? 1 : -1;
    const myNQFFilter = (a, b) =>
        Number(a.NQF_Level) > Number(b.NQF_Level) ? 1 : -1;

    const columns = useMemo(() => [
        {
            name: "Name",
            selector: "name",
            sortField: "name",
            sortable: true,
            wrap: true,
            // sortFunction: myNameFilter,
            cell: (row) => (
                <PermissionsGate
                    scopes={["matpedit"]}
                    RenderError={() => <span className="feature-name"> <span className="textLimit100">{row?.name ?? "-"}</span></span>}
                >
                    <Link
                        to={`/courseAdministration/Programmes/programme/open/${row.id}/details`}
                        title={row?.name ?? "-"}
                        className="as-text-blue curser feature-name"
                    >
                        <span className="textLimit100">{row?.name ?? "-"}</span>
                    </Link>
                </PermissionsGate>
            ),
        },
        {
            name: "Intake",
            selector: "intakes",
            sortField: "intakes",
            // sortFunction: myCodeFilter,
            sortable: true,
            cell: (row, index) => (
                <div className="ticket-id">
                    <span title={row?.intakes ?? "-"} className="overflow-ellipsis2">
                       {row?.intakes ?? "-"}
                    </span>
                </div>
            ),
        },{
            name: "Campus",
            selector: "campus",
            sortField: "campus",
            // sortFunction: myCodeFilter,
            
            sortable: true,
            cell: (row, index) => (
                <div className="ticket-id">
                    <span title={row?.campus ?? "-"} className="overflow-ellipsis2">
                        {row?.campus ? RenderCampus(row.campus).html : ""}
                    </span>
                </div>
            ),
        },
        {
            name: "Delivery Method",
            selector: "type",
            sortField: "type",
            // sortFunction: myCodeFilter,
            sortable: true,
            cell: (row, index) => (
                <p className="learningMethodText">
                    {row && row.type ? RenderLearningMethod(row.type).html : "-"}
                </p>
            ),
        },
        {
            name: "Start Date",
            selector: "start",
            sortField: "start",
            // sortFunction: myCodeFilter,
            sortable: true,
            cell: (row, index) => (
                <div className="ticket-id">
                    <span title={row?.start ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : "-"} className="overflow-ellipsis2">
                        {row?.start ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : "-"}
                    </span>
                </div>
            ),
        },
        {
            name: "End Date",
            selector: "end",
            sortField: "end",
            // sortFunction: myCodeFilter,
            sortable: true,
            cell: (row, index) => (
                <div className="ticket-id">
                    <span title={row?.end ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : "-"} className="overflow-ellipsis2">
                        {row?.end ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : "-"}
                    </span>
                </div>
            ),
        },
        {
            name: "Course Type",
            selector: "subject_type",
            sortField: "subject_type",
            // sortFunction: myCodeFilter,
            sortable: true,
            cell: (row, index) => (row.subject_type ? row.subject_type : "-"),
        },
        {
            name: "Students",
            selector: "total_student",
            sortField: "total_student",
            // sortFunction: myCodeFilter,
            sortable: true,
            cell: (row, index) => (
                <div className="ticket-id">
                    <span title={row?.total_student != null ? row.total_student : "-"} className="overflow-ellipsis2">
                        {row?.total_student != null ? row.total_student : "-"}
                    </span>
                </div>
            ),
        },

        
        {
            name: "Actions",
            selector: "",
            cell: (row) => (
                <div className="assessment-08">
                    <div className="as-buttons">
                        <PermissionsGate
                            scopes={["matpedit"]}
                            RenderError={() => (
                                <button
                                    className="btn btn-primary rounded-circle"
                                    title="Open"
                                    disabled
                                >
                                    <i className="fal fa-folder-open"></i>
                                </button>
                            )}
                        >
                            <Link
                                to={`/courseAdministration/Programmes/programme/open/${row.id}/details`}
                                className="btn btn-primary rounded-circle"
                                title="Open"
                            >
                                <i className="fal fa-folder-open"></i>
                            </Link>
                        </PermissionsGate>
                        <PermissionsGate
                            scopes={["matpadd"]}
                            RenderError={() => (
                                <button
                                    className="btn btn-primary rounded-circle"
                                    title="Open"
                                    disabled
                                >
                                    <i className="fal fa-folder-open"></i>
                                </button>
                            )}
                        >
                            <button
                                onClick={() => unlink(row.id)}
                                className="btn btn-danger rounded-circle"
                                title="Unlink"
                            >
                                <i className="fal fa-unlink"></i>
                            </button>
                        </PermissionsGate>
                    </div>
                </div>
            ),
        },
    ]);

    const dataToRender = () => {
        let updatedData = [];
        let allData = data;
        if (search.length) {
            let tempCode = allData.filter((item) => {
                let includes =
                    item.Code &&
                    item.Code.toString().toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempName = allData.filter((item) => {
                let includes =
                    item.P_SAQA_Name &&
                    item.P_SAQA_Name.toString()
                        .toLowerCase()
                        .includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempLink = allData.filter((item) => {
                let includes =
                    item.P_SAQA_Link &&
                    item.P_SAQA_Link.toString()
                        .toLowerCase()
                        .includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempP_NQF_Level = allData.filter((item) => {
                let includes =
                    item.P_NQF_Level &&
                    item.P_NQF_Level.toString()
                        .toLowerCase()
                        .includes(search.toLowerCase());

                if (includes) {
                    return includes;
                } else return null;
            });
            let tempP_School = allData.filter((item) => {
                let includes =
                    item.P_School &&
                    item.P_School.toString().toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempP_Quality_Council = allData.filter((item) => {
                let includes =
                    item.P_Quality_Council &&
                    item.P_Quality_Council.toString()
                        .toLowerCase()
                        .includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });

            let tempdata = [
                ...tempCode,
                ...tempName,
                ...tempLink,
                ...tempP_NQF_Level,
                ...tempP_School,
                ...tempP_Quality_Council,
            ];
            let unique = [...new Set(tempdata)];

            updatedData = unique;
        } else {
            updatedData = allData;
        }

        if (school.arr.length) {
            let tempRole = updatedData;
            let tempSearch = tempRole.filter((item) => {
                const startsWith = school.arr.find(function (user, index) {
                    if (
                        user.toLowerCase() ==
                        (item.P_School ? item.P_School.toLowerCase() : "")
                    )
                        return item;
                });
                if (startsWith) {
                    return startsWith;
                } else return null;
            });

            updatedData = tempSearch;
        }

        if (search.length || school.arr.length) {
            return updatedData;
        } else {
            return data;
        }
    };
    const unlink = (programme_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, unlink it!",
          }).then((result) => {
              if(result.isConfirmed) {
                Swal.fire({
                    title: "Please Wait !",
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                      Swal.showLoading();
                    },
                  });
                const data = {
                    programme_id:programme_id,
                    netsuite_id:detail?.netsuite_product,
                    PMC_programme_id : id
                }
                UnlinkedIntakeToProgramme(data)
                .then(res => 
                {
                    Swal.fire({
                        icon: "success",
                        title: "Programme Unlinked Successfully",
                    });
                    setToggle(!toggle);
                }
                )
                .catch(err => console.log(err));
            }
          })
    };
    return (
        <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
            <div
                className="custom-table-div filter-search-icon card card-table-custom"
            >
                <div className="search-filter-div">
                    <div className="search-filter-div-left">
                        <div className="system-administration-table table-responsive">
                            <div className="table-responsive-div">
                                <div
                                    id="assessment-table-main_wrapper"
                                    className="dataTables_wrapper no-footer"
                                >
                                    <div
                                        id="assessment-table-main_filter"
                                        className="dataTables_filter"
                                    >
                                        <label>
                                            <input
                                                type="search"
                                                className=""
                                                placeholder="Search"
                                                aria-controls="assessment-table-main"
                                                onChange={handleSearchFilter}
                                                value={search}
                                            />
                                        </label>
                                        <div className="filter-eff filter-data-btn">
                                            <button className="filter-buttons">
                                                <i className="fal fa-filter"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-button-group">
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner  filter-custom-new`}>
                                        <Tablefilter
                                            filterName="Intake"
                                            optionArr={dropdown.intake}
                                            state={intake}
                                            setState={setIntake}
                                            isOptionReversed={true}
                                        />
                                        <Tablefilter
                                            filterName="Campus"
                                            optionArr={dropdown.campus}
                                            state={campus}
                                            setState={setCampus}
                                        />
                                        <Tablefilter
                                            filterName="Delivery Method"
                                            optionArr={dropdown.deliveryMethod}
                                            state={delivery}
                                            setState={setDelivery}
                                            renderLabelFunction={RenderLearningMethod}
                                        />
                                        <Tablefilter
                                            filterName="Course Type"
                                            optionArr={dropdown.courseType}
                                            state={courseType}
                                            setState={setCourseType}
                                        />
                                    </div>
                                </div>
                                <div className="reset-btn-group">
                                    <div className="button-reset dropdown-comman">
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => resetFilter()}
                                            title="Reset"
                                        >
                                            <i className="fal fa-redo"></i>Reset
                                        </button>
                                    </div>
                                    <div className="files-export-group">
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("xlsx", "PMC");
                                            }}
                                            title="Export spreadsheet"
                                        >
                                            <i className="fal fa-file-excel icon"></i>
                                        </button>

                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("csv", "PMC");
                                            }}
                                            title="Export CSV"
                                        >
                                            <i className="fal fa-file-csv icon"></i>
                                        </button>

                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("pdf", "PMC");
                                            }}
                                            title="Export PDF"
                                        >
                                            <i className="fal fa-file-pdf icon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                    <button className="btn btn-primary" title="Link Programme" data-toggle="modal" data-target="#assignQuestion">
                      {/* <button className="btn btn-primary" title="Link Programme" onClick={() => history.push(`/courses/content/assemblies/add`)}> */}
                      <i className="fal fa-link"></i>Link Programme
                    </button>
              </div>
            </div>
          </div>
                </div>
                <DataTable
                    data={listData}
                    columns={columns}
                    pagination={true}
                    onSort={handleSort}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    paginationServer={true}
                    progressComponent={<SkeletonTicketList />}
                    progressPending={loading}
                    noDataComponent={Str.noRecord}
                    defaultSortAsc={true}
                    defaultSortField={"name"}
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                />
            </div>
            <div className="topic-add-modal modal fade" id="assignQuestion" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered available-assessment-modal" role="document">

          <div className="modal-content">
            {/* <div className="modal-body"> */}
            <div class="modal-header modal-header-custom"><h5 class="modal-title"><i class="fal fa-link"></i> Link Programmes</h5><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>

            <LPTable handlerefresh={handlerefresh} toggle={toggle} setToggle={setToggle} detail={detail}/>
          </div>
        </div>
      </div>
        </div>
        
    )
}

export default LinkedProgramme