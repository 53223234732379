import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { RenderLearningMethod } from '../../../utils/CommonGroupingItem';
import DataTableComponentFrontPagination from '../../common/DataTableComponentFrontPagination';
import axios from 'axios';
import { RenderCourseAssignedlistStatus } from '../../../utils/CommonStatusItems';
import EmailAvailableProgrames from './EmailAvailableProgrames'
import { GetAllPmcPagesListCancelToken, GetAllPmcPagesListFilters, getEmailProgrammeShortCourseListFilters, getEmailProgrammesList, getEmailProgrammesListFilters, getEmailShortCourseList, getEmailShortCourseListFilters } from '../../../services/EmailsPageServer';
import SkeletonTicketList from '../../../loaders/SkeletonTicketList';
import EmailAvailableShortCourse from './EmailAvailableShortCourse';

function EmailAssignedProgrammes({ id , assignedRec , setAssignedRec , assignloading , setAssignLoading , type}) {
  const [availableLoading, setAvailableLoading] = useState(false);
  const [courseLoading, setCourseLoading] = useState(false);
  const [addedRec, setAddedRec] = useState([]);
  const [availableRec, setAvailableRec] = useState([]);
  const [availableCourseRec, setAvailableCourseRec] = useState([]);
  const [searchAssigned, setSearchAssigned] = useState("");
  const [assingIntake, setAssingIntake] = useState({ arr: [], checkObj: {} })
  const [assingStudyType, setAssingStudyType] = useState({ arr: [], checkObj: {} })
  const [linkedProgramme,setLinkedProgramme] = useState({ arr: [], checkObj: {} })
  const [filterData, setFilterData] = useState({ intake: [], learning: [],linked: []})
  const [AvailablefilterData, setAvailableFilterData] = useState({ intake: [], learning: [], linked:[] })
  const [AvailableCoursefilterData, setAvailableCourseFilterData] = useState({ intake: [], learning: [],linked:[] })

  useEffect(() => {
    console.log("OOOOOOOOOOOOOOOOOO",assignedRec);
  },[assignedRec])

  const uniquekey = assignedRec.map((id) => id.unique_id)

  useEffect(() => {
    const getProgramsList = async () => {
      setAssignLoading(true);

      try {
        let res = await getEmailProgrammeShortCourseListFilters(type === "update" ? Number(id) : 0);

        if (res.status === 200) {
          setFilterData({ ...res.data, intake: res?.data?.statusFilters, learning: res?.data?.typeFilters,linked:res?.data?.linkedPMC })
          setAssignLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error:", error)
          setAssignLoading(false)
        }
      }

    }

    const getsingleProgramFilter = () => {
      const data = {
        id:id,
        isAssigned:0,
        page_type:0,
        getdatafor:'email'
      }
      GetAllPmcPagesListFilters(data)
        .then((res) => {
          setAvailableFilterData({ intake: res?.data?.statusListFilter, learning: res?.data?.getType, linked:res?.data?.getLinkedProgrammes })
        })
    }

    const getsingleShortFilter = () => {
    const data = {
        id:id,
        isAssigned:0,
        page_type:1,
        getdatafor:'email'
      }
      GetAllPmcPagesListFilters(data)
        .then((res) => {
          setAvailableCourseFilterData({ intake: res?.data?.statusListFilter, learning: res?.data?.getType, linked:res?.data?.getLinkedProgrammes })
        })
    }

    getProgramsList()
    getsingleProgramFilter()
    getsingleShortFilter()
  }, [id])

  useEffect(() => {
    const cancelTokenSources = [];
    const getProgramsList = async () => {
      setAvailableLoading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      const data = {
        id:id,
        isAssigned:0,
        page_type: 0,
        getdatafor:'email'
      };
      try {
        let res = await GetAllPmcPagesListCancelToken(data, source.token);

        if (res.status === 200) {
          setAvailableRec(res?.data?.result);
          setAvailableLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error:", error)
          setAvailableLoading(false)
        }
      }

    }
    getProgramsList()
    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [id])

  useEffect(() => {
    const cancelTokenSources = [];
    const getProgramsList = async () => {
      setCourseLoading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      const data = {
        id:id,
        isAssigned:0,
        page_type:1,
        getdatafor:'email'
      };
      try {
        let res = await GetAllPmcPagesListCancelToken(data, source.token);
        
        if (res.status === 200) {
          setAvailableCourseRec(res?.data?.result);
          setCourseLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error:", error)
          setCourseLoading(false)
        }
      }

    }
    getProgramsList()
    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [id])

  const handleAdd = (rec, type) => {
    console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk',rec);
    if (type === 'add') {
      // if (rec.assigned_type === 3){
        Swal.fire({
          title: "Are you sure?",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Add it!",
        }).then((result) => {
          if (result.isConfirmed) {
            availableCourseRec.map((elem, ind) => {
              if (elem.unique_id == rec.unique_id) {
                availableCourseRec.splice(ind, 1);
                setAssignedRec([...assignedRec, rec]);
                if (addedRec.length) {
                  addedRec.map((e, i) => {
                    if (e == rec.id) {
                      addedRec.splice(i, 1);
                      setAddedRec([...addedRec]);
                    } else {
                      setAddedRec([...addedRec, rec.unique_id]);
                    }
                  });
                } else {
                  setAddedRec([...addedRec, rec.unique_id]);
                }
              }
            });
            Swal.fire("Success!", "Course Assigned Successfully.", "success");
          }
        });
      // } else if (rec.assigned_type === 2){
        // Swal.fire({
        //   title: "Are you sure?",
        //   icon: "info",
        //   showCancelButton: true,
        //   confirmButtonColor: "#3085d6",
        //   cancelButtonColor: "#d33",
        //   confirmButtonText: "Yes, Add it!",
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     availableRec.map((elem, ind) => {
        //       if (elem.unique_id == rec.unique_id) {
        //         availableRec.splice(ind, 1);
        //         setAssignedRec([...assignedRec, rec]);
        //         if (addedRec.length) {
        //           addedRec.map((e, i) => {
        //             if (e == rec.id) {
        //               addedRec.splice(i, 1);
        //               setAddedRec([...addedRec]);
        //             } else {
        //               setAddedRec([...addedRec, rec.unique_id]);
        //             }
        //           });
        //         } else {
        //           setAddedRec([...addedRec, rec.unique_id]);
        //         }
        //       }
        //     });
        //     Swal.fire("Success!", "Programe Assigned Successfully.", "success");
        //   }
        // });
      // }
    } else if (type === "delete") {
      // if (rec.assigned_type === 3) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            assignedRec.map((elem, ind) => {
              if (elem.unique_id == rec.unique_id) {
                assignedRec.splice(ind, 1);
                setAvailableCourseRec([...availableCourseRec, rec]);
                if (addedRec.length) {
                  addedRec.map((e, i) => {
                    if (e == rec.id) {
                      addedRec.splice(i, 1);
                      setAddedRec([...addedRec]);
                    } else {
                      setAddedRec([...addedRec, rec.unique_id]);
                    }
                  });
                } else {
                  setAddedRec([...addedRec, rec.unique_id]);
                }
              }
            });
          }
        });
      // } else if(rec.assigned_type === 2){
        // Swal.fire({
        //   title: "Are you sure?",
        //   text: "You won't be able to revert this!",
        //   icon: "warning",
        //   showCancelButton: true,
        //   confirmButtonColor: "#3085d6",
        //   cancelButtonColor: "#d33",
        //   confirmButtonText: "Yes, delete it!",
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     assignedRec.map((elem, ind) => {
        //       if (elem.unique_id == rec.unique_id) {
        //         assignedRec.splice(ind, 1);
        //         setAvailableRec([...availableRec, rec]);
        //         if (addedRec.length) {
        //           addedRec.map((e, i) => {
        //             if (e == rec.id) {
        //               addedRec.splice(i, 1);
        //               setAddedRec([...addedRec]);
        //             } else {
        //               setAddedRec([...addedRec, rec.unique_id]);
        //             }
        //           });
        //         } else {
        //           setAddedRec([...addedRec, rec.unique_id]);
        //         }
        //       }
        //     });
        //   }
        // });
      // }
    }
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => (
        <Link
          className="as-text-blue curser feature-name"
          //   to={handleRedirection(row.id)}
          title={row.name}
        >
          <span className="textLimit100">{row.name}</span>
        </Link>
      ),
    },
    {
      name: "Code",
      selector: "Code",
      sortField: "Code",
      sortable: true,
      cell: (row) => (row.Code ? row.Code : "-"),
    },
    {
      name: "Status",
      selector: "publish_status",
      sortField: "publish_status",
      sortable: true,
      cell: (row) => (row.publish_status ? RenderCourseAssignedlistStatus(row.publish_status).html : "-"),
    },
    {
      name: "Linked programmes and courses",
      selector: "LinkedProgrammeName",
      sortable: true,
      cell: (row) => row?.LinkedProgrammeName ? (
        <div title={row?.LinkedProgrammeName}>
          <Link className="as-text-blue cursor feature-name"
          to={`/websiteManagement/pages/programmes_and_short_courses/${row?.type == "2" ? 'programme_pages' : 'short_course_page'}/details/open/${row?.course_id}`}
          >
            {/* <i className="fas fa-circle mr-1"></i> */}
            {row?.LinkedProgrammeName}
          </Link>
        </div>) : "-"
    },
    {
      name: "Programme Type",
      selector: "qualification_type_name",
      sortable: true,
      sortField: "qualification_type_name",
      cell: (row) =>
        row?.qualification_type_name
          ? RenderLearningMethod(row?.qualification_type_name, "programme").html
          : "-",
    },
    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <Link
              className="btn btn-primary h-auto rounded-circle"
              // to={`/websiteManagement/events/upcoming_events/event_details/update/${row.id}`}
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </Link>

            <button
              type="button"
              className="btn btn-danger rounded-circle"
              onClick={() => handleAdd(row, "delete")}
              title='Delete'
            >
              <i className="fal fa-minus"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const dataToRender = () => {
    let updatedData = [];
    let allData = assignedRec;

    if (searchAssigned.length) {
      let name = allData?.filter((item) => {
        let includes = item?.name?.toString()
          .toLowerCase()
          .includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...name];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (assingStudyType.arr.length) {
      let tempAssignStudy = updatedData;
      let tempIntakeHash = tempAssignStudy.filter((item) => {
        const startsMarking = assingStudyType.arr.find((it) => it == item.qualification_type)
        if (startsMarking) {
          return startsMarking;
        } else return null;
      });
      updatedData = tempIntakeHash;
    } 
    if (assingIntake.arr.length) {
      let tempIntake = updatedData;
      let tempIntakeHash = tempIntake.filter((item) => {
        const startsMarking = assingIntake.arr.find((it) => it == item.publish_status)
        if (startsMarking) {
          return startsMarking;
        } else return null;
      });

      updatedData = tempIntakeHash;
    }
    if(linkedProgramme.arr.length){
      let tempLinkedProgramme = updatedData;
      let tempLinkedProgrammeHash = tempLinkedProgramme.filter((item) => {
        const startsMarking = linkedProgramme.arr.find((it) => it == item.pmc_id)
        if (startsMarking) {
          return startsMarking;
        } else return null;
      });

      updatedData = tempLinkedProgrammeHash;
    }

    if (
      searchAssigned.length ||
      linkedProgramme.arr.length ||
      assingStudyType.arr.length ||
      assingIntake.arr.length
    ) {
      return updatedData;
    } else {
      return assignedRec;
    }
  };

  const exportData = () => {
    let data = dataToRender();
    const header = [
      "Name",
      "Code",
      "Status",
      "Type",
      "Learning Methods"
    ];
    data = data.map((row) => ({
      "Name": row.name ? row.name : "-",
      "Code": row.Code ? row.Code : "-",
      "Status": row.status,
      "Type": row?.qualification_type,
      "Learning Methods": row?.learning_Methods,
    }));
    const pdfData = data.map((row) => {
      return [
        row["Name"],
        row["Code"],
        row["Status"],
        row["Type"],
        row["Learning Methods"]
      ];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  };

  return (
    <>
      <DataTableComponentFrontPagination
        data={dataToRender()}
        columns={columns}
        loading={assignloading}
        search={searchAssigned}
        setSearch={setSearchAssigned}
        exportData={exportData}
        exportFileName={"Assigned_Programmes_and_sortCourses"}
        progressComponent={<SkeletonTicketList />}
        defaultSort={{ defaultSortColumn: "name", defaultSortAsc: false }}
        keyField = {uniquekey[1]}
        filters={[
          {
            filterName: "Status",
            optionArr: filterData.intake || [],
            state: assingIntake, 
            setState: setAssingIntake,
            uniqueId: "assignIntake",
            isOptionReversed: true,
            renderLabelFunction: RenderCourseAssignedlistStatus,
          },
          {
            filterName: "Linked programmes and courses",
            optionArr: filterData.linked || [],
            state: linkedProgramme,
            setState: setLinkedProgramme,
            uniqueId: "assignStudyType",
            // renderLabelFunction: RenderLearningMethod,
          },
          {
            filterName: "Programme Type",
            optionArr: filterData.learning || [],
            state: assingStudyType,
            setState: setAssingStudyType,
            uniqueId: "assignStudyType",
            renderLabelFunction: RenderLearningMethod,
          },
        ]}
        tableButton={
          [
            <div className='d-flex'>
              <button className="btn btn-primary mr-1" data-toggle="modal" data-target="#availableProgramme" title="Assign Programme" type="button">
                <i className="fal fa-laptop"></i>Assign Programme
              </button>

              <button className="btn btn-primary" data-toggle="modal" data-target="#availableShortCourse" title="Assign Short Course" type="button">
                <i className="fal fa-books"></i>Assign Short Course
              </button>
            </div>
          ]
        }
      />

      <div
        className="topic-add-modal modal able_modal01 fade"
        id="availableProgramme"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <EmailAvailableProgrames
            AvailablefilterData={AvailablefilterData}
            availableRec={availableRec}
            assignedRec={assignedRec}
            setAssignedRec={setAssignedRec}
            availableLoading={availableLoading}
            handleAdd={handleAdd}
          />
        </div>
      </div>

      <div
        className="topic-add-modal modal able_modal01 fade"
        id="availableShortCourse"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <EmailAvailableShortCourse
            AvailablefilterData={AvailableCoursefilterData}
            availableRec={availableCourseRec}
            assignedRec={assignedRec}
            setAssignedRec={setAssignedRec}
            availableLoading={courseLoading}
            handleAdd={handleAdd}
          />
        </div>
      </div>
    </>
  )
}

export default EmailAssignedProgrammes