import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import Str from "../../common/Str";
import { Link, useHistory } from "react-router-dom";
import { TrimText } from "../../common/TrimText";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { GetCommonContentReReg } from "../../../services/ProgrammeServices";
import {
  TABLE_DATE_FORMAT,
  TABLE_ROWS_PER_PAGE,
} from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
import {
  getProgrammeStudyPathModuleList,
  LinkedModuleNetsuiteProductCourseList,
  LinkedModuleNetsuiteProductCourseListFilters,
  LinkInatkeToModule,
  UnlinkInatkeToModule,
} from "../../../services/ProgramService";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";

function CreateStudyPathModuleModel({
  data,
  detail,
  addProgramme,
  tableData1,
  setTableData1,
  tableData,
  module_type,
  totalRows,
}) {
  const history = useHistory();
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [intake, setIntake] = useState({ arr: [], checkObj: {} });
  const [campus, setCampus] = useState({ arr: [], checkObj: {} });
  const [delivery, setDelivery] = useState({ arr: [], checkObj: {} });
  const [courseType, setCourseType] = useState({ arr: [], checkObj: {} });
  const [listData, setListData] = useState([]);
  const [dropdown, setDropdown] = useState({
    intake: [],
    campus: [],
    deliveryMethod: [],
    courseType: [],
  });
  const [school, setSchool] = useState({ arr: [], checkObj: {} });
  const [schoolArr, setSchoolArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [sortkey, setSortKey] = useState("name");
    const [sortOrder, setSortOrder] = useState("ASC");
  const [totalRows1, setTotalRows1] = useState(0);
  const [tableState, setTableState] = useState({
    page: 0,
    perPage: 10,
    sortKey: "",
    sortOrder: "asc",
  });

  useEffect(() => {
    handleTableScroll();
  }, [loading]);

  useEffect(() => {
    const getListOfuser = async () => {
      setLoading(true);
      const apiData = {
        page: page,
        limit: perPage,
        key: sortkey,
        sort: sortOrder,
        search: search,
        exportStatus: "false",
        isAssigned: 0,
        programme_id : id,
        module_type: module_type,
        id: id,
      };

      try {
        const res = await getProgrammeStudyPathModuleList(apiData);
        if (res.status == 200) {
          setTableData1(res?.data.modules.data);
          setTotalRows1(res?.data.modules.total);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    getListOfuser();
  }, [search, id,perPage,page,sortkey,sortOrder]);

  const handlePageChange = (pageNo) => {
    console.log("__________________________________",pageNo);
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.selector);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  useEffect(() => {
    const getFilters = async () => {
      const response = await LinkedModuleNetsuiteProductCourseListFilters(
        id,
        detail?.netsuite_product,
        0
      );
      if (response.status === 200) {
        setDropdown({
          ...dropdown,
          intake: response.data?.yearsAndIntaksFilters,
          campus: response.data?.campusFilter,
          deliveryMethod: response.data?.intakeTypeFilters,
          courseType: response.data?.subjectTypeFilter,
        });
      }
    };
    getFilters();
  }, [detail]);

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken?.source();
      cancelTokenSources.push(source);
      try {
        const data = {
          module_id: id,
          netsuite_product_id: detail?.netsuite_product,
          isAssigned: 0,
        };
        const response = await LinkedModuleNetsuiteProductCourseList(data);
        if (response.status === 200) {
          setListData(response.data.data.data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error :", error);
        }
      }
    };

    getData();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [detail]);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["matpview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    GetCommonContentReReg()
      .then((res) => setSchoolArr(res.data.school_type))
      .catch((err) => console.error("err in common data api :", err));
  }, []);

  const resetFilter = () => {
    setSearch("");
    setIntake({ arr: [], checkObj: {} });
    setCampus({ arr: [], checkObj: {} });
    setDelivery({ arr: [], checkObj: {} });
    setCourseType({ arr: [], checkObj: {} });
  };

  const exportData = (fileType, fileName) => {
    let data;
    const header = ["Name", "Code","Duration (Weeks)","Credits"];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    const cData = {
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search,
      exportStatus: "true",
      isAssigned: 0,
      programme_id: id,
      module_type: module_type,
      id: id,
    }
    getProgrammeStudyPathModuleList(cData).then(res => {
      data = res.data?.modules;
      data = data?.map((row, ind) => ({
        ...row,
        "Name": row.name ? row.name : "-",
        "Code" : row.Code ? row.Code: "-",
        "Duration (Weeks)": row.Duration ? row.Duration : "-",
        "Credits" : row.credits ? row.credits : "-",
      }));
      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        // console.log(csvString);
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
        anchor.click();

        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.map((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        // Returning false as downloading of file is already taken care of
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [row["Name"], row["Code"],row["Duration (Weeks)"],row["Credits"]];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            color : "white",
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }

    }).catch(error => {
      console.log("error -----> ", error);
    })




  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const myNameFilter = (a, b) => {
    return a.SAQA_Name &&
      a.SAQA_Name.toLowerCase() > b.SAQA_Name &&
      b.SAQA_Name.toLowerCase()
      ? 1
      : -1;
  };
  const myCodeFilter = (a, b) =>
    a.Code.toLowerCase() > b.Code.toLowerCase() ? 1 : -1;
  const myNQFFilter = (a, b) =>
    Number(a.NQF_Level) > Number(b.NQF_Level) ? 1 : -1;
  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => (
        <Link
          className="as-text-blue curser feature-name"
          
          to={`/program-management-center/modules/module/open/${row.Id}`}
          title={row.name}
        >
          <span className="textLimit100">{row.name}</span>
        </Link>
      ),
    },
    {
      name: "Code",
      selector: "Code",
      sortable: true,
      cell: (row) => (
        <div className="ticket-id">
          {row.Code ? (
            <span title={row.Code} className="overflow-ellipsis2 as-text-blue">
              {TrimText(row.Code, 25)}
            </span>
          ) : (
            "-"
          )}
        </div>
      ),
    },

    {
      name: "Duration (Weeks)",
      selector: "Duration",
      sortable: true,
      cell: (row) => (
        <div className="ticket-id">
          {row.Duration ? (
            <span
              title={row.Duration}
              className="overflow-ellipsis2 as-text-blue"
            >
              {TrimText(row.Duration, 25)}
            </span>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      name: "Credits",
      selector: "credits",
      sortable: true,
      cell: (row) =>
        row.credits ? <div title={row.credits}>{row.credits}</div> : "-",
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => {
        const isAssigned = tableData?.some((item) => item.Id === row.Id);

        return (
          <div className="assessment-08">
            <div className="as-buttons">
            <Link
                to={`/program-management-center/modules/module/open/${row.Id}`}
                className="btn btn-primary rounded-circle"
                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </Link>
              <button
                type="button"
                className={`btn rounded-circle ${
                  isAssigned ? "btn-danger" : "btn-primary"
                }`}
                onClick={() => {
                  // Swal.fire({
                  //   title: "Are you sure?",
                  //   icon: "question",
                  //   showCancelButton: true,
                  //   confirmButtonColor: "#3085d6",
                  //   cancelButtonColor: "#d33",
                  //   confirmButtonText: "Yes, Add it!",
                  // }).then((result) => {
                  //   if (result.isConfirmed) {
                      addProgramme(row, isAssigned ? "delete" : "add");
                    // }
                  // });
                }}
                title={isAssigned ? "Unassign Page" : "Assign Page"}
              >
                <i className={`fal fa-${isAssigned ? "minus" : "plus"}`}></i>
              </button>
            </div>
          </div>
        );
      },
    },
  ]);

  const dataToRender = () => {
    let updatedData = [];
    let allData = data;
    if (search.length) {
      let tempCode = allData.filter((item) => {
        let includes =
          item.Code &&
          item.Code.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempName = allData.filter((item) => {
        let includes =
          item.P_SAQA_Name &&
          item.P_SAQA_Name.toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempLink = allData.filter((item) => {
        let includes =
          item.P_SAQA_Link &&
          item.P_SAQA_Link.toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempP_NQF_Level = allData.filter((item) => {
        let includes =
          item.P_NQF_Level &&
          item.P_NQF_Level.toString()
            .toLowerCase()
            .includes(search.toLowerCase());

        if (includes) {
          return includes;
        } else return null;
      });
      let tempP_School = allData.filter((item) => {
        let includes =
          item.P_School &&
          item.P_School.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempP_Quality_Council = allData.filter((item) => {
        let includes =
          item.P_Quality_Council &&
          item.P_Quality_Council.toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempdata = [
        ...tempCode,
        ...tempName,
        ...tempLink,
        ...tempP_NQF_Level,
        ...tempP_School,
        ...tempP_Quality_Council,
      ];
      let unique = [...new Set(tempdata)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (school.arr.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = school.arr.find(function (user, index) {
          if (
            user.toLowerCase() ==
            (item.P_School ? item.P_School.toLowerCase() : "")
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }

    if (search.length || school.arr.length) {
      return updatedData;
    } else {
      return data;
    }
  };
  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={search}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={() => resetFilter()}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "PMC");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>

                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "PMC");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>

                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "PMC");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          data={tableData1}
          columns={columns}
          pagination={true}
          sortServer={true}
          paginationServer={true}
          progressComponent={<SkeletonTicketList />}
          progressPending={loading}
          paginationTotalRows={totalRows1}
          noDataComponent={Str.noRecord}
          onSort={handleSort}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          defaultSortAsc={true}
          defaultSortField={"name"}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
    </div>
  );
}

export default CreateStudyPathModuleModel;
