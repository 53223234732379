import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import {
  TABLE_ROWS_PER_PAGE,
  TABLE_TIME_FORMAT,
} from "../../../utils/Constants";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import hasPermission from "../../../utils/hasMultiplePermission";
import Tablefilter from "../../common/Tablefilter";
import {
  LayoutHeaderStatus,
  RenderWebsiteBrandTemplate,
  RenderWebsitelistStatus,
  UpcomingEventsStatus,
} from "../../../utils/CommonStatusItems";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";
import $ from "jquery";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import {
  deleteInfoPack,
  deleteUpcomingEvent,
  getInfoPackFiltersList,
  getInfoPacksList,
  getInfoPacksListCancelToken,
  getUpcomingEventFiltersList,
  getUpcomingEventsList,
  getUpcomingEventsListCancelToken,
} from "../../../services/EventService";
import moment from "moment";

function InfoPacks() {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [linkeEvents, setLinkeEvents] = useState({ arr: [], checkObj: {} });
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [deleterow, setDeleteRow] = useState(false);
  const [updateData, setUpdateData] = useState(true);
  const [eventsData, setEventsData] = useState([]);
  const [loading, setloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterData, setFilterData] = useState({
    linkeEvents: [],
  });

  useEffect(() => {
    handleTableScroll();
  }, [loading]);

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });
  useEffect(() => {
    const storedFilters = JSON.parse(
      localStorage.getItem("filterStateInfoPacks")
    );
    if (storedFilters) {
      setLinkeEvents(storedFilters.linkeEvents);
      setFilterData(storedFilters.filterData);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      linkeEvents,
      filterData,
    };
    localStorage.setItem("filterStateInfoPacks", JSON.stringify(filterState));
  }, [linkeEvents, filterData]);
  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });

  useEffect(() => {
    const cancelTokenSources = [];
    const getUpcomingEventList = async () => {
      setloading(true);
      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      // setCancelTokenSources([...cancelTokenSources, source]);
      cancelTokenSources.push(source);

      const data = {
        page: page,
        perPage: perPage,
        keyval: sortkey,
        sort: sortOrder,
        search: search,
        exportStatus: false,
        viaEvent: linkeEvents.arr,
      };

      try {
        const res = await getInfoPacksListCancelToken(data, source.token);
        const event = res.data.data;
        setEventsData(event);
        setTotalRows(res.data?.total);
        setPerPage(res.data?.perPage);
        // if (res.linkeEvents == 200) {
        setloading(false);
        // }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    };

    getUpcomingEventList();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [
    page,
    perPage,
    sortOrder,
    sortkey,
    search,
    linkeEvents,
    searchStatusCheck,
    updateData,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getInfoPackFiltersList();
        setFilterData({
          ...res.data,
          linkeEvents: res.data.eventFilters ? res.data?.eventFilters : [],
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["wbseventinfopview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebouncedTerm(value);
  };

  const resetFilter = () => {
    setLinkeEvents({ arr: [], checkObj: {} });
    setSearchStatusCheck({});
    setSearch("");
    setDebouncedTerm("");
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteInfoPack({ id: id })
          .then((res) => {
            Swal.fire("Deleted!", "Info packs has been deleted.", "success");
            setUpdateData(!updateData);
          })
          .catch((error) => console.log(error));
      }
    });
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "from_title",
      sortField: "from_title",
      sortable: true,
      // minWidth: "190px",
      cell: (row) => (
        <div className="assigned-title-block-new">
          {/* <div className="assigned-title-itm"> */}
          <PermissionsGate scopes={["wbseventinfopedit"]} RenderError={() => <p>{row?.from_title}</p>}>
          <Link
            className="as-text-blue curser feature-name"
            to={`/websiteManagement/events/info_packs/email_details/open/${row.id}`}
            title={row.from_title}
          >
            <span className="textLimit100">{row.from_title}</span>
          </Link>
          </PermissionsGate>
          {/* </div> */}
        </div>
      ),
    },
    {
      name: "Linked Events",
      selector: "events",
      sortField: "events",
      sortable: false,
      // minWidth: "190px",
      cell: (row) =>
        row.events?.map((item) => (
          <div className="assigned-title-block-new">
            {/* <PermissionsGate scopes={["sgiview"]} RenderError={() => <p>{row.first_name + " " + row.last_name}</p>}> */}
            <Link
              className="as-text-blue curser feature-name"
              to={`/websiteManagement/events/landing_page/page_details/update/${item.event_id}`}
              title={item.EventTitle}
            >
              <span className="textLimit100">{item.EventTitle}</span>
            </Link>
            {/* </PermissionsGate> */}
            {/* </div> */}
          </div>
        )),
    },

    {
      name: "Last Updated",
      selector: "updated_at",
      sortField: "updated_at",
      sortable: true,
      cell: (row) => moment(row?.updated_at)?.format("DD MMM YY") || "-",
    },
    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <PermissionsGate scopes={['wbseventinfopedit']} RenderError={() => (
              <button className="btn btn-primary rounded-circle" disabled>
                <i className="fal fa-folder-open"></i>
              </button>
            )}>

            <Link
              className="btn btn-primary rounded-circle"
              to={`/websiteManagement/events/info_packs/email_details/open/${row.id}`}
              title="Open"
              >
              <i className="fal fa-folder-open"></i>
            </Link>
            </PermissionsGate>
            <PermissionsGate scopes={['wbseventinfopdelete']} RenderError={() => (
              <button className="btn btn-primary rounded-circle" disabled>
                <i className="fal fa-trash-alt"></i>
              </button>
            )}>

            <button
              className="btn btn-danger rounded-circle"
              onClick={() => handleDelete(row.id)}
              >
              <i className="fal fa-trash-alt"></i>
            </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data = [];
    const header = ["Name", "Linked Events", "Updated Date"];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const eventsData = {
      page: page,
      perPage: perPage,
      keyval: sortkey,
      sort: sortOrder,
      search: search,
      exportStatus: true,
      viaEvent: linkeEvents.arr,
    };
    getInfoPacksList(eventsData)
      .then((res) => {
        data = res.data;
        data = data?.map((row) => ({
          ...row,
          Name: row.from_title,
          "Linked Event":
            row?.events?.map((d, i) => d?.EventTitle).join(",") || "-",
          "Updated Date": row?.updated_at
            ? moment(row?.updated_at)?.format("DD MMM YY")
            : "-",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row?.from_title,
              row?.events?.map((d, i) => d?.EventTitle).join(",") || "-",
              row?.updated_at
                ? moment(row?.updated_at)?.format("DD MMM YY")
                : "-",
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        Swal.close();
        console.log(err);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [debouncedTerm]);

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom program-table">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={debouncedTerm}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner filter-custom-new`}>
                    <Tablefilter
                      filterName="Linked Events"
                      optionArr={filterData?.linkeEvents}
                      state={linkeEvents}
                      setState={setLinkeEvents}
                      // renderLabelFunction={UpcomingEventsStatus}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "info_packs");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "info_packs");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "info_packs");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate scopes={['wbseventinfopadd']} RenderError={() => (
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled
                    title="Create new"
                    >
                    <i className="fal fa-plus"></i>Create New
                  </button>
                )}>

                <Link
                  to={`/websiteManagement/events/info_packs/email_details/create`}
                  >
                  <button
                    type="button"
                    className="btn btn-primary"
                    title="Create new"
                    >
                    <i className="fal fa-plus"></i>Create New
                  </button>
                </Link>
                  </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          paginationDefaultPage={page}
          progressPending={loading}
          data={eventsData}
          progressComponent={<SkeletonTicketList />}
          defaultSortField={sortkey}
          defaultSortAsc={false}
          columns={columns}
          pagination={true}
          // noDataComponent={Str.noRecord}
          onSort={handleSort}
          sortServer
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
    </div>
  );
}

export default InfoPacks;
