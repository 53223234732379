import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import hasPermission from "../../../utils/hasMultiplePermission";
import Tablefilter from "../../common/Tablefilter";
import { LayoutHeaderStatus, RenderQuizTopic, RenderWebsiteBrandTemplate } from "../../../utils/CommonStatusItems";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";
import $ from "jquery";
import {
  DeleteLayhoutHeader,
  getAttachedPageFilters,
  getAttachedPageList,
} from "../../../services/LayoutHeaderServices";
import { RenderHeaderPageType, RenderHeaderTypeMethod } from "../../../utils/CommonGroupingItem";

function AttachedPage() {
  const history = useHistory();
  const { subId, subTab } = useParams();
  const [search, setSearch] = useState("");
  const [attachPage, setAttachPageData] = useState([]);
  const [brandTemplate, setBrandTemplate] = useState({ arr: [], checkObj: {} });
  const [pageType, setPageType] = useState({ arr: [], checkObj: {} });
  const [updateData, setUpdateData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [filterData, setFilterData] = useState({
    brandTemplate: [],
    pageType: [],
  });

  useEffect(() => {
    handleTableScroll();
  }, [loading]);

  useEffect(() => {
    const fetchHeadersList = async () => {
      try {
        setLoading(true)
        const res = await getAttachedPageList(subId);
        setAttachPageData(res.data.data);
        setPerPage(res.data.data.perPage);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (subId) {
      fetchHeadersList();
    }
  }, [subId, updateData]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const res = await getAttachedPageFilters(subId);
        setFilterData({
          brandTemplate: res.data.brandListFilter || [],
          pageType: res.data.headerStatusFilter || [],
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchFilters();
  }, []);

  const resetFilter = () => {
    setBrandTemplate({ arr: [], checkObj: {} });
    setPageType({ arr: [], checkObj: {} });
    setSearch("");
  };

  useEffect(() => {
    return resetFilter();
  }, [])

  const dataToRender = useCallback(() => {
    let updatedData = [...attachPage];

    if (search) {
      updatedData = updatedData.filter((item) =>
        item.PageTitle?.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (brandTemplate.arr.length) {
      updatedData = updatedData.filter((item) =>
        item?.brandTemplete?.some((template) =>
          brandTemplate.arr.includes(template?.templateName)
        )
      );
    }

    if (pageType.arr.length) {
      updatedData = updatedData.filter((item) =>
        pageType.arr.includes(item.type)
      );
    }

    return updatedData;
  }, [search, attachPage, brandTemplate, pageType]);


  const handleSearchFilter = (e) => setSearch(e.target.value);

  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: "PageTitle",
        sortField: "IsDefault",
        sortable: true,
        cell: (row) => (
          <Link
          title={row.PageTitle}
          className="as-text-blue curser feature-name"
          to={row.type == "Static page" && `/website_management/pages/staticpages/table/update/${row.PageID}` ||
          row.type == "Event Landing Page" && `/websiteManagement/events/landing_page/page_details/open/${row.PageID}` ||  
          row.type == "Category Landing Page" && `/website_management/pages/categorylandingpages/table/open/${row.PageID}` ||
          row.type == "Course Landing Page" && `/website_management/pages/courselandingpages/table/open/${row.PageID}`  
        }
        >
            <span className="textLimit100">
              {row.PageTitle ? row.PageTitle : ""}{" "}
            </span>
          </Link>
        ),
      },
      {
        name: "Brand Templates",
        selector: "brandTemplete",
        sortable: false,
        cell: (row) => {
          if (row.brandTemplete.length) {
            return (
              <>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {row?.brandTemplete?.map((d, i) => (
                    <React.Fragment key={i}>
                      {RenderWebsiteBrandTemplate(d?.templateName).html}
                    </React.Fragment>
                  ))}
                </div>
              </>
            );
          } else {
            return "-";
          }
        },
      },
      {
        name: "Page Type",
        selector: "type",
        sortField: "type",
        sortable: true,
        cell: (row) => (row.type ? RenderHeaderPageType(row.type)?.html : "-"),
      },
      {
        name: "Actions",
        cell: (row) => (
          <div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons d-flex">
              <Link
                className="btn btn-primary rounded-circle"
                to={row.type == "Static page" && `/website_management/pages/staticpages/table/update/${row.PageID}` ||
                  row.type == "Event Landing Page" && `/websiteManagement/events/landing_page/page_details/open/${row.PageID}` ||
                  row.type == "Category Landing Page" && `/website_management/pages/categorylandingpages/table/open/${row.PageID}` ||
                  row.type == "Course Landing Page" && `/website_management/pages/courselandingpages/table/open/${row.PageID}`}
                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </Link>
              <button
                className="btn btn-danger rounded-circle"
                // onClick={() => handleDelete(row.SliderID)}
                title="View"
              >
                <i className="fal fa-eye"></i>
              </button>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  const exportData = async (fileType, fileName) => {
    let data = dataToRender() || [];
    const header = ["Name", "Brand Templates", "Page Type"];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    try {
      const res = await getAttachedPageList(subId);
      data = res.data.data.map((row) => ({
        Name: row.PageTitle || "",
        "Brand Templates": row.brand_name || "-",
        "Page Type": row.type || "-",
      }));

      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
        const blobURL = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.click();
        URL.revokeObjectURL(blobURL);
      } else if (fileType === "xlsx") {
        const wb = XLSX.utils.book_new();
        const ws1 = XLSX.utils.json_to_sheet(data, { header });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
      } else if (fileType === "pdf") {
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: data.map((row) => [
            row.PageTitle,
            row.brand_name,
            row.type,
          ]),
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
      }
      Swal.close();
    } catch (err) {
      Swal.close();
      console.error(err);
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom program-table">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div className="dataTables_wrapper no-footer">
                  <div className="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        placeholder="Search"
                        onChange={handleSearchFilter}
                        value={search}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className="filter-scroll-inner filter-custom-new">
                    <Tablefilter
                      filterName="Brand Template"
                      optionArr={filterData.brandTemplate}
                      state={brandTemplate}
                      setState={setBrandTemplate}
                      // renderLabelFunction={RenderQuizTopic}
                      uniqueId="brand"
                    />
                  </div>
                </div>
                <div className="filter-scroll">
                  <div className="filter-scroll-inner filter-custom-new">
                    <Tablefilter
                      filterName="Page Type"
                      optionArr={filterData.pageType}
                      state={pageType}
                      setState={setPageType}
                      renderLabelFunction={RenderHeaderPageType}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() =>
                        exportData("xlsx", "Header_Attached_Page_list")
                      }
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() =>
                        exportData("csv", "Header_Attached_Page_list")
                      }
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() =>
                        exportData("pdf", "Header_Attached_Page_list")
                      }
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading && <SkeletonTicketList />}
        <DataTable
          data={dataToRender()}
          columns={columns}
          pagination
          defaultSortField="close_date_time_mili"
          defaultSortAsc={false}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
    </div>
  );
}

export default AttachedPage;
