import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { GetStudentDetailsCancelToken } from "../services/StudentsSettingServices";
import axios from "axios";
import BrandInformation from "../components/website/addbrandtemplate/brandtempletetab/BrandInformation";
import AuditTrail from "../components/StudentAdministration/Students/AuditTrail";
import BrandHome from "../components/website/addbrandtemplate/brandtempletetab/BrandHome";
import EventLandingPage from "../components/website/events/EventLandingPage";
import UpcomingEvents from "../components/website/events/UpcomingEvents";
import InfoPacks from "../components/website/events/InfoPacks";
import PermissionsGate from "../utils/permissionGate";
import EventAuditTrail from "../components/StudentAdministration/Students/EventAuditTrail";
import { getEventsAuditTrailListFilters } from "../services/WebsiteListServer";
import { getEventsAuditTrailList } from "../services/WebsiteListServer";
// This will be shown in merge-all branch

function Events() {
  const history = useHistory();
  const { type, id, tab, subType, subId } = useParams();

  const [loading, setLoading] = useState(false);
  const [websiteDetail, setWebsiteDetail] = useState([]);

  useEffect(() => {
    const cancelTokenSources = [];

    const getData = async () => {
      setLoading(true);
      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        setWebsiteDetail({ first_name: "", last_name: "" });
        const res = await GetStudentDetailsCancelToken(id, source.token);
        if (res.status == 200) {
          setWebsiteDetail(res.data.data_list);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      } finally {
        setLoading(false);
      }
    };

    if (type == "open") {
      getData();
    }

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [type]);

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div
          className={
            "sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")
          }
        >
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        {/* Student <span>List</span> */}
                        {type == "add"
                          ? "Add"
                          : type == "open"
                          ? "Events"
                          : "Events"}
                      </h4>
                    </div>
                    <div className="text-md-right action2-blk">
                      <div className="ticket-view-btn d-flex align-items-center">
                        <div className="add-new-ticket-bx">
                          <button
                            onClick={() => history.goBack()}
                            title="Back"
                            className="btn btn-white-bordered"
                          >
                            <i className="fal fa-angle-left"></i>Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                  {(tab == "landing_page" ||
                    tab == "upcoming_events" ||
                    tab == "info_packs" ||
                    tab == "auditTrail") && (
                    <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                      <PermissionsGate scopes={['wbseventlpageview']} RenderError={() => null}>

                      <li
                        className="nav-item"
                        onClick={() =>
                          history.push(`/websiteManagement/events/landing_page/table`)
                        }
                        >
                        <Link
                          className={`nav-link ${
                            tab === "landing_page" && "active"
                          }`}
                          id="pills-tab1"
                          data-toggle="pill"
                          to={`/websiteManagement/events/landing_page/table`}
                          role="tab"
                          aria-controls="pills-tab11"
                          aria-selected="true"
                        >
                          <i class="fa fa-globe"></i>
                          Event Landing Pages
                        </Link>
                      </li>
                      </PermissionsGate>
                      <PermissionsGate scopes={['wbseventupcomingview']} RenderError={() => null}>

                      <li
                        className="nav-item"
                        onClick={() =>
                          history.push(
                            `/websiteManagement/events/upcoming_events/table`
                          )
                        }
                        >
                        <Link
                          className={`nav-link ${
                            tab === "upcoming_events" && "active"
                          }`}
                          id="pills-tab2"
                          data-toggle="pill"
                          to={`/websiteManagement/events/upcoming_events/table`}
                          role="tab"
                          aria-controls="pills-tab12"
                          aria-selected="true"
                          >
                          <i class="fa fa-calendar"></i> Upcoming Events
                        </Link>
                      </li>
                      </PermissionsGate>
                      <PermissionsGate scopes={['wbseventinfopview']} RenderError={() => null}>

                      <li
                        className="nav-item"
                        onClick={() =>
                          history.push(`/websiteManagement/events/info_packs/table`)
                        }
                        >
                        <Link
                          className={`nav-link ${
                            tab === "info_packs" && "active"
                          }`}
                          id="pills-tab2"
                          data-toggle="pill"
                          to={`/websiteManagement/events/info_packs/table`}
                          role="tab"
                          aria-controls="pills-tab12"
                          aria-selected="true"
                          >
                          <i class="fa fa-envelope"></i> Info Packs
                        </Link>
                      </li>
                      </PermissionsGate>
                      <li
                        className="nav-item"
                        onClick={() =>
                          history.push(
                            `/websiteManagement/events/auditTrail/table`
                          )
                        }
                      >
                        <Link
                          className={`nav-link ${
                            tab == "auditTrail" && "active"
                          }`}
                          id="pills-tab2"
                          data-toggle="pill"
                          to={`/websiteManagement/events/auditTrail/table`}
                          role="tab"
                          aria-controls="pills-tab12"
                          aria-selected="true"
                        >
                          <i className="fal fa-history"></i>Audit trail
                        </Link>
                      </li>
                    </ul>
                  )}
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab11"
                      role="tabpanel"
                      aria-labelledby="pills-tab1"
                    >
                      {tab == "landing_page" && <EventLandingPage />}
                      {tab == "upcoming_events" && <UpcomingEvents />}
                      {tab == "info_packs" && <InfoPacks />}
                      {tab == "auditTrail" && <EventAuditTrail apiParams={{ action_id: subId, }} apiFunction={getEventsAuditTrailList} apiFilter={getEventsAuditTrailListFilters} exportName={"Event_auditTrail"}/>}
                      </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </>
  );
}

export default Events;
