import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import {
  AddAndUpdateProgrammeDates,
  AddAndUpdateShortCourseDates,
  DeleteProgrammeDates,
  DeleteShortCourseDates,
  GetCampusLocationDropdown,
  GetProgramDateFilter,
  GetProgrammesDatesListCancelToken,
  GetShortCourseDatesFilter,
  GetShortCourseDatesList,
} from "../../../services/ProgramService";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import Swal from "sweetalert2";
import { RenderLearningMethod } from "../../../utils/CommonGroupingItem";
import $ from "jquery";
import { RenderLateReReg, RenderLateReRegPMC } from "../../../utils/CommonStatusItems";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";
import DataTableComponent from "../../common/DataTableComponent";
import moment from "moment";
import { FORM_DATE_FORMAT, FORM_DATE_TIME_FORMAT, TABLE_DATE_FORMAT } from "../../../utils/Constants";
import PopupComponent from "../../common/PopupComponent";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Select from "react-select";
import DatePicker from "react-datepicker";

const LATE_REG_OPTION = [
  {
    value: "1",
    label: "Active"
  },
  {
    value: "0",
    label: "Inactive"
  }
]


const CourseDates = () => {
  const { id } = useParams()
  const history = useHistory();
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [campus, setCampus] = useState({ arr: [], checkObj: {} });
  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });
  const [lateReg, setLateReg] = useState({ arr: [], checkObj: {} });
  const [loading, setloading] = useState(true);
  const [updateData, setUpdateData] = useState(true);
  const [filterData, setFilterData] = useState({ campus: [], learningMethod: [], lateReReg: [] });
  const [tableState, setTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "id",
    sortOrder: "asc",
  });
  const [totalRows, setTotalRows] = useState(0);
  const [campusList, setCampusList] = useState([]);
  const dropDownValues = useSelector(state => state.registerDropdownValues);
  const [saving, setSaving] = useState(false);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    const cancelTokenSources = [];

    const getProgram = async () => {
      setloading(true);

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const payload = {
          page: tableState.page,
          limit: tableState.perPage,
          key: tableState.sortKey,
          sort: tableState.sortOrder,
          search: search,
          exportStatus: "false",
          viaCampus: campus.arr,
          viaDeliveryMethods: learningMethod.arr,
          viaRegStatus: lateReg.arr,
          pmc_id: id,
        }
        const res = await GetShortCourseDatesList(payload, source.token);
        if (res.status === 200) {
          const respData = res.data.data;
          setData(respData.data);
          setTotalRows(respData.total)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      } finally {
        setloading(false);
      }
    };

    getProgram();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData, tableState, search, id, campus, learningMethod, lateReg]);

  useEffect(() => {
    const filterSource = axios.CancelToken.source();
    const getFilters = async () => {
      try {
        const res = await GetShortCourseDatesFilter({ pmc_id: id }, filterSource.token);
        if (res.status == 200) {
          setFilterData({
            ...filterData,
            campus: res.data.campus,
            learningMethod: res.data.deliveryMethods,
            lateReReg: res.data.regStatus
          })
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }

    getFilters()
    return () => {
      filterSource.cancel('Component unmounted');
    };
  }, [data])

  useEffect(() => {
    const cancelDropdownSource1 = [];
    const getCampusLocationData = async () => {
      cancelDropdownSource1.forEach(source => {
        source.cancel('New request made');
      });
      const source1 = axios.CancelToken.source();
      cancelDropdownSource1.push(source1);

      try {
        const res = await GetCampusLocationDropdown(source1.token);
        if (res.status == 200) {
          setCampusList(res.data.campusLocationFilters);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }
    getCampusLocationData();
    return () => {
      cancelDropdownSource1.forEach(source => {
        source.cancel('Component unmounted');
      })
    }
  }, [])

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["pmcpview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  const columns = useMemo(() => [
    {
      name: "Campus",
      selector: "CampusName",
      sortField: "CampusName",
      sortable: true,
      cell: (row) => (
        <span title={row.CampusName} className="as-text-blue curser feature-name">
          {" "}
          <span className="textLimit100"  data-toggle="modal" data-target="#programmeDateForm" onClick={()=>{setEditData(row)}}>{row.CampusName}</span>
        </span>
      ),
    },
    {
      name: "Registration Close Date",
      selector: "reg_close_date",
      sortField: "reg_close_date",
      sortable: true,
      cell: (row) =>
        row?.reg_close_date ? moment(row?.reg_close_date).format(TABLE_DATE_FORMAT) : "-",
    },
    {
      name: "Start Date",
      selector: "start_date",
      sortField: "start_date",
      sortable: true,
      cell: (row) =>
        row?.start_date ? moment(row?.start_date).format(TABLE_DATE_FORMAT) : "-",
    },
    {
      name: "End Date",
      selector: "end_date",
      sortField: "end_date",
      sortable: true,
      cell: (row) =>
        row?.EndDate ? moment(row?.EndDate).format(TABLE_DATE_FORMAT) : "-",
    },
    {
      name: "Learning Method",
      selector: "delivery_Methods",
      sortField: "delivery_Methods",
      sortable: true,
      cell: (row, index) => (
        row?.delivery_Methods ? RenderLearningMethod(row?.delivery_Methods).html : "-"
      ),
    },
    {
      name: "Late Registration Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row) => (
        <div className="custom-control custom-checkbox text-left table-curser" title="Late Registration Active">
          <input
            type="checkbox"
            readOnly
            className="custom-control-input"
            id={`late_re_reg${row.id}`}
            name="late_re_reg"
            checked={row.status == 1} // Set the initial value from the data            
          />
          <label className="custom-control-label" htmlFor={row.id}>Late Registration Active</label>
        </div>
      ),
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <button
              className="btn btn-primary rounded-circle"
              title="Edit"
              data-toggle="modal" 
              data-target="#programmeDateForm"
              onClick={()=>{setEditData(row)}}
            >
              <i className="fal fa-edit"></i>
            </button>
            <button
              title="Delete"
              className="btn btn-danger rounded-circle"
              onClick={() => handleDelete(row.id)}
            >
              <i className="fal fa-trash-alt"></i>
            </button>
          </div>
        </div>
      ),
    },
  ], []);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteShortCourseDates({ id: id })
          .then((res) => {
            Swal.fire("Deleted!", "Programme Date has been deleted.", "success");
            setUpdateData(!updateData);
          })
          .catch((error) => console.log(error));
      }
    });
  };

  const exportData = (fileType, fileName) => {
    let exportData = [...data];

    const header = ["Campus", "Registration Close Date", "Start Date", "End Date", "Learning Method", "Late Registration Status"];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const payload = {
      page: tableState.page,
      limit: tableState.perPage,
      key: tableState.sortKey,
      sort: tableState.sortOrder,
      search: search,
      exportStatus: "false",
      viaCampus: campus.arr,
      viaDeliveryMethods: learningMethod.arr,
      viaRegStatus: lateReg.arr,
      pmc_id: id,
    }

    GetShortCourseDatesList(payload).then((res) => {
      exportData = res.data.data.data;
      exportData = exportData?.map((row) => ({
        ...row,
        Campus: row?.CampusName ? row?.CampusName : "-",
        "Registration Close Date": row?.reg_close_date ? moment(row?.reg_close_date).format(TABLE_DATE_FORMAT) : "-",
        "Start Date": row?.start_date ? moment(row?.start_date).format(TABLE_DATE_FORMAT) : "-",
        "End Date": row?.EndDate ? moment(row?.EndDate).format(TABLE_DATE_FORMAT) : "-",
        "Learning Method": row?.delivery_Methods ? RenderLearningMethod(row?.delivery_Methods).text : "-",
        "Late Registration Status": row.status == 1 ? "Yes" : "No",
      }));

      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, exportData });
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [
          "text/csv",
          anchor.download,
          anchor.href,
        ].join(":");
        anchor.click();

        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = exportData.map((row) => {
          const obj = {};
          header.forEach((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        // Returning false as downloading of file is already taken care of
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = exportData.map((row) => {
          return [
            row["Campus"],
            row["Registration Close Date"],
            row["Start Date"],
            row["End Date"],
            row["Learning Method"],
            row["Late Registration Status"],
          ];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            // valign: "center",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }
    }).catch((err) => {
      console.log(err)
    })
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pmc_id : id,
      campus_id: editData?.campus_id ? editData?.campus_id : "",
      delivery_Methods: editData?.delivery_Methods ? editData?.delivery_Methods : "",
      reg_status: (editData.status == "0" ||  editData.status == "1") ? String(editData.status) : "",
      start_date: editData?.start_date ? new Date(editData?.start_date) : "",
      EndDate: editData?.EndDate ? new Date(editData?.EndDate) : "",
      reg_close_date: editData?.reg_close_date ? new Date(editData?.reg_close_date) : "",
    },
    validationSchema: Yup.object({
      campus_id: Yup.string().required("Campus is required"),
      delivery_Methods: Yup.string().required("Learning Method is required"),
      reg_status: Yup.string().required("Late Registration Status is required"),
      start_date: Yup.string().required("Start Date is required"),
      EndDate: Yup.string().required("End Date is required"),
      reg_close_date: Yup.string().required("Registration Close Date is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);
      let startDate = moment(values.start_date).format("YYYY-MM-DD") + " 00:00:00";
      let endDate = moment(values.EndDate).format("YYYY-MM-DD") + " 00:00:00";
      let regCloseDate = moment(values.reg_close_date).format("YYYY-MM-DD") + " 00:00:00";

      let payload = {
        pmc_id : id,
        campus_id : values.campus_id,
        delivery_Methods : values.delivery_Methods,
        reg_status : values.reg_status,
        start_date : startDate,
        EndDate:endDate,
        reg_close_date : regCloseDate
      }

      if(editData.id){
        payload = {...payload, id : editData.id};
      }
      AddAndUpdateShortCourseDates(payload).then((res)=>{
        if(res.data === 200){
          Swal.fire({
            icon: "success",
            title: "Success",
            text: editData.id ? "Record Updated Successfully" : "Record Added Successfully",
          });  
        
     
        }

      }).catch((err)=>{
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.data}`,
        });
      }).finally(() => {
        $("#programmeDateForm").modal("hide");
        setSaving(false);
        setEditData({});
        setUpdateData(!updateData);
        formik.resetForm()
      });
      
    },
  });
  const handleClose = () => {
    formik.resetForm();
    setEditData({});        
    history.push(`/program-management-center/programmes/courseDates/table/${id}`);
  }

  return (
    <>
      <DataTableComponent
        data={data}
        columns={columns}
        loading={loading}
        search={search}
        setSearch={setSearch}
        state={tableState}
        setState={setTableState}
        totalRows={totalRows}
        exportFunction={exportData}
        exportFileName={"Course_Date_List"}
        filters={[
          {
            filterName: "Campus",
            optionArr: filterData.campus,
            state: campus,
            setState: setCampus
          },
          {
            filterName: "Learning Method",
            optionArr: filterData.learningMethod,
            state: learningMethod,
            setState: setLearningMethod,
            renderLabelFunction: RenderLearningMethod,
          },
          {
            filterName: "Late Registration Status",
            optionArr: filterData.lateReReg,
            state: lateReg,
            setState: setLateReg,
            renderLabelFunction: RenderLateReRegPMC,
          }
        ]}
        tableButton={[
          <button className="btn btn-primary" title="Add Dates" data-toggle="modal" data-target="#programmeDateForm">
            <i className="fal fa-calendar-check"></i>Add Dates
          </button>
        ]}
      />
      <PopupComponent
        id="programmeDateForm"
        type="form"
        closeFunction={() => { handleClose() }}
        header={{ iconName: "fa-calendar-alt", heading: editData.id ? "Update Course Date" : "Add Course Date" }}
        inputs={[
          <>
            <div className="col-md-6 col-lg-4">
              <div className="form-group-blk mb-3" title="Campus">
                <label>Campus * <i className="fal fa-info-circle grade-icon"></i></label>
                <Select
                  className={
                    "form-control custom-select-box" +
                    (formik.errors.campus_id &&
                      formik.touched.campus_id
                      ? " is-invalid"
                      : "")
                  }
                  name="campus_id"
                  value={campusList.filter((obj) => {
                    return obj.value == formik.values.campus_id
                  })}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("campus_id", value.value)
                    } else {
                      formik.setFieldValue("campus_id", "")
                    }
                  }}
                  onBlur={formik.handleBlur}
                  options={campusList}
                  maxMenuHeight={175}
                  placeholder={
                    formik.values.campus_id
                      ? formik.values.campus_id
                      : "Select Campus"
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group-blk mb-3" title="Learning Method">
                <label>Learning Method *</label>
                <Select
                  className={
                    "form-control custom-select-box" +
                    (formik.errors.delivery_Methods &&
                      formik.touched.delivery_Methods
                      ? " is-invalid"
                      : "")
                  }
                  name="delivery_Methods"
                  value={dropDownValues.study_type.filter((obj) => {
                    return obj.value == formik.values.delivery_Methods
                  })}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("delivery_Methods", value.value)
                    } else {
                      formik.setFieldValue("delivery_Methods", "")
                    }
                  }}
                  onBlur={formik.handleBlur}
                  options={dropDownValues.study_type}
                  maxMenuHeight={175}
                  placeholder={
                    formik.values.delivery_Methods
                      ? formik.values.delivery_Methods
                      : "Select Learning Method"
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group-blk mb-3" title="Late Registration Status">
                <label>Late Registration Status *</label>
                <Select
                  className={
                    "form-control custom-select-box" +
                    (formik.errors.reg_status && formik.touched.reg_status
                      ? " is-invalid"
                      : "")
                  }
                  name="reg_status"
                  value={LATE_REG_OPTION.filter((obj) => obj.value === formik.values?.reg_status)}
                  onChange={(option) => {
                    if (option) {
                      formik.setFieldValue("reg_status", option.value);
                    } else {
                      formik.setFieldValue("reg_status", "");
                    }
                  }}
                  onBlur={formik.handleBlur}
                  options={LATE_REG_OPTION}
                  maxMenuHeight={175}
                  placeholder="Select Status"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group-blk mb-4">
                <label>Start Date *</label>
                <div className="">
                <DatePicker
                  autoComplete="off"
                  selected={formik.values.start_date}
                  name="start_date"
                  onChange={(date) => {
                    formik.setFieldValue("start_date", date);                    
                  }}
                  dateFormat={FORM_DATE_FORMAT}
                  showYearDropdown
                  scrollableYearDropdown
                  dateFormatCalendar="MMMM"
                  className={
                    "form-control cursor-pointer" +
                    (formik.errors.start_date &&
                      formik.touched.start_date
                      ? " is-invalid empty-date"
                      : "")
                  }
                  title="Start Date"
                  placeholderText="Select Start Date"
                  timeIntervals={15}
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                  // minDate={new Date()}
                />
              </div>
              <span className="icon-after-date"><i className="fal fa-calendar-alt"></i></span>
                    </div> 
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group-blk mb-4">
                <label>Registration Close Date *</label>
                <div className="">
                <DatePicker
                  autoComplete="off"
                  selected={formik.values.reg_close_date}
                  onChange={(date) => {
                    formik.setFieldValue("reg_close_date", date);                    
                  }}
                  name="reg_close_date"
                  dateFormat={FORM_DATE_FORMAT}
                  showYearDropdown
                  scrollableYearDropdown
                  dateFormatCalendar="MMMM"
                  className={
                    "form-control cursor-pointer" +
                    (formik.errors.reg_close_date &&
                      formik.touched.reg_close_date
                      ? " is-invalid empty-date"
                      : "")
                  }
                  disabled={!formik.values.start_date}
                  title="Registration Close Date"
                  placeholderText="Select Date"
                  timeIntervals={15}
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                  // minDate={
                  //   (() => {
                  //     const startDate = new Date(formik.values.start_date);
                  //     const minDate = new Date(startDate);
                  //     minDate.setDate(minDate.getDate() + 1);
                  //     return minDate;
                  //   })()
                  // }
                />
                 <span className="icon-after-date"><i className="fal fa-calendar-alt"></i></span>
                    </div> 
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group-blk mb-4">
                <label>End Date *</label>
                <div className="">
                <DatePicker
                  autoComplete="off"
                  selected={formik.values.EndDate}
                  name="end date"
                  onChange={(date) => {
                    formik.setFieldValue("EndDate", date);                    
                  }}
                  dateFormat={FORM_DATE_FORMAT}
                  showYearDropdown
                  scrollableYearDropdown
                  dateFormatCalendar="MMMM"
                  className={
                    "form-control cursor-pointer" +
                    (formik.errors.EndDate &&
                      formik.touched.EndDate
                      ? " is-invalid empty-date"
                      : "")
                  }
                  title="end Date"
                  placeholderText="Select end Date"
                  timeIntervals={15}
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                  // minDate={new Date()}
                  // minDate={
                  //   (() => {
                  //     const startDate = new Date(formik.values.start_date);
                  //     const minDate = new Date(startDate);
                  //     minDate.setDate(minDate.getDate() + 1);
                  //     return minDate;
                  //   })()
                  // }
                />
                  <span className="icon-after-date"><i className="fal fa-calendar-alt"></i></span>
                    </div> 
              </div>
            </div>  
            
          </>
        ]}
        bottomButton={[
          <button type="submit" className="btn btn-save btn-success" title="Save" onClick={() => { formik.handleSubmit() }} disabled={saving}>
            {saving ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}Save
          </button>,
          <button type="reset" title="Cancel" className="btn btn-danger" data-dismiss="modal" onClick={() => { handleClose() }}>
            <i className="fal fa-times"></i>
            Cancel
          </button>,
          Object.keys(formik.values).map((key, ind) => {
            if (formik.touched[key] && formik.errors[key]) {
              return (
                <div className="invalid-feedback d-block" key={ind}>
                  {formik.errors[key]}
                </div>
              )
            }
          })
        ]}
      />
    </>
  );
};

export default CourseDates;
