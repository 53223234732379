import React, { useMemo, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import TreeTheme from "react-sortable-tree-theme-minimal";

import {
  DeleteProgrammeStudyPath,
  getCommonContent,
  GetProgramAssignedOrNotAssignedList,
  GetProgramAssignedOrNotAssignedListCancelToken,
  GetProgrammeStudyPathList,
  ReArrangeStudyPathPhase,
  RemoveModuleFromProgramme,
} from "../../../services/ProgramService";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import PermissionsGate from "../../../utils/permissionGate";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import { RenderModuleType } from "../../../utils/CommonGroupingItem";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";
import Str from "../../common/Str";
import { TrimText } from "../../common/TrimText";
import AvailableTables from "./ProgramDetailBlock/AvailableTables";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import SortableTree from "react-sortable-tree";
import { Skeleton } from "@material-ui/core";
import { Placeholder } from "semantic-ui-react";

const StudyPathRearrange = () => {
  const history = useHistory();
  const { id } = useParams();
  const [search, setSearch] = useState("");

  const [duration, setDuration] = useState({arr:[], checkObj:{}});
  const [moduleType, setModuleType] = useState({ arr: [], checkObj: {} })
  const [filterData, setFilterData] = useState([]);
  const [data, setData] = useState(); 
  const [availableData, setAvailableData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availableLoading, setAvailableLoading] = useState(false);
  const [renderData, setRenderData] = useState([]);
  const [buttonLoad, setButtonLoad] = useState(false);
  const [studyPathData,setStudyPathData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false)
  const [tableState, setTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "order_number",
    sortOrder: "ASC"
})

  useEffect(() => {
    handleTableScroll()
  }, [loading])


  useEffect(() => {
    const filterSource = axios.CancelToken.source();
    const getFilters = async () =>{
      try {
        const res = await getCommonContent(filterSource.token);
        if(res.status==200){
          setFilterData(res?.data.type_course);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);          
        }
      }
    }
    
    getFilters();
    return () => {
      filterSource.cancel('Component unmounted');      
    };
  }, []);

  useEffect(() => {
      const cancelTokenSources = [];

      const getProgramsList = async () => {
        setLoading(true)
    
        cancelTokenSources.forEach(source => {
          source.cancel('New request made');
        });
    
        const source = axios.CancelToken.source();
        cancelTokenSources.push(source);

        try {
          const res = await GetProgramAssignedOrNotAssignedListCancelToken({ PId: id, type: "assigned" }, source.token);
          if (res.status === 200) {
            setLoading(false)
            setData(res.data.programs);
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
            setLoading(false)
          }
        }
      };
    
      getProgramsList();
  
      return () => {
        cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
        });
      };
  }, [refresh]);

  const handleChangeData = (newData) => {
    setRenderData(newData);
};

const handleDragNode = (dragData) => {
    const { isDragging } = dragData;

    if (isDragging === false) {
        setIsDisabled(false);
    }
};

const handleSaveData = () => {
  setIsDisabled(true);
    console.log("__________________________________",renderData);
    let array = []
    for(let d of renderData){
      array.push(
        d.id
      )
    }
    let dataToSend = {
      study_paths : array
    }
        ReArrangeStudyPathPhase(dataToSend).then((res)=>{
            if(res.status===200){
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: `${res?.data?.message}`,
                  }).then(() => {
                    // getData();
                    setIsDisabled(false)
                    setRefresh(!refresh)
                  });
            }
        }).catch((err)=>{
          setIsDisabled(false)
            console.log(err);            
            Swal.fire({
              icon: "error",
              title: "error",
              text: `${err?.response?.data[0]?.message}`,
            });
        }).finally(()=>{
          setIsDisabled(false)
            setIsDisabled(false)
        })
};



  // useEffect(() => {
  //   const cancelTokenSources = [];
  //   const getList = async () =>{
  //     setAvailableLoading(true)
  //     cancelTokenSources.forEach(source => {
  //       source.cancel('New request made');
  //     });
  
  //     const source = axios.CancelToken.source();
  //     cancelTokenSources.push(source);

  //     try {
  //       const res = await GetProgramAssignedOrNotAssignedListCancelToken({ PId: id, type: "available" }, source.token);
  //       if (res.status === 200) {
  //         setAvailableLoading(false);
  //         setAvailableData(res.data.programs)
  //       }
  //     } catch (error) {
  //       if (!axios.isCancel(error)) {
  //         console.error(error);
  //         setAvailableLoading(false)
  //       }
  //     }
  //   }
    
  //   getList();

  //   return () => {
  //     cancelTokenSources.forEach(source => {
  //       source.cancel('Component unmounted');
  //     });
  //   };
  // }, [refresh]);

  useEffect(() => {
    const cancelTokenSources = [];

    const getListOfuser = async () => {
      setLoading(true)
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      // setCancelTokenSources([...cancelTokenSources, source]);
      cancelTokenSources.push(source);
  
      const apiData = {
        page: tableState.page,
        limit: tableState.perPage,
        key: "order_number",
        sort: tableState.sortOrder,
        search: search,
        programme_id: id,
        exportStatus: "true"
      };
  
      try {
        const res = await GetProgrammeStudyPathList(apiData);

        if (res.status == 200) {
          setStudyPathData(res.data?.data)
          const formatData = res.data?.data.map(
            (data) => {
                return {
                    ...data,
                    // type:data.type,
                    title:data ? <RenderTitle data={data}/> : "N/A",
                    // keywords : data.questions[0] && data.questions[0].keywords ? JSON.parse(data.questions[0].keywords) : []                             
                }
            }
          )
          setRenderData(formatData)
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    }

   getListOfuser();

   return () => {
    cancelTokenSources.forEach(source => {
      source.cancel('Component unmounted');
    });
  };
  }, [ search,refresh ]);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["pmview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  const handlerefresh = () => {
    setRefresh(!refresh);
  };

  const columns = useMemo(() => [
    {
      name: "No.",
      selector: "id",
      sortable: true,
      cell: (row) => (
        <span>{row.id}</span>
      ),
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => (
        <div className="ticket-id">
          {row.name ? <span title={row.name} className="overflow-ellipsis2 as-text-blue">
            {TrimText(row.name, 25)}
          </span> : "-"}
        </div>
      ),
    },

    {
      name: "Core Modules",
      selector: "core_module",
      sortable: true,
      cell: (row) => 
        row.core_module.map((d,i) => (
          <div className="d-flex gap-4 align-items-center">
          <span className="study-path-dot"></span>
          <div className="as-text-blue">{d.name}</div>
          </div>
        )
      ),
    },
    {
      name: "Core Credits",
      selector: "core_credits",
      sortable: true,
      cell: (row) => row.core_credits ? <div title={row.core_credits}>{row.core_credits}</div> : "-"
    },
    {
      name: "Foundational Modules",
      selector: "foundation_module",
      sortable: true,
      cell: (row) =>(
        row.foundation_module.map((d,i) => (
          <div className="d-flex gap-4 align-items-center">
          <span className="study-path-dot"></span>
          <div className="as-text-blue">{d.name}</div>
          </div>
        ))
      )
    },
    {
      name: "Foundational Credits",
      selector: "foundational_credits",
      sortable: true,
      cell: (row) => row?.foundational_credits ? row.foundational_credits : "-",
    },
    {
      name: "Elective Modules",
      selector: "elective_module",
      sortable: true,
      cell: (row) => (
        row.elective_module.map((d,i) => (
          <div className="d-flex gap-4 align-items-center">
          <span className="study-path-dot"></span>
          <div className="as-text-blue">{d.name}</div>
          </div>
      ))),
    },
    {
      name: "Elective Credits",
      selector: "elective_credits",
      sortable: true,
      cell: (row) => (row.elective_credits ? <div title={row.elective_credits}>{row.elective_credits}</div> : "-")
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <PermissionsGate
              scopes={["pmcpmedit"]}
              RenderError={() => (
                <button
                  className="btn btn-primary rounded-circle"
                  title="Open"
                  disabled
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
            >
              <Link
                 to={`/program-management-center/programmes/study_path/open/${id}/${row.id}`}
                className="btn btn-primary rounded-circle"
                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
            <PermissionsGate
              scopes={["pmdelete"]}
              RenderError={() => (
                <button
                  className="btn btn-danger rounded-circle"
                  title="Delete"
                  disabled
                >
                  <i className="fal fa-trash-alt"></i>
                </button>
              )}
            >
              <button
                className="btn btn-danger rounded-circle"
                title="Delete"
                onClick={() => handleDelete(row.id)}
              >
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteProgrammeStudyPath({ id: ID })
          .then((res) => {
            Swal.fire("Deleted!", "Your module has been removed.", "success");
            handlerefresh();
          })
          .catch((error) => console.log(error));
      }
    });
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = data;
    if (search.length) {
      let tempCode = allData.filter((item) => {
        let includes = item.Code && item.Code.toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let temp = allData.filter((item) => {
        let includes = item.Portal && item.Portal.toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempDuration = allData.filter((item) => {
        let includes = item.Duration && item.Duration.toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempTopics = allData.filter((item) => {
        let includes = item.topics && item.topics
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempProgramme = allData.filter((item) => {
        let includes = item.Programmes && item.Programmes.toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempMname = allData.filter((item) => {
        let includes =
          item.Marketing_Name &&
          item.Marketing_Name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempType = allData.filter((item) => {
        let includes =
          item.Module_Type &&
          item.Module_Type.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempdata = [
        ...temp,
        ...tempTopics,
        ...tempProgramme,
        ...tempType,
        ...tempDuration,
        // ...tempId,
        ...tempCode,
        ...tempMname,
      ];
      let unique = [...new Set(tempdata)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (duration.arr.length) {
      let tempData = updatedData;
      let tempSearch = tempData.filter((item) => {
        const startsWith = duration.arr.find((weeks) => {
          if (weeks === "1-4") {
            if (Number(item.Duration) > 1 && Number(item.Duration) <= 4)
              return item;
          }
          if (weeks === "4-8") {
            if (Number(item.Duration) > 4 && Number(item.Duration) <= 8)
              return item;
          }
          if (weeks === "8-12") {
            if (Number(item.Duration) > 8 && Number(item.Duration) <= 12)
              return item;
          }
          if (weeks === "> 12") {
            if (Number(item.Duration) > 12) return item;
          }
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }
    if (moduleType.arr.length) {
      let tempStudyType = updatedData;
      let tempSearch = tempStudyType.filter((item) => {
        const startsWith = moduleType.arr.find(function (post, index) {
          if (
            post.toLowerCase() ==
            (item.Module_Type ? item.Module_Type.toLowerCase() : "")
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }
    if (search.length || duration.arr.length || moduleType.arr.length) {
      return updatedData;
    } else {
      return data;
    }
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setSearch("");
    setModuleType({ arr: [], checkObj: {} })
    setDuration({ arr: [], checkObj: {} })
  };

  const exportData = (fileType, fileName) => {
    let data;
    const header = ["No.","Name", "Core Modules","Core Credits","Foundational Modules","Foundational Credits","Elective Modules","Elective Credits"];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    const cData = {
      page: tableState.page,
      limit: tableState.perPage,
      key: tableState.sortKey,
      sort: tableState.sortOrder,
      search: search,
      exportStatus: "true",
      programme_id : id
    }
    GetProgrammeStudyPathList(cData).then(res => {
      data = res.data?.data;
      data = data?.map((row, ind) => ({
        ...row,
        "No.":row.order_number ? row.order_number : "-",
        "Name": row.name ? row.name : "-",
        "Core Modules" : row.core_module.length ? 
        row.core_module.map((d,i) => (
          d.name
        )) : "-",
        "Core Credits": row.core_credits ? row.core_credits : "-",
        "Foundational Modules" : row.foundation_module.length ? 
        row.foundation_module.map((d,i) => (
          d.name
        )) : "-",
        "Foundational Credits" : row.foundational_credits ? row.foundational_credits : "-",
        "Elective Modules" : row.elective_module.length ? 
        row.elective_module.map((d,i) => (
          d.name
        )) : "-",
        "Elective Credits" : row.elective_credits ? row.elective_credits : "-",
      }));
      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        // console.log(csvString);
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
        anchor.click();

        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.map((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        // Returning false as downloading of file is already taken care of
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [row["No."],row["Name"], row["Core Modules"],row["Core Credits"],row["Foundational Modules"],row["Foundational Credits"],row["Elective Modules"],row["Elective Credits"]];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            color : "white",
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }

    }).catch(error => {
      console.log("error -----> ", error);
    })




  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="custom-table-div filter-search-icon card card-table-custom">
      <h5 className="mb-4 fw-bold phase-color">Phases</h5>
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={search}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                {/*end of learning method*/}
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={() => resetFilter()}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                </div>
                <div className="files-export-group">
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("xlsx", "Study Path");
                    }}
                    title="Export spreadsheet"
                  >
                    <i className="fal fa-file-excel icon"></i>
                  </button>

                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("csv", "Study Path");
                    }}
                    title="Export CSV"
                  >
                    <i className="fal fa-file-csv icon"></i>
                  </button>

                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("pdf", "Study Path");
                    }}
                    title="Export PDF"
                  >
                    <i className="fal fa-file-pdf icon"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk gap-3">
              <div className="add-ticket-blk button-reset dropdown-comman">
                  <button
                    className="btn btn-primary"
                    title="Re-Arrange"
                    onClick={() => {history.push(`/program-management-center/programmes/study_path/table/${id}`)}}
                  >
                    <i className="fal fa-random"></i>Re-Arrange
                  </button>
              </div>
              <div className="add-ticket-blk button-reset dropdown-comman">
                  <button
                    className="btn btn-primary"
                    // data-toggle="modal"
                    // data-target="#viewCourses"
                    title="Create New"
                    onClick={() => {history.push(`/program-management-center/programmes/study_path/create/${id}`)}}
                    >
                    <i className="fal fa-plus"></i>Create New
                  </button>
              </div>
            </div>

          </div>
        </div>
        <div className="tree-wrapper">
                    {loading ? (
                        <SkeletonTicketList />
                    ) : (
                        <SortableTree
                            theme={TreeTheme}
                            className="my-class"
                            isVirtualized={false}
                            treeData={renderData ? renderData : []}
                            onChange={(treeData) => handleChangeData(treeData)}
                            maxDepth={2}
                            onDragStateChanged={(dragData) => handleDragNode(dragData)}
                            style={{
                                padding: "0px",
                                width: "100%",
                            }}
                            canNodeHaveChildren = {(node)=>(node.type===1)}
                            generateNodeProps={(rowInfo) => {
                                // console.log(">>>>>>>>>>>>>>>>>>>>>>",rowInfo);
                                const { node, parentNode } = rowInfo;
                                return {
                                    // canDrag :node.type===0 ? true : false,
                                    canDrop :node.type===0 ? true : false,
                                    class:
                                        node.type === 1
                                            ? "main-category-node just1"
                                            : node.type === 0
                                                ? "sub-category-node just1"
                                                : "item-node just1",
                                    buttons: [
                                        <>
                                        <div className="drag-option-container"> 
                                                
                                                <div className="assessment-08 btn-dropdown-grp">
                                                    <div className="as-buttons">
                                                        <PermissionsGate
                                                        scopes={["pmcpmedit"]}
                                                        RenderError={() => (
                                                            <button
                                                            className="btn btn-primary rounded-circle"
                                                            title="Open"
                                                            disabled
                                                            >
                                                            <i className="fal fa-folder-open"></i>
                                                            </button>
                                                        )}
                                                        >
                                                        <Link
                                                             to={`/program-management-center/programmes/study_path/open/${id}/${rowInfo.node.id}`}
                                                            className="btn btn-primary rounded-circle"
                                                            title="Open"
                                                            >
                                                            <i className="fal fa-folder-open"></i>
                                                        </Link>
                                                        </PermissionsGate>
                                                        <PermissionsGate
                                                            scopes={["pmcstudypathdelete"]}
                                                            RenderError={() => (
                                                              <button
                                                                className="btn btn-danger rounded-circle"
                                                                title="Delete"
                                                                disabled
                                                              >
                                                                <i className="fal fa-trash-alt"></i>
                                                              </button>
                                                            )}
                                                          >
                                                        <button
                                                            className="btn btn-danger rounded-circle"
                                                            title="Delete"
                                                            onClick={() => handleDelete(rowInfo.node?.id)}
                                                            >
                                                            <i className="fal fa-trash-alt"></i>
                                                        </button>
                                                        </PermissionsGate>
                                                    </div>
                                                    </div>                           
                                            </div>
                                                            </>
                                    ],
                                    style: { borderRadius: "10px", width: "100%" },
                                };
                            }}
                            placeholderRenderer={Placeholder}
                        />
                    )}
                </div>
      <div className="form-group form-group-save-cancel mt-3 mb-0">
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                        handleSaveData();
                    }}
                    title="Save"
                    disabled={isDisabled}
                >
                    {isDisabled === false ? <i className="fal fa-save"></i> : <i className="fas fa-cog fa-spin"></i>}
                    Save
                </button>
                <button
                    className="btn btn-close btn-danger"
                    type="button"
                    title="Cancel"
                    onClick={() => (setRefresh(!refresh))}
                >
                    <i className="fal fa-times"></i>Cancel
                </button>
            </div>
      </div>
    </div>
  );
};

export default StudyPathRearrange;

const RenderTitle = ({data}) => {
    return (
        <div className="d-flex justify-content-between">
        <p className="assembly-list-title" title={data.name}>
         <i className="fal fa-list mr-2"></i>
        <span className="as-text-blue">
            {TrimText(data.name)}    
        </span>
      </p>
      <div className="d-flex gap-5">

      <span>
        <span className="fw-bold fs-14">Core Modules: </span> <br/> <span className="fs-14">{data.core_module?.length} Modules {data.core_credits} Credits</span>
      </span>
      <span>
        <span className="fw-bold fs-14">Foundational Modules: </span> <br/> <span className="fs-14">{data.foundation_module?.length} Modules {data.foundational_credits} Credits</span>
      </span>
      <span>
        <span className="fw-bold fs-14">Elective Modules: </span> <br/> <span className="fs-14">{data.elective_module?.length} Modules {data.elective_credits} Credits</span>
      </span>
      </div>
        </div>
    )
}