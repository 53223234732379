import React, { useMemo, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";

import {
  DeleteProgrammeStudyPath,
  getCommonContent,
  GetProgramAssignedOrNotAssignedList,
  GetProgramAssignedOrNotAssignedListCancelToken,
  GetProgrammeStudyPathList,
  RemoveModuleFromProgramme,
} from "../../../services/ProgramService";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import PermissionsGate from "../../../utils/permissionGate";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import { RenderModuleType } from "../../../utils/CommonGroupingItem";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";
import Str from "../../common/Str";
import { TrimText } from "../../common/TrimText";
import AvailableTables from "./ProgramDetailBlock/AvailableTables";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";

const StudyPath = () => {
  const history = useHistory();
  const { id,type } = useParams();
  const [search, setSearch] = useState("");

  const [duration, setDuration] = useState({arr:[], checkObj:{}});
  const [moduleType, setModuleType] = useState({ arr: [], checkObj: {} })
  const [filterData, setFilterData] = useState([]);
  const [data, setData] = useState(); 
  const [availableData, setAvailableData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availableLoading, setAvailableLoading] = useState(false);
  const [studyPathData,setStudyPathData] = useState([]);
  const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0)
    const [sortkey, setSortKey] = useState("order_number");
    const [sortOrder, setSortOrder] = useState("ASC");
    const [tableState, setTableState] = useState({
        page: 1,
        perPage: 10,
        sortKey: "name",
        sortOrder: "DESC"
    })
    const [expandedRows1, setExpandedRows1] = useState([]);
    const [expandedRows2, setExpandedRows2] = useState([]);
    const [expandedRows3, setExpandedRows3] = useState([]);



  useEffect(() => {
    handleTableScroll()
  }, [loading])


  useEffect(() => {
    const filterSource = axios.CancelToken.source();
    const getFilters = async () =>{
      try {
        const res = await getCommonContent(filterSource.token);
        if(res.status==200){
          setFilterData(res?.data.type_course);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);          
        }
      }
    }
    
    getFilters();
    return () => {
      filterSource.cancel('Component unmounted');      
    };
  }, []);

  useEffect(() => {
      const cancelTokenSources = [];

      const getProgramsList = async () => {
        setLoading(true)
    
        cancelTokenSources.forEach(source => {
          source.cancel('New request made');
        });
    
        const source = axios.CancelToken.source();
        cancelTokenSources.push(source);

        try {
          const res = await GetProgramAssignedOrNotAssignedListCancelToken({ PId: id, type: "assigned" }, source.token);
          if (res.status === 200) {
            setLoading(false)
            setData(res.data.programs);
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
            setLoading(false)
          }
        }
      };
    
      getProgramsList();
  
      return () => {
        cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
        });
      };
  }, [refresh]);
  // useEffect(() => {
  //   const cancelTokenSources = [];
  //   const getList = async () =>{
  //     setAvailableLoading(true)
  //     cancelTokenSources.forEach(source => {
  //       source.cancel('New request made');
  //     });
  
  //     const source = axios.CancelToken.source();
  //     cancelTokenSources.push(source);

  //     try {
  //       const res = await GetProgramAssignedOrNotAssignedListCancelToken({ PId: id, type: "available" }, source.token);
  //       if (res.status === 200) {
  //         setAvailableLoading(false);
  //         setAvailableData(res.data.programs)
  //       }
  //     } catch (error) {
  //       if (!axios.isCancel(error)) {
  //         console.error(error);
  //         setAvailableLoading(false)
  //       }
  //     }
  //   }
    
  //   getList();

  //   return () => {
  //     cancelTokenSources.forEach(source => {
  //       source.cancel('Component unmounted');
  //     });
  //   };
  // }, [refresh]);

  useEffect(() => {
    const cancelTokenSources = [];

    const getListOfuser = async () => {
      setLoading(true)
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      // setCancelTokenSources([...cancelTokenSources, source]);
      cancelTokenSources.push(source);
  
      const apiData = {
        page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
        search: search,
        exportStatus: "false",
        programme_id:id
      };
      
  
      try {
        const res = await GetProgrammeStudyPathList(apiData);

        if (res.status == 200) {
          setStudyPathData(res.data?.data.data)
          setTotalRows(res.data?.data.total);
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    }

   getListOfuser();

   return () => {
    cancelTokenSources.forEach(source => {
      source.cancel('Component unmounted');
    });
  };
  }, [ search,perPage,sortOrder,sortkey,tableState,page,totalRows,refresh ]);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["pmcstudypathview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  const handlerefresh = () => {
    setRefresh(!refresh);
  };

  const columns = useMemo(() => [
    {
      name: "No.",
      selector: "order_number",
      sortField : "order_number",
      sortable: true,
      cell: (row,i) => <span>{row.order_number}</span>,
    },
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => (
        

        <div className="ticket-id">
          {row.name ? (
            <Link
            to={`/program-management-center/programmes/study_path/open/${id}/${row.id}`}
            className="as-text-blue curser feature-name"
            title={row.name}
            >
            <span className="textLimit100">{row?.name}</span>
          </Link>
          ) : (
            "-"
          )}
        </div>
      ),
    },

    {
      name: "Core Modules",
      selector: "core_module",
      sortable: false,
      cell: (row) => {
        const module = row.core_module || [];
        const showLimit = 2;
        const shouldDisplaySeeMore = module.length > showLimit;

        const toggleExpanded = (rowId) => {
          if (expandedRows1.includes(rowId)) {
            setExpandedRows1(expandedRows1.filter((id) => id !== rowId));
          } else {
            setExpandedRows1([...expandedRows1, rowId]);
          }
        };

        return (
          <>
            <>
              {(module.length > 3 ? module.slice(0, showLimit) : module).map(
                (item, index) => (
                  <li key={index} className="as-text-blue cursor">
                    <Link
                      to={`/program-management-center/modules/module/open/${item.Id}`}
                      className="as-text-blue cursor"
                      title={item.name}
                    >
                      {TrimText(item.name, 25)}
                    </Link>
                  </li>
                )
              )}
              {module.length > 3 && !expandedRows1.includes(row.id) && (
                <span
                  className="see-more cursor-pointer as-text-blue"
                  onClick={() => toggleExpanded(row.id)}
                >
                  See more...
                </span>
              )}
              {expandedRows1.includes(row.id) && (
                <>
                  {module.slice(showLimit).map((item, index) => (
                    <li key={index} className="as-text-blue cursor">
                      <Link
                        to={`/program-management-center/modules/module/open/${item.Id}`}
                        className="as-text-blue cursor"
                        title={item.name}
                      >
                        {TrimText(item.name, 25)}
                      </Link>
                    </li>
                  ))}
                  <span
                    className="see-less cursor-pointer as-text-blue"
                    onClick={() => toggleExpanded(row.id)}
                  >
                    See less...
                  </span>
                </>
              )}
              {module.length === 0 && (
                <span className="articulation-programme-status">N/A</span>
              )}
            </>
          </>
        );
      },
    },
    {
      name: "Core Credits",
      selector: "core_credits",
      sortField: "core_credits",
      sortable: true,
      cell: (row) =>
        row.core_credits ? (
          <div title={row.core_credits}>{row.core_credits}</div>
        ) : (
          "-"
        ),
    },
    {
      name: "Foundational Modules",
      selector: "foundation_module",
      sortable: false,
      cell: (row) => {
        const module = row.foundation_module || [];
        const showLimit = 2;
        const shouldDisplaySeeMore = module.length > showLimit;

        const toggleExpanded = (rowId) => {
          if (expandedRows2.includes(rowId)) {
            setExpandedRows2(expandedRows2.filter((id) => id !== rowId));
          } else {
            setExpandedRows2([...expandedRows2, rowId]);
          }
        };

        return (
          <>
            <>
              {(module.length > 3 ? module.slice(0, showLimit) : module).map(
                (item, index) => (
                  <li key={index} className="as-text-blue cursor">
                    <Link
                      to={`/program-management-center/modules/module/open/${item.Id}`}
                      className="as-text-blue cursor"
                      title={item.name}
                    >
                      {TrimText(item.name, 25)}
                    </Link>
                  </li>
                )
              )}
              {module.length > 3 && !expandedRows2.includes(row.id) && (
                <span
                  className="see-more cursor-pointer as-text-blue"
                  onClick={() => toggleExpanded(row.id)}
                >
                  See more...
                </span>
              )}
              {expandedRows2.includes(row.id) && (
                <>
                  {module.slice(showLimit).map((item, index) => (
                    <li key={index} className="as-text-blue cursor">
                      <Link
                        to={`/program-management-center/modules/module/open/${item.Id}`}
                        className="as-text-blue cursor"
                        title={item.name}
                      >
                        {TrimText(item.name, 25)}
                      </Link>
                    </li>
                  ))}
                  <span
                    className="see-less cursor-pointer as-text-blue"
                    onClick={() => toggleExpanded(row.id)}
                  >
                    See less...
                  </span>
                </>
              )}
              {module.length === 0 && (
                <span className="articulation-programme-status">N/A</span>
              )}
            </>
          </>
        );
      },
    },
    {
      name: "Foundational Credits",
      selector: "foundational_credits",
      sortField: "foundational_credits",
      sortable: true,
      cell: (row) =>
        row?.foundational_credits ? row.foundational_credits : "-",
    },
    {
      name: "Elective Modules",
      selector: "elective_module",
      sortable: false,
      cell: (row) => {
        const module = row.elective_module || [];
        const showLimit = 2;
        const shouldDisplaySeeMore = module.length > showLimit;

        const toggleExpanded = (rowId) => {
          if (expandedRows3.includes(rowId)) {
            setExpandedRows3(expandedRows3.filter((id) => id !== rowId));
          } else {
            setExpandedRows3([...expandedRows3, rowId]);
          }
        };

        return (
          <>
            <>
              {(module.length > 3 ? module.slice(0, showLimit) : module).map(
                (item, index) => (
                  <li key={index} className="as-text-blue cursor">
                    <Link
                      to={`/program-management-center/modules/module/open/${item.Id}`}
                      className="as-text-blue cursor"
                      title={item.name}
                    >
                      {TrimText(item.name, 25)}
                    </Link>
                  </li>
                )
              )}
              {module.length > 3 && !expandedRows3.includes(row.id) && (
                <span
                  className="see-more cursor-pointer as-text-blue"
                  onClick={() => toggleExpanded(row.id)}
                >
                  See more...
                </span>
              )}
              {expandedRows3.includes(row.id) && (
                <>
                  {module.slice(showLimit).map((item, index) => (
                    <li key={index} className="as-text-blue cursor">
                      <Link
                        to={`/program-management-center/modules/module/open/${item.Id}`}
                        className="as-text-blue cursor"
                        title={item.name}
                      >
                        {TrimText(item.name, 25)}
                      </Link>
                    </li>
                  ))}
                  <span
                    className="see-less cursor-pointer as-text-blue"
                    onClick={() => toggleExpanded(row.id)}
                  >
                    See less...
                  </span>
                </>
              )}
              {module.length === 0 && (
                <span className="articulation-programme-status">N/A</span>
              )}
            </>
          </>
        );
      },
    },
    {
      name: "Elective Credits",
      selector: "elective_credits",
      sortField: "elective_credits",
      sortable: true,
      cell: (row) =>
        row.elective_credits ? (
          <div title={row.elective_credits}>{row.elective_credits}</div>
        ) : (
          "-"
        ),
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            
              <Link
                to={`/program-management-center/programmes/study_path/open/${id}/${row.id}`}
                className="btn btn-primary rounded-circle"
                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            <PermissionsGate
              scopes={["pmcstudypathdelete"]}
              RenderError={() => (
                <button
                  className="btn btn-danger rounded-circle"
                  title="Delete"
                  disabled
                >
                  <i className="fal fa-trash-alt"></i>
                </button>
              )}
            >
              <button
                className="btn btn-danger rounded-circle"
                title="Delete"
                onClick={() => handleDelete(row.id)}
              >
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteProgrammeStudyPath({ id: ID })
          .then((res) => {
            Swal.fire("Deleted!", "Your module has been removed.", "success");
            handlerefresh();
          })
          .catch((error) => console.log(error));
      }
    });
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = data;
    if (search.length) {
      let tempCode = allData.filter((item) => {
        let includes = item.Code && item.Code.toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let temp = allData.filter((item) => {
        let includes = item.Portal && item.Portal.toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempDuration = allData.filter((item) => {
        let includes = item.Duration && item.Duration.toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempTopics = allData.filter((item) => {
        let includes = item.topics && item.topics
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempProgramme = allData.filter((item) => {
        let includes = item.Programmes && item.Programmes.toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempMname = allData.filter((item) => {
        let includes =
          item.Marketing_Name &&
          item.Marketing_Name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempType = allData.filter((item) => {
        let includes =
          item.Module_Type &&
          item.Module_Type.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempdata = [
        ...temp,
        ...tempTopics,
        ...tempProgramme,
        ...tempType,
        ...tempDuration,
        // ...tempId,
        ...tempCode,
        ...tempMname,
      ];
      let unique = [...new Set(tempdata)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (duration.arr.length) {
      let tempData = updatedData;
      let tempSearch = tempData.filter((item) => {
        const startsWith = duration.arr.find((weeks) => {
          if (weeks === "1-4") {
            if (Number(item.Duration) > 1 && Number(item.Duration) <= 4)
              return item;
          }
          if (weeks === "4-8") {
            if (Number(item.Duration) > 4 && Number(item.Duration) <= 8)
              return item;
          }
          if (weeks === "8-12") {
            if (Number(item.Duration) > 8 && Number(item.Duration) <= 12)
              return item;
          }
          if (weeks === "> 12") {
            if (Number(item.Duration) > 12) return item;
          }
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }
    if (moduleType.arr.length) {
      let tempStudyType = updatedData;
      let tempSearch = tempStudyType.filter((item) => {
        const startsWith = moduleType.arr.find(function (post, index) {
          if (
            post.toLowerCase() ==
            (item.Module_Type ? item.Module_Type.toLowerCase() : "")
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }
    if (search.length || duration.arr.length || moduleType.arr.length) {
      return updatedData;
    } else {
      return data;
    }
  };
  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setSearch("");
    setModuleType({ arr: [], checkObj: {} })
    setDuration({ arr: [], checkObj: {} })
  };

  const exportData = (fileType, fileName) => {
    let data;
    const header = ["No.","Name", "Core Modules","Core Credits","Foundational Modules","Foundational Credits","Elective Modules","Elective Credits"];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    const cData = {
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search,
      exportStatus: "true",
      programme_id : id
    }
    GetProgrammeStudyPathList(cData).then(res => {
      data = res.data?.data;
      data = data?.map((row, ind) => ({
        ...row,
        "No.":row.order_number ? row.order_number : "-",
        "Name": row.name ? row.name : "-",
        "Core Modules" : row.core_module.length ? 
        row.core_module.map((d,i) => (
          d.name
        )) : "-",
        "Core Credits": row.core_credits ? row.core_credits : "-",
        "Foundational Modules" : row.foundation_module.length ? 
        row.foundation_module.map((d,i) => (
          d.name
        )) : "-",
        "Foundational Credits" : row.foundational_credits ? row.foundational_credits : "-",
        "Elective Modules" : row.elective_module.length ? 
        row.elective_module.map((d,i) => (
          d.name
        )) : "-",
        "Elective Credits" : row.elective_credits ? row.elective_credits : "-",
      }));
      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        // console.log(csvString);
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
        anchor.click();

        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.map((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        // Returning false as downloading of file is already taken care of
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [row["No."],row["Name"], row["Core Modules"],row["Core Credits"],row["Foundational Modules"],row["Foundational Credits"],row["Elective Modules"],row["Elective Credits"]];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            color : "white",
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }

    }).catch(error => {
      console.log("error -----> ", error);
    })




  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="custom-table-div filter-search-icon card card-table-custom">
      <h5 className="mb-4 fw-bold phase-color">Phases</h5>
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={search}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                {/*end of learning method*/}
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={() => resetFilter()}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                </div>
                <div className="files-export-group">
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("xlsx", "Study Path");
                    }}
                    title="Export spreadsheet"
                  >
                    <i className="fal fa-file-excel icon"></i>
                  </button>

                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("csv", "Study Path");
                    }}
                    title="Export CSV"
                  >
                    <i className="fal fa-file-csv icon"></i>
                  </button>

                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("pdf", "Study Path");
                    }}
                    title="Export PDF"
                  >
                    <i className="fal fa-file-pdf icon"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk gap-3">
              <div className="add-ticket-blk button-reset dropdown-comman">
                  <button
                    className="btn btn-primary"
                    // data-toggle="modal"
                    // data-target="#viewCourses"
                    title="Re-Arrange"
                    onClick={() => {history.push(`/program-management-center/programmes/study_path/re-arrange/${id}`)}}
                  >
                    <i className="fal fa-random"></i>Re-Arrange
                  </button>
              </div>
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate 
                scopes={["pmcstudypathadd"]}
                RenderError={() => (
                  <button
                    className="btn btn-primary"
                    title="Open"
                    disabled
                  >
                    <i className="fal fa-plus"></i> Create New
                  </button>
                )}
                >

                  <button
                    className="btn btn-primary"
                    // data-toggle="modal"
                    // data-target="#viewCourses"
                    title="Create New"
                    onClick={() => {history.push(`/program-management-center/programmes/study_path/create/${id}`)}}
                    >
                    <i className="fal fa-plus"></i>Create New
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
        {/* {loading ? <SkeletonTicketList /> */}
          <DataTable
            data={studyPathData}
            defaultSortField="order_number"
            onSort={handleSort}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            defaultSortAsc={true}
            sortServer={true}
            paginationServer={true}
            paginationTotalRows={totalRows}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            progressPending={loading}
            progressComponent={<SkeletonTicketList />}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
      </div>
      {/* Modal to show table of child courses */}
      <div
        className="topic-add-modal modal fade"
        id="viewCourses"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fal fa-plus"></i> Assign Module</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
            <div className="modal-body">
              <AvailableTables
                data={availableData}
                refresh={handlerefresh}
                id={id}
                filterData={filterData}
                loading={availableLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyPath;
