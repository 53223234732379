import axiosInstance from "../utils/axios";

export const getMenusItemsList = async (data) => {
    return await axiosInstance().post('/getMenusItemsList', data)
}

export const UpdateMenuItems = async (data) => {
    return await axiosInstance().post(`/updateMenuItem`, data)
}

export const getMenusItemsDropdownlist = async () => {
    return await axiosInstance().get(`/getMenuItemDropDownList`)
}

export const getMenuDropdown = async () => {
    return await axiosInstance().get(`/getMenuDropDownList`)
}

export const getMenuItemsTypeDropdown = async () => {
    return await axiosInstance().get(`/getMenuItemsTypesList`)
}

export const getMenuItemssub_menuDropdown = async (id) => {
    return await axiosInstance().get(`/getSubMenusList?MenuID=${id}`)
}

export const getMenuItemsColorDropdown = async (id) => {
    return await axiosInstance().get(`/getAccentColorMenusItemsList?MenuID=${id}`)
}

export const GetMenuListCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getMenusList`, values, {
        cancelToken: cancelToken
    });
};

export const GetMenusListsFilterCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/getMenuFiltersList`, {
        cancelToken: cancelToken
    });
};

export const DeleteMenuList = async (values) => {
    return await axiosInstance().post(`/deleteMenu`, values);
};

export const DeleteMenuItemList = async (values) => {
    return await axiosInstance().post(`/deleteMenu`, values);
};

export const DeleteMenuItemsList = async (id) => {
    return await axiosInstance().post(`/deleteMenuItems?MenuItemID=${id}`);
};

export const GetMenuList = async (data) => {
    return await axiosInstance().post(`/getMenusList`, data);
};

export const GetMenusItemListsFilterCancelToken = async (id , assigned) => {
    return await axiosInstance().get(`/getMenuItemFiltersList?isAssigned=${assigned}&menu_id=${id}`);
};

export const AddMenus = async (data) => {
    return await axiosInstance().post(`/createMenu`, data)
}

export const AddMenusItem = async (data) => {
    return await axiosInstance().post(`/createMenuItem`, data)
}

export const UpdateMenus = async (data) => {
    return await axiosInstance().post(`/updateMenu`, data)
}

export const GetUpdateMenus = async (id) => {
    return await axiosInstance().get(`/getMenuDetails?MenuID=${id}`)
}

export const GetUpdateMenuItems = async (id) => {
    return await axiosInstance().get(`/getMenuItemsDetails?MenuItemID=${id}`)
}

export const getMenuItemLandingPageList = async (id) => {
    return await axiosInstance().get(`/getMenuItemLandingPageList?menus_items_id=${id}`)
}

export const getMenuItemLandingPageListFilters = async (id) => {
    return await axiosInstance().get(`/getMenuItemLandingPageListFilters?menus_items_id=${id}`)
}

export const getCategoryLandingColorList = async (values) => {
    return await axiosInstance().post(`/getAccentColorBaseOnBrandTmpList` , values)
}


