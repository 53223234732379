import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import hasPermission from "../../../utils/hasMultiplePermission";
import Tablefilter from "../../common/Tablefilter";
import { RenderQuizTopic, RenderstatusList, RenderWebsitelistStatus } from "../../../utils/CommonStatusItems";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";
import $ from "jquery";
import { DeleteMenuList, GetMenuList, GetMenusListsFilterCancelToken, GetMenuListCancelToken } from "../../../services/Menusserver";
import { RenderMenuType } from "../../../utils/CommonGroupingItem";
import { DeleteTestimonial, GetTestimonial, GetTestimonialCancelToken, GetTestimonialFilterCancelToken } from "../../../services/TestimonialsServer";

function LayoutTestimonials() {

    const history = useHistory();
    const [search, setSearch] = useState("");
    const [debouncedTerm, setDebouncedTerm] = useState("");
    const [status, setStatus] = useState({ arr: [], checkObj: {} });
    const [searchStatusCheck, setSearchStatusCheck] = useState({});
    const givenPermsisions = useSelector((state) => state.givenPermission);
    const [deleterow, setDeleteRow] = useState(false);
    const [menuData, setMenuData] = useState([]);
    const [loading, setloading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [updateData, setUpdateData] = useState(true);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [sortkey, setSortKey] = useState("title");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [filterData, setFilterData] = useState({ status: [] });


    useEffect(() => {
        handleTableScroll()
    }, [loading])

    useEffect(() => {
        $(document).ready(function () {
            $(".dropdown-toggle").click(function () {
                $('.rdt_TableCell').css('z-index', 0)
                $(this).parents('.rdt_TableCell').css('z-index', 22)
            });
        });
    })

    useEffect(() => {
        const storedFilters = JSON.parse(localStorage.getItem("filterStateMenuListMain"));
        if (storedFilters) {
            setStatus(storedFilters.status);
        }
    }, []);

    useEffect(() => {
        const filterState = {
            status,
        };
        localStorage.setItem("filterStateMenuListMain", JSON.stringify(filterState));
    }, [status]);

    useEffect(() => {
        $(document).ready(function () {
            $(".dropdown-toggle").click(function () {
                $('.rdt_TableCell').css('z-index', 0)
                $(this).parents('.rdt_TableCell').css('z-index', 22)
            });
        });
    })

    // get list
    useEffect(() => {
        const cancelTokenSources = [];

        const getMenuList = async () => {
            setloading(true);

            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });

            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);

            const menuData = {
                key: sortkey,
                sort: sortOrder,
                page: page,
                perPage: perPage,
                search: search,
                viaStatus: status.arr,
            };

            try {
                const res = await GetTestimonialCancelToken(menuData, source.token);
                // console.log(res?.data?.data);
                setMenuData(res?.data?.data);
                setTotalRows(res.data?.total);
                setPerPage(res.data?.perPage);
                if (res.status == 200) {
                    setloading(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    setloading(false);
                }
            }
        }

        getMenuList();

        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [page, perPage, sortOrder, sortkey, search, status, searchStatusCheck, updateData]);

    // get dropdownlist
    useEffect(() => {
        const dropdownSource = axios.CancelToken.source();

        const fetchData = async () => {
            try {
                const res = await GetTestimonialFilterCancelToken(dropdownSource.token);
                // console.log(res?.data?.statusFilters);

                setFilterData({
                    status: res?.data?.statusFilters
                })
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        };

        fetchData();

        return () => {
            dropdownSource.cancel('Component unmounted');
        };
    }, []);

    useEffect(() => {
        let response = hasPermission({
            scopes: ["wbslntestimonialsview"],
            permissions: givenPermsisions,
        });
        if (!response) {
            history.push("/noaccess");
        }
    }, []);

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setDebouncedTerm(value);
    };

    const resetFilter = () => {
        setStatus({ arr: [], checkObj: {} });
        setSearchStatusCheck({});
        setSearch("");
        setDebouncedTerm("");
    };

    const handlePageChange = (pageNo) => {
        setPage(pageNo);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handleSort = (column, sortDirection) => {
        setSortKey(column.sortField);
        setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
    };

    const handleRedirection = (ID) => {
        if (hasPermission({ scopes: ["sgiview"], permissions: givenPermsisions })) {
            return `/studentAdministration/students/open/${ID}/general`;
        } else if (
            hasPermission({ scopes: ["spiview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/personal`;
        } else if (
            hasPermission({ scopes: ["sedview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/education`;
        } else if (
            hasPermission({ scopes: ["sapiview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/account`;
        } else if (
            hasPermission({ scopes: ["scpview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/coursesAndProgramme`;
        } else if (
            hasPermission({ scopes: ["sasview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/assessments`;
        } else if (
            hasPermission({ scopes: ["snview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/notifications`;
        } else if (
            hasPermission({ scopes: ["scaview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/classAttendance`;
        } else if (
            hasPermission({ scopes: ["slview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/licenses`;
        } else if (
            hasPermission({ scopes: ["scview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/certificates`;
        } else if (
            hasPermission({ scopes: ["saview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/appeals`;
        } else if (
            hasPermission({ scopes: ["stview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/tickets`;
        } else if (
            hasPermission({ scopes: ["snoview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/notes`;
        } else {
            return `/studentAdministration/students/open/${ID}/auditTrail/employee`;
        }
        // history.push(redirectLink)
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteTestimonial(id)
                    .then((res) => {
                        Swal.fire("Deleted!", "Testimonial has been deleted.", "success");
                        setUpdateData(!updateData);
                    })
                    .catch((error) => console.log(error));
            }
        });
    }

    const columns = useMemo(() => [
        {
            name: "Name",
            selector: "title",
            sortField: "title",
            sortable: true,
            // minWidth: "190px",
            cell: (row) => (
                <div>
                    <PermissionsGate scopes={["wbslntestimonialsedit"]} RenderError={() => <p>{row?.title}</p>}>
                        <Link
                            className="as-text-blue curser feature-name"
                            to={`/websiteManagement/layout_navigation/testimonials/testimonial_Information/open/${row.id}`}
                            // to={handleRedirection(row.id)}
                            title={row.title}
                        >
                            <span className="textLimit100">{row.title}</span>
                        </Link>
                    </PermissionsGate>
                </div>
            ),
        },
        {
            name: "Related School",
            selector: "school_name",
            sortField: "school_name",
            sortable: true,
            cell: (row) => (
                <>
                    {row.school_name ? (
                        <span>
                            {RenderMenuType(row.school_name).html}
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "Rating",
            selector: "rating",
            sortField: "rating",
            sortable: true,
            cell: (row) => {
                const rating = row.rating;
                const fullStars = Math.floor(rating);
                const halfStar = rating % 1 >= 0.5;
                const emptyStars = 5 - Math.ceil(rating);

                return (
                    row.rating > 0 ?
                        <div className="rating-stars">
                            {[...Array(fullStars)].map((_) => (
                                <i class="fa fa-sharp fa-solid fa-star"></i>
                            ))}
                            {halfStar && <i class="fa fa-sharp fa-regular fa-star-half-stroke"></i>}
                            {[...Array(emptyStars)].map((_) => (
                                <i className="fal fa-star"></i>
                            ))}
                        </div> : "-"
                );
            },
        },
        {
            name: "Assigned Brand Templates",
            selector: "brandTemplete",
            sortField: "brandTemplete",
            cell: (row) =>
                row?.brandTemplete && row?.brandTemplete.length ? (
                    <div className="d-flex flex-wrap">
                        {row?.brandTemplete.map((item, index) => (
                            <span key={index} className="mr-1">
                                {/* Assuming RenderQuizTopic returns an object with html property */}
                                {RenderQuizTopic(item.brand_name).html}
                            </span>
                        ))}
                    </div>
                ) : (
                    "-"
                ),
        },
        {
            name: "Status",
            selector: "status",
            sortField: "status",
            sortable: true,
            // maxWidth: "120px",
            // minWidth: "120px",
            cell: (row) => (row.status ? RenderstatusList(row.status).html : "-"),
        },
        {
            name: "Actions",
            selector: "",
            // maxWidth: "50px",
            cell: (row) => (
                <div className="assessment-08 btn-dropdown-grp">
                    <div className="as-buttons d-flex">

                        <PermissionsGate scopes={['wbslntestimonialsedit']} RenderError={() => (
                            <button
                                className="btn btn-primary rounded-circle"
                                disabled
                                title="Edit"
                            >
                                <i className="fal fa-folder-open"></i>
                            </button>
                        )}>

                            <Link
                                className="btn btn-primary rounded-circle"
                                to={`/websiteManagement/layout_navigation/testimonials/testimonial_Information/open/${row.id}`}
                                title="Open"
                            >
                                <i className="fal fa-folder-open"></i>
                            </Link>
                        </PermissionsGate>
                        <PermissionsGate scopes={['wbslntestimonialsdelete']} RenderError={() => (
                            <button
                                className="btn btn-danger rounded-circle"
                                disabled
                                title="Delete"
                            >
                                <i className="fal fa-trash-alt"></i>
                            </button>
                        )}>

                            <button
                                className="btn btn-danger rounded-circle"
                                onClick={() => handleDelete(row.id)}
                            >
                                <i className="fal fa-trash-alt"></i>
                            </button>
                        </PermissionsGate>
                    </div>
                </div>
            ),
        },
    ]);

    // TODO Excel
    // TODO Csv
    // TODO Pdf

    const exportData = (fileType, fileName) => {
        let data = [];
        const header = ["Name", "Related School", "Assigned Brand Templates", "Status"];
        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });

        const menuData = {
            exportStatus: "true"
        };

        GetTestimonial(menuData)
            .then((res) => {
                // console.log(res?.data);
                data = res?.data;

                data = data?.map((row) => {

                    const brandTemplate = row.brandTemplete?.map((item) => item.brand_name).join(",");

                    // const rating = row.rating;
                    // const fullStars = Math.floor(rating);
                    // const halfStar = rating % 1 >= 0.5;
                    // const emptyStars = 5 - Math.ceil(rating);

                    return ({
                        ...row,
                        "Name": row?.title,
                        "Related School": row.school_name ? row.school_name : "-",
                        // Rating: rating > 0 ?
                        //     <div className="rating-stars">
                        //         {[...Array(fullStars)].map((_) => (
                        //             <i class="fa fa-sharp fa-solid fa-star"></i>
                        //         ))}
                        //         {halfStar && <i class="fa fa-sharp fa-regular fa-star-half-stroke"></i>}
                        //         {[...Array(emptyStars)].map((_) => (
                        //             <i className="fal fa-star"></i>
                        //         ))}
                        //     </div> : "-",
                        "Assigned Brand Templates": row.brandTemplete ? brandTemplate : "-",
                        Status: row?.status ? RenderWebsitelistStatus(row.status).text
                            : "-",
                    })
                });

                if (fileType === "csv") {
                    const csvString = Papa.unparse({ fields: header, data });
                    const blob = new Blob([csvString], {
                        type: "text/csv;charset=utf-8,",
                    });

                    const blobURL = window.URL.createObjectURL(blob);

                    // Create new tag for download file
                    const anchor = document.createElement("a");
                    anchor.download = fileName;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = [
                        "text/csv",
                        anchor.download,
                        anchor.href,
                    ].join(":");
                    anchor.click();

                    // Remove URL.createObjectURL. The browser should not save the reference to the file.
                    setTimeout(() => {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        URL.revokeObjectURL(blobURL);
                    }, 1000);
                    Swal.close();
                } else if (fileType === "xlsx") {
                    const compatibleData = data.map((row) => {
                        const obj = {};
                        header.map((col, index) => {
                            obj[col] = row[col];
                        });
                        return obj;
                    });

                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                        header,
                    });
                    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                    XLSX.writeFile(wb, `${fileName}.xlsx`);
                    Swal.close();
                    // Returning false as downloading of file is already taken care of
                    return false;
                }
                if (fileType === "pdf") {
                    const compatibleData = data?.map((row) => {

                        const brandTemplate = row.brandTemplete?.map((item) => item.brand_name).join(",");
                        // const rating = row.rating;
                        // const fullStars = Math.floor(rating);
                        // const halfStar = rating % 1 >= 0.5;
                        // const emptyStars = 5 - Math.ceil(rating);

                        return [
                            row.title,
                            row.school_name ? row.school_name : "-",
                            // rating > 0 ?
                            //     <div className="rating-stars">
                            //         {[...Array(fullStars)].map((_) => (
                            //             <i class="fa fa-sharp fa-solid fa-star"></i>
                            //         ))}
                            //         {halfStar && <i class="fa fa-sharp fa-regular fa-star-half-stroke"></i>}
                            //         {[...Array(emptyStars)].map((_) => (
                            //             <i className="fal fa-star"></i>
                            //         ))}
                            //     </div> : "-",
                            row.brandTemplete ? brandTemplate : "-",
                            row.status,
                        ];
                    });
                    const doc = new JsPDF();
                    doc.autoTable({
                        head: [header],
                        body: compatibleData,
                        styles: {
                            minCellHeight: 10,
                            minCellWidth: 5,
                            halign: "left",
                            // valign: "center",
                            fontSize: 8,
                        },
                    });
                    doc.save(`${fileName}.pdf`);
                    Swal.close();
                    return false;
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
        return () => {
            clearTimeout(timer);
        }
    }, [debouncedTerm])

    return (
        <div className="my-tickets-info-list Tickets-main-wrap">
            <div className="custom-table-div filter-search-icon card card-table-custom program-table">
                <div className="search-filter-div">
                    <div className="search-filter-div-left">
                        <div className="system-administration-table table-responsive">
                            <div className="table-responsive-div">
                                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                    <div id="assessment-table-main_filter" className="dataTables_filter">
                                        <label>
                                            <input
                                                type="search"
                                                className=""
                                                placeholder="Search"
                                                aria-controls="assessment-table-main"
                                                onChange={handleSearchFilter}
                                                value={debouncedTerm}
                                            />
                                        </label>
                                        <div className="filter-eff filter-data-btn">
                                            <button className="filter-buttons">
                                                <i className="fal fa-filter"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-button-group">
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner filter-custom-new`}>
                                        <Tablefilter
                                            filterName="Status"
                                            optionArr={filterData?.status}
                                            state={status}
                                            setState={setStatus}
                                            stopOptionSorting={true}
                                            isOptionReversed={false}
                                            renderLabelFunction={RenderstatusList}
                                        />
                                    </div>
                                </div>
                                <div className="reset-btn-group">
                                    <div className="button-reset dropdown-comman">
                                        <button
                                            className="btn btn-primary"
                                            onClick={resetFilter}
                                            title="Reset"
                                        >
                                            <i className="fal fa-redo"></i>Reset
                                        </button>
                                    </div>
                                    <div className="files-export-group">
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("xlsx", "websitelist");
                                            }}
                                            title="Export spreadsheet"
                                        >
                                            <i className="fal fa-file-excel icon"></i>
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("csv", "Students_list");
                                            }}
                                            title="Export CSV"
                                        >
                                            <i className="fal fa-file-csv icon"></i>
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("pdf", "Students_list");
                                            }}
                                            title="Export PDF"
                                        >
                                            <i className="fal fa-file-pdf icon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search-filter-div-right">
                        <div className=" filter-search-bar-blk">
                            <div className="add-ticket-blk button-reset dropdown-comman">
                                <PermissionsGate
                                    RenderError={() => (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            title="Create new"
                                            disabled
                                        >
                                            <i className="fal fa-plus"></i>Create New
                                        </button>
                                    )}
                                    scopes={["wbslntestimonialsadd"]}
                                >
                                    <Link to={`/websiteManagement/layout_navigation/testimonials/testimonial_Information/create`}>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            title="Create new"
                                        >
                                            <i className="fal fa-plus"></i>Create New
                                        </button>
                                    </Link>
                                </PermissionsGate>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable
                    paginationDefaultPage={page}
                    progressPending={loading}
                    data={menuData}
                    progressComponent={<SkeletonTicketList />}
                    defaultSortField={sortkey}
                    defaultSortAsc={false}
                    columns={columns}
                    pagination={true}
                    // noDataComponent={Str.noRecord}
                    onSort={handleSort}
                    sortServer
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    highlightOnHover={false}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                />
            </div>
        </div>
    );
}

export default LayoutTestimonials;
